import { FC, ReactEventHandler, ReactNode } from "react";
import classNames from "classnames";
import ClearBtn from "../ClearBtn/ClearBtn";
import "./Modal.scss";

export interface IBaseModalProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
	isOpen: boolean;
	onClose: ReactEventHandler;
};

interface ModalProps extends IBaseModalProps {
	children?: ReactNode,
	title?: string;
	className?: string;
	color?: "error" | "green" | "light" | string;

	isBlocked?: boolean;

	renderBody?: FC;
	renderIcon?: FC | ReactNode;
	renderFooter?: FC | ReactNode;
};

const Modal: FC<ModalProps> = (props) => {

	const { isOpen, title = "ReDoc", isBlocked = true, className, color, onClose, children, renderIcon, renderFooter, renderBody } = props;

	const onCloseHandler: ReactEventHandler = (e) => onClose(e);

	if ( !isOpen )
		return null;

	const modalClasses = classNames( "rdc_modal", className, color);

	const renderWrapper = ( props: any ) => {
		if ( !!renderBody )
			return (
				<div className="rdc_modal__content__wrapper">
					{ renderBody(props) }
				</div>
			);
		return <p className="rdc_modal__content__text body_large">{children}</p>;
	};

	const renderModal = () => (
		<div className={modalClasses} >
			<div className="rdc_modal__header">
				<span className="rdc_modal__header__title headline-large">{title}</span>
				<ClearBtn className="rdc_modal__header__close_btn" onClick={onCloseHandler} isClose />
			</div>
			<div className="rdc_modal__content">
				{
					!!renderIcon &&
					<div className="rdc_modal__content__icon">
						{ typeof renderIcon === "function" ? renderIcon(props) : renderIcon }
					</div>
				}
				{ renderWrapper(props) }
			</div>
			{
				!!renderFooter && 
				<div className="rdc_modal__footer">
					{ typeof renderFooter === "function" ? renderFooter(props) : renderFooter }
				</div>
			}
		</div>
	);

	return (
		<div className={classNames("rdc_blocked__wrapper", { 'blocked': isBlocked } )}>
			{renderModal()}
		</div>
	);
};

export default Modal;
