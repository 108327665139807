import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

const isDevelop = process.env.NODE_ENV === "development";

i18next
	.use( Backend )
	.use( initReactI18next )
	.init( {
		debug: false,
		ns: "translation",
		load: 'languageOnly',
		lng: 'rus',
		supportedLngs: [ 'rus', 'belg', 'dev' ],
		backend: {
			loadPath: isDevelop ? '/locales/{{lng}}/{{ns}}.json' : '/new/locales/{{lng}}/{{ns}}.json',
		},
		interpolation: {
			// экранирование уже есть в React, поэтому отключаем
			escapeValue: false,
		},
	});

export default i18next;
