
export type UserPultType = {
    pultId: string, 
    pultName: string
};

export enum DamaskPultStatusType {
	Offline,
	Waiting,
	Idle,
	Calling,
	Working,
	OnPause
};

export type DamaskStatusType = {
	pultStatus: DamaskPultStatusType;
	ticket?: DamaskTicketType;
	message: string;
	// Максимальное время ожидания клиента
	maxClientWaitingTime?: number;
	// Действие по истечении MaxClientWaitingTime
	clientTimeoutAction?: DamaskClientActionType;
};

export type DamaskTicketType = {
	// Номер билета
	idQ: string;
	// Системный идентификатор билета
	systemId: string;
	// Клиент, взявший билет
	client: DamaskClientType;
	startServingTime: Date;
};

export enum DamaskClientActionType {
	Remove,
	Delay
};

////////////////////////////////////////////////////

export type DamaskQueueStateType = {
	pultStatus: DamaskPultStatusType;
    ticket?: DamaskTicketType;
    message: string;
	// Максимальное время ожидания клиента
    maxClientWaitingTime?: number;
	// Действие по истечении MaxClientWaitingTime
    clientTimeoutAction?: DamaskClientActionType;
	// Список услуг
    operations: Array<DamaskStateOperationType>;
};

export type DamaskStateOperationType = {
	// Идентификатор услуги
    id: string;
	// Название услуги
    name: string;
	// Количество клиентов, ожидающих по данной услуге
    waitCount: number;

};

export type DamaskClientType = {
	// ФИО клиента
	name: string;
	// Услуги, по которым обращается клиент
	operation: Array<DamaskOperationType>;
	// Предварительная запись
	preordered: boolean;
};

export type DamaskOperationType = {
	// Идентификатор услуги
	id: string;
	// Название услуги
	name_ru: string;
	// Статус обслуживания клиента по услуге сервера
	status: DamaskOperationStatusType;
};

export type DamaskRedirectParamType = {
	window: number;
	priority: "Low" | "Default" | "High";
	callback: boolean;
};

/// <summary>
/// Приоритет билета при направлении на другое окно
/// </summary>
export enum RedirectPriority {
	/// <summary>
	/// Обычный приоритет
	/// </summary>
	Default = 0,
	/// <summary>
	/// Поместить билет в начало очереди
	/// </summary>
	High = 1,
	/// <summary>
	/// Поместить билет в конец очереди
	/// </summary>
	Low = 2
};


/// <summary>
/// Статус обслуживания клиента по услуге сервера Дамаск
/// </summary>
export enum DamaskOperationStatusType {
	/// <summary>
	/// Клиент ожидает вызова
	/// </summary>
	Waiting = 1,

	/// <summary>
	/// Клиент вызван и ожидается у окна
	/// </summary>
	Called = 2,

	/// <summary>
	/// Клиент обслуживается окном
	/// </summary>
	Serving = 3,

	/// <summary>
	/// Обслуживание клиента по услуге отложено
	/// </summary>
	Delayed = 4,

	/// <summary>
	/// Обслуживание клиента по услуге отменено
	/// </summary>
	Dropped = 5,

	/// <summary>
	/// Обслуживание клиента по услуге завершено
	/// </summary>
	Served = 6
};
