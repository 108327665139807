import { useEffect } from 'react';
import { useLocalStorage } from '../Hooks';

interface IThemeProvider {
	children: React.ReactNode
};

/**
 * 
 * @param props 
 * @returns 
 */
const ThemeProvider = (props:IThemeProvider) => {
	const [theme,] = useLocalStorage("theme", "main");

	useEffect(() => {
		import( `../Themes/${theme}/style.scss` ); 
	}, [theme]);

	return (
		<>
			{props.children}
		</>
	);
};

export default ThemeProvider;