import { createAsyncThunk } from "@reduxjs/toolkit";
import { EQAuthParamType } from "src/Types";
import API from "src/API";
import { EnterQueueItemType, EnterStateType } from "src/Types/ReDoc/Enter";

const setMessage = ( dispatch: any, text: string = "Непознанная ошибка", title: string = "Ошибка ЭО" ) => {
	dispatch( {
		type: "infomodal/showError",
		payload: {	title, text }
	});
};

/** Санка на получение настроек электронной очереди */
export const enterGetConfig = createAsyncThunk( 'eQueueEnter/GetConfig', async ( payload, thunkAPI ) => {
	const result = await API.EnterEQ.getConfig();
	if ( result.hasError ) {
		return;
	}
	thunkAPI.dispatch( { type: "eQueueEnter/setConfig", payload: result.result } );
	return result;
});

/** Санка на сохранение настроек электронной очереди */
export const enterSaveConfig = createAsyncThunk( 'eQueueEnter/SaveConfig', async ( payload: EQAuthParamType ) => await API.EnterEQ.setConfig( payload ) );

/** Санка на логин электронной очереди */
export const enterStart = createAsyncThunk( 'eQueueEnter/Login', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.start( payload );
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result as EnterStateType } );
		thunkAPI.dispatch( enterGetQueueState() );
		thunkAPI.dispatch( enterGetDenyReasons() );
	}
	return data;
});

/** Санка на получение статуса ЭО */
export const enterGetStatus = createAsyncThunk( 'eQueueEnter/GetStatus', async (payload, thunkAPI) => {
	const data = await API.EnterEQ.getStatus();
	if ( !data.hasError ) {
		thunkAPI.dispatch( enterGetQueueState() );
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result as EnterStateType } );
	}
	return data;
});

/** Санка на получение состояния очереди */
export const enterGetQueueState = createAsyncThunk( 'eQueueEnter/GetState', async (payload, thunkAPI) => {
	const data = await API.EnterEQ.getQueueState();
	if ( data.hasError ) {
		console.warn( data );
	}
	return data;
});

/** Санка на вызов клиента электронной очереди */
export const enterCall = createAsyncThunk( 'eQueueEnter/Call', async ( payload, thunkAPI ) => {
	const data = await API.EnterEQ.call();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result as EnterStateType } );
	} 
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage );
	}
	return data;
});

/** Санка на получение причин перерыва */
export const enterGetPauseReasons = createAsyncThunk( 'eQueueEnter/GetPauseReasons', async () => {
	return await API.EnterEQ.getPauseReasons();
});

/** Санка на вызов перерыва */
export const enterBeginPause = createAsyncThunk( 'eQueueEnter/BeginPause', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.beginPause( payload );
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage || "", "Ошибка ЭО" );
	}
	return data;
});

/** Санка на окончание перерыва */
export const enterEndPause = createAsyncThunk( 'eQueueEnter/EndPause', async ( payload, thunkAPI ) => {
	const data = await API.EnterEQ.endPause();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	return data;
});

/** Санка на получение очереди */
export const enterGetQueue = createAsyncThunk( 'eQueueEnter/GetQueue', async ( payload, thunkAPI ) => {
	const list: Array<EnterQueueItemType> = [];
	const queue = await API.EnterEQ.getQueue();
	if ( queue.hasError ) {
		setMessage( thunkAPI.dispatch, queue.errorMessage || "", "Ошибка получения очереди" );
	} 
	else {
		list.push( ...queue.result.map( (q: EnterQueueItemType) => ({ ...q, isPersonal: false }) ) );
	}
	const pQueue = await API.EnterEQ.getPersonalQueue();
	if ( pQueue.hasError ) {
		setMessage( thunkAPI.dispatch, pQueue.errorMessage || "", "Ошибка получения персональной очереди" );
	} 
	else {
		list.push( ...pQueue.result.map( (q: EnterQueueItemType) => ({ ...q, isPersonal: true }) ) );
	}
	return list;
});

/** Санка на вызов клиента по ИД */
export const enterCallById = createAsyncThunk( 'eQueueEnter/CallById', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.callById(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	if ( !!data.result.message ) {
		setMessage( thunkAPI.dispatch,  data.result.message );
	}
	return data;
});

/** Производит повторный вызов текущего клиента */
export const enterRecall = createAsyncThunk( 'eQueueEnter/Recall', async ( payload, thunkAPI ) => {
	const data = await API.EnterEQ.recall();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage );
	}
	return data;
});

/** Отменяет обслуживание текущего клиента */
export const enterReject = createAsyncThunk( 'eQueueEnter/Reject', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.reject();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	return data;
});

/** Санка Начала обслуживания ЭО */
export const enterBeginServing = createAsyncThunk( 'eQueueEnter/BeginServing', async ( payload, thunkAPI ) => {
	const data = await API.EnterEQ.beginServing();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	return data;
});

/** Санка на получение причин перерыва */
export const enterGetHoldMode = createAsyncThunk( 'eQueueEnter/GetHoldMode', async () => {
	return await API.EnterEQ.getHoldMode();
});

/** Запрос списка причин неоказания услуги */
export const enterGetDenyReasons = createAsyncThunk( 'eQueueEnter/GetDenyReasons', async () => {
	return await API.EnterEQ.getDenyReasons();
});

/** Откладывает обслуживание текущего клиента */
export const enterDelay = createAsyncThunk( 'eQueueEnter/Delay', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.hold(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
		thunkAPI.dispatch( enterGetQueueState() );
	}
	return data;
});

/** Успешное завершение обслуживания посетителя */
export const enterComplete = createAsyncThunk( 'eQueueEnter/OnComplete', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.complete();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	return data;
});

/** Неуспешное завершение обслуживания посетителя */
export const enterReason = createAsyncThunk( 'eQueueEnter/OnReason', async ( payload: string, thunkAPI ) => {
	const data = await API.EnterEQ.deny(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/updateState", payload: data.result } );
	}
	return data;
});

/** Завершение работы с сервером ЭО  */
export const enterExit = createAsyncThunk( 'eQueueEnter/OnExit', async ( payload, thunkAPI ) => {
	const data = await API.EnterEQ.finish();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueEnter/exit", payload: data.result } );
	}
	return data;
});
