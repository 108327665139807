import { UncontrolledTooltip } from "reactstrap";
import classNames from 'classnames';
import { ICertificateInfo }  from '../../Types/Certificate';
import DateFormater from '../../Utils/DateFormater';
import { ReactComponent as CertIconAlert } from '../../Themes/assets/CertIconAlert.svg';
import { InputRadio } from '../Controls';
import { TextWithIcon } from '../';
import "./style.scss";

interface ICertificateItemProps extends ICertificateInfo {
	currentThumbprint: string;
}

/** Возвращает текст в зависимости от даты действия сертификата */
const getInvalidText = ( cert: ICertificateInfo ): string => {
	if ( !cert.valid )
		return "Срок действия сертификата истёк";
	else
		return "Нет доверия к сертификату";
};

const CertificateItem = ( props: ICertificateItemProps ) => {
	
	const { thumbprint, subjectName, subjectEmail, issuerName, validToDate, valid, currentThumbprint } = props;
	
	const containerClasses = classNames( "certificate", {'invalid-cert': !valid	});

	return (
		<div className={containerClasses} id={thumbprint}>

			<div className="certificate_cb">
				<InputRadio name="certificate" disabled={!valid} value={currentThumbprint === thumbprint} />
			</div>
			<div className="certificate_content">
				<div className="certificate_subject">
					<p className='certificate_text'>
						{subjectName}
					</p>
					<p className='certificate_text'>
						{subjectEmail}
					</p>
				</div>
				<div className="certificate_issuer">
					<p className='certificate_text'>
						<span>Издатель:</span>
						<span>{issuerName}</span>
					</p>
					<p className='certificate_text'>
						<span>Действителен по:</span>
						<span>{DateFormater.toDateString(validToDate)}</span>
					</p>
				</div>
			</div>
			<div className="certificate_icon">
				{
					!valid &&
					<>
						<CertIconAlert width='22px' height='20px' id={"invalcert_"+ thumbprint}/>
						<UncontrolledTooltip 
							className="cert_hint"
							placement="right"
							target={"invalcert_"+ thumbprint}
						>
							<div className="certificate_popup brd-orange">
								<h4 className='certificate_popup-title'>
									{getInvalidText(props)}
								</h4>
								<TextWithIcon className='certificate_popup-text' iconClasses='icon-check-blue'>
									Установите действующий сертификат
								</TextWithIcon>
							</div>
						</UncontrolledTooltip>
					</>
				}
			</div>
		</div>
	);
};

export default CertificateItem;