import classNames from "classnames";
import ReDocTrober from "../ReDocTrober";
import "./TroberButton.scss";


interface ITroberButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
	isWaiting: boolean;
	disabled?: boolean;
	block?: boolean;
	type?: "button" | "submit" | "reset" | undefined;
	className?: string;
	color?: "blue" | "green" | "red" | "yellow";
}

const TroberButton: React.FC<ITroberButtonProps> = (props) => {

	const { children, type = 'button', color="blue", isWaiting, className, block, disabled, onClick, ...otherProps } = props;

	const btnClasses = classNames( "trober-btn", className, `trober-btn-${color}`, {
		"is_block": block,
		'iswaiting': isWaiting
	});

	return (
		<button disabled={disabled} className={btnClasses} type={type} onClick={ (e) => !!onClick && !isWaiting && onClick(e)} {...otherProps}>
			{
				isWaiting ? <ReDocTrober className="m-auto" size='sm'/> : children
			}
		</button>
	);
}

export default TroberButton;