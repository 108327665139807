import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import './texthint.scss';

interface TextHintProps {
	children: string;
	hint?: string;
	onParent?: boolean;
	className?: string;
	align?: "left" | "center" | "right";
	isList?: boolean;
	disable?: boolean;
};

/** Компонента для отображения текста, при переполнении текст обрезается и показывается подсказка  */
const TextHint: React.FC<TextHintProps> = ({ children, disable = false, isList = false, align = "left", className, hint = '', onParent = true }) => {

	const spanRef = useRef<HTMLDivElement>(null);

	useEffect( () => {
		if ( disable ) return;
		if ( !!spanRef && !!spanRef.current ) {
			const spanSize = spanRef.current.clientWidth;
			const textSize = spanRef.current.scrollWidth;
			const element = onParent ? spanRef.current.parentElement : spanRef.current;
			const text = !!hint ? hint : children;
			if (textSize > spanSize) {
				element?.setAttribute( "data-tooltip-id", "rdc_tooltip" );
				if ( isList ) {
					const newText = text.replaceAll( ",", "</br>");
					element?.setAttribute( "data-tooltip-html", newText );
				} else {
					element?.setAttribute( "data-tooltip-content", text );
				}
			}
			else {
				element?.removeAttribute( "data-tooltip-content" );
				element?.removeAttribute( "data-tooltip-id" );
			}
		}
	// eslint-disable-next-line
	}, [ children, hint, onParent, disable, isList ] );

	return (
		<div ref={spanRef} className={classNames("rdc_texthint", className)} style={{ textAlign: align }}>
			{children}
		</div>
	);
};

export default TextHint;
