import React from "react";
import { UncontrolledCollapse } from 'reactstrap';
import './styles.scss';

export interface HelperBlockProps {
	title: string;
	text?: string;
	children?: React.ReactNode;
	body?: Function;
}

const HelperBlock = (props: HelperBlockProps) => {
	const { title, text, children, body } = props;
	const id = "toggler_" + Date.now();
	return (
		<div className="helpblock">
			<div className="helpblock__body">
				<div id={id} className="helpblock__header">
					<span className="helpblock__header-title">{title}</span>
					&nbsp;
					<i className="fa fa-hand-o-down" aria-hidden="true" />
				</div>
				<UncontrolledCollapse toggler={"#" + id}>
					<div className="helpblock__content">
						<p className="helpblock__content-text">{text}</p>
						{children}
						{!!body && body()}
					</div>
				</UncontrolledCollapse>
			</div>
		</div>
	);
};

export default HelperBlock;