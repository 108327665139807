import classNames from "classnames";
import st from "./RdcCard.module.scss";

interface IRdcCardProps extends React.AreaHTMLAttributes<HTMLDivElement> {
	dir: "col" | "row";
};

/** Компонента-контейнер для отображения блока */
export const RdcCard: React.FC<IRdcCardProps> = ( { className, children, dir = "col", ...otherProps } ) => (
	<div 
		className={ classNames(st.rdc_card, className, { 
			'flex-row': dir === "row",
			'flex-column': dir === "col"
		}) }
		{...otherProps}
	>
		{children}
	</div>
);
