import moment from 'moment';

export default class DateFormater {
    
    static ruMonthNames = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];

	static parseOformatZoneOffset = (hours:number|string, minutes:number|string, sign?:string) => {
		return ((sign === '-') ? -1 : 1) * ((~~hours * 60) + (~~minutes));
	};

	static getDateWithoutTimeZoneConvertion = (inputDateStr:string|Date) => {
		let date = new Date(inputDateStr);
		if (!!date) {
			//user timezone offset
			let userOffset;
			//input timezone offset
			let offset;
			if (inputDateStr instanceof Date) {
				//userOffset = date.getTimezoneOffset(); // user's offset time
				//offset = inputDateStr.getTimezoneOffset();
				//date = new Date(date.getTime() + (userOffset - offset) * 60 * 1000);
				return date;
			}
			if (typeof inputDateStr === 'string') {
				var match = (/[.](?:[0-9]+)([+]|[-])([\d]{2}):([\d]{2})$/).exec(inputDateStr);
				if (match) {
					userOffset = date.getTimezoneOffset(); // user's offset time
					offset = -1 *(match[1] === '' || match[1] === 'z' || match[1] === 'Z' ? 0 : this.parseOformatZoneOffset(match[2], match[3], match[1]));
					date = new Date(date.getTime() + (userOffset - offset) * 60 * 1000);
				}
			}
		}
		return date;
	};

	static getDate = (inputDateStr:string|Date, options?:any) => {
		return (!options || !options.useTimezone) ? 
            this.getDateWithoutTimeZoneConvertion(inputDateStr) : 
            new Date(inputDateStr);
	};

	static toFullDateString = (inputDateStr:string, options?:any) => {
		if (inputDateStr !== '') {
			var date = this.getDate(inputDateStr, options);
			var dateToShow = '';
			if (!isNaN(date.getTime()))
				dateToShow = (date.getDate() >= 10 ? date.getDate() : ('0' + date.getDate())) + ' ' + this.ruMonthNames[(date.getMonth())] + ' ' + date.getFullYear() + ' в ' + date.getHours() + ":" + (date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes()));
				return dateToShow;
		}
		return '';
	};

    static toDateString = (inputDateStr:string|Date, options?:any) => {
		if (inputDateStr !== '') {

            var date = this.getDate(inputDateStr, options);
			return (date.getDate() >= 10 ? date.getDate() : ('0' + date.getDate())) + " " + this.ruMonthNames[(date.getMonth())] + " " + date.getFullYear();
		}
		return '';
	};

	static toShortString = (inputDateStr:string|Date|null, options?:any) => {
		if ( !inputDateStr )
			return '';
		const date = this.getDate(inputDateStr, options);
		return moment(date).format("DD.MM.yyyy");
	};

	static toString = (inputDateStr:string|Date|null, options?:any) => {
		if ( !inputDateStr )
			return '';
		const date = this.getDate(inputDateStr, options);
		return moment(date).format("DD.MM.yyyy, hh:mm:ss");
	};

	/** Парсит дату из строки  */
	static parseDate = ( value: any, needParse: boolean = false ): Date|null => {
		if ( !value )
			return null;
		if ( typeof value === "object" && !!value.getDay )
			return value;
		if ( needParse ) {
			const date = moment.parseZone( value, 'DD.MM.YYYY').toDate();
			date.setHours(0);
			return date;
		}
		const date = new Date(value);
		date.setMinutes(0);
		date.setHours(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date;
	};

	static toString_ddMMyyyy = (date:Date) => {
		return (date.getDate() >= 10 ? date.getDate() : ('0' + date.getDate())) + " " + this.ruMonthNames[(date.getMonth())] + " " + date.getFullYear();
	};
}; 