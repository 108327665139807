
import { RdcGroupList } from './RdcGroupList';

export type ExtendedGroupType = {
    [groupKey: string]: boolean;
}; 

export type RdcGroupType = {
    icon?: string;
    groupId: string;
    count: number;
    groupTitle?: string;
    page: number;
    list: Array<RdcGroupType> | Array<RdcGroupItemType|any>;
};

export type RdcGroupItemType = {
    id: string;
    title: string;
    icon?: string;
    isFavorite?: boolean;
};

export type IRdcGroupListRef = {
	scrollTo: ( id: string ) => void;
};

export default RdcGroupList;
