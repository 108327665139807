import { useCallback, useState } from "react";

type ContextMenuInfoType = {
	isEmpty: boolean;
	position: {
		x: number;
		y: number;
	}
	data: any;
};

const _Empty: ContextMenuInfoType = {
	isEmpty: true,
	position: { x: 0, y: 0 },
	data: null
};

const useContextMenuHelper = ( isEnable: boolean = true ) => {

    const [ contextMenuInfo, setContextMenuInfo ] = useState<ContextMenuInfoType>(_Empty);

    const onContextMenuHandler = useCallback( ( e: React.MouseEvent, data: any ) => {
		if ( !isEnable ) return;
		e.preventDefault();
		setContextMenuInfo( { isEmpty: false, position: { x: e.clientX, y: e.clientY }, data } );
    // eslint-disable-next-line
	}, [ isEnable ] );

	const hideMenu = useCallback(() => {
		setContextMenuInfo(_Empty);
	// eslint-disable-next-line
	}, []);
    
    return [ contextMenuInfo, onContextMenuHandler, hideMenu ] as const;
};

export default useContextMenuHelper;
