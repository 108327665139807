import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'Src/store';
// import { RootState } from '../store';
import { FileRequestType, PresetFilterType, ReestrColumnType, ReestrType } from 'src/Types';

interface IPresetState {
	currentPreset: ReestrType | null;
	userPreset: ReestrType | null;
	filters: FileRequestType;
	currentPresetValue: string;
	presetFilters: PresetFilterType[];

	availableColumns: Array<ReestrColumnType>;
};

const initialState: IPresetState = {
	currentPreset: null,
	userPreset: null,
	presetFilters: [],
	filters: {
		presetType: "",
		clientTimezoneOffset: (new Date()).getTimezoneOffset(),
		clientDate: (new Date((new Date()).setHours(0, 0, 0, 0))).toJSON(),
		page: 0
	},
	currentPresetValue: '',
	availableColumns: []
};

export const presetSlicer = createSlice({
	name: 'preset',
	initialState,
	reducers: {
		setPreset: (state, action: PayloadAction<ReestrType> ) => {
			state.currentPreset = action.payload;
			state.userPreset = {
				...action.payload,
				reccode: "userPreset",
				shared: true
			};
			state.currentPresetValue = applyPresetToFilters( action.payload, state.filters );
			state.presetFilters = action.payload.filters;
			state.filters = {
				...getPresetFilters(state.presetFilters),
				sortBy: action.payload.sorting.column,
				sortOrder: action.payload.sorting.sorttype,
				clientTimezoneOffset: (new Date()).getTimezoneOffset(),
				clientDate: (new Date((new Date()).setHours(0, 0, 0, 0))).toJSON()
			};
		},
		setUserPreset: (state, action: PayloadAction<ReestrType> ) => {
			state.userPreset = {
				...action.payload,
				shared: true
			};
			state.presetFilters = action.payload.filters;
			state.currentPresetValue = getCurrentPresetValue(state.userPreset);
		},
		setFilters: (state, action: PayloadAction<FileRequestType> ) => {
			state.filters = {
				...action.payload,
				presetType: state.currentPresetValue,
				...getPresetFilters(state.presetFilters),
				clientTimezoneOffset: (new Date()).getTimezoneOffset(),
				clientDate: (new Date((new Date()).setHours(0, 0, 0, 0))).toJSON()
			};
		},
		resetUserPreset: (state, action: PayloadAction) => {
			if ( !!state.currentPreset ) {
				state.userPreset = { 
					...state.currentPreset,
					isChanged: false
				};
				state.currentPresetValue = getCurrentPresetValue(state.currentPreset);
				state.presetFilters = state.currentPreset.filters;
				state.filters = {
					...getPresetFilters(state.presetFilters),
					sortBy: state.userPreset.sorting.column,
					sortOrder: state.userPreset.sorting.sorttype,
					clientTimezoneOffset: (new Date()).getTimezoneOffset(),
					clientDate: (new Date((new Date()).setHours(0, 0, 0, 0))).toJSON()
				};
			}
		},
		setAvailableColumns: (state, action: PayloadAction<ReestrColumnType[]>) => {
			state.availableColumns = action.payload;
		}
	},
});

const getPresetFilters = ( presetFilters: Array<PresetFilterType> ): any => {
	const filters: any = {};
	presetFilters.forEach( filter => {
		if ( filter.disable !== true )
			filters[filter.name] = filter.value;
	});
	return filters;
};

const applyPresetToFilters = (reestr: ReestrType, filters: FileRequestType) => {
	if ( !reestr ) return;
	const curPreset = getCurrentPresetValue(reestr);
	filters.presetType = curPreset;

	if ( !!reestr.sorting ) {
		filters.sortBy = reestr.sorting.column;
		filters.sortOrder = reestr.sorting.sorttype;
	}
	return curPreset;
};

const getCurrentPresetValue = ( reestr: ReestrType ) => {
	const pr = reestr.filters.find( f => f.name === "presetType" && !f.disable );
	return !!pr ? pr.value : ( !["all", "userPreset"].includes(reestr.reccode) ? reestr.reccode : "");
};

// ACTIONS
export const { setPreset, setFilters, setUserPreset, resetUserPreset, setAvailableColumns } = presetSlicer.actions;

// SELECTORS
export const getUserPreset = (store: RootState): ReestrType|null => store.preset.userPreset;

export const selectPreset = createSelector(
	[
		state => state.preset.currentPreset,
		state => state.preset.userPreset,
		(state, isConstructorMode) => isConstructorMode
	],
	( currentPreset, userPreset, isConstructorMode): ReestrType|null => ( isConstructorMode ? userPreset : currentPreset )
);

export const selectAvailableColumns = createSelector(
	[
		selectPreset,
		(state, isConstructorMode) => isConstructorMode
	],
	( preset, isConstructorMode ): Array<ReestrColumnType> => ( isConstructorMode ? preset?.columns : preset?.columns ) || []
);

export const presetActions = presetSlicer.actions;

export default presetSlicer;