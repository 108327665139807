import classNames from 'classnames';
import "./icon-button.scss";

interface IIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    iconClass?: string;
    isWaiting?: boolean;
    disable?: boolean;
    centered?: boolean;
    bordered?: boolean;
	iconOnly?: boolean;
    color?: "blue" | "yellow" | "green" | "red" | string;
    outline?: boolean;
	active?: boolean;
    block?: boolean;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const IconButton: React.FC<IIconButtonProps> = (props) => {

    const { children, className, iconOnly, color = "blue", active = false, disable = false, centered, block = false, outline = false, bordered, isWaiting, iconClass, ...otherProps } = props;

	const classes = classNames('rdc_icon-btn', className, color, {
		'btn_disabled': disable,
		'rdc_outline': outline,
		'centered': centered,
		'btn_block': block,
		'bordered': bordered,
		'iconBtn': iconOnly,
		'active': active
	});

	const renderContent = () => {
		if ( !!isWaiting ) {
			return (<span className='rdc_small_spiner' />);
		}
		else if ( !!iconClass ) {
			return (
				<>
					<span className={iconClass} onClick={otherProps.onClick} />
					{!!children && children}
				</>
			);
		}
		else {
			return !!children && children;
		}
	};

    return (
        <button type="button" className={classes} {...otherProps}>
            {renderContent()}
        </button>
    );
}

export default IconButton;