import { Response } from "src/Types/ReDoc";
import { ListJsonResponseType } from "src/Types";
import { CustomersType } from "src/Types/Customer";
import { AnswerType, DepartmentListType, NewServiceType, QuestionsListType, RequestNewServiceInfoType } from "src/Types/ReDoc/FileWizard";
import { ServiceType, ServiceUpdateGroup, ServicesRequestType, ServicesResponse } from "src/Types/ReDoc/Service";

/** */
export default class FileWizardAPI {

	/** Запрос на проверку является ли заявитель комплексным */
	static checkComplexCustomer = async ( recid: string ): Promise<Response<boolean>> => {
		const url = window.host + "/MfcUiModule/MfcUiWebController/Service/CheckComplexCustomer?ElementId=" + recid;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return {
					hasError: data.hasError,
					errorMessage: data.errorMessage,
					result: data.isComplexCustomer
				};
			}
			throw new Error("Ошибка запросе на проверку заявителя на комплексность. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: false
			};
		}
	};

	/** Запрос на проверку пропуска выбора заявителя */
	static checkSkipCustomerSelection = async ( recid: string ): Promise<Response<boolean>> => {
		const url = window.host + "/MfcUiModule/MfcUiWebController/Service/SkipCustomerSelection?ElementId=" + recid;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return {
					hasError: data.hasError,
					errorMessage: data.errorMessage,
					result: data.isSkipSelectingCustomer
				};
			}
			throw new Error("Ошибка запросе на проверку пропуска выбора заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: false
			};
		}
	};

	/**  */
	static getDepartments = async ( serviceId: string, departmentSearchText: string = "" ): Promise<DepartmentListType> => {
		const url = window.host + "/MfcUiModule/Wizzard/Step4/GetDepartments";
		const frmData = new FormData();
		frmData.append( "ServiceRecId", serviceId );
		frmData.append( "DepartmentSearchText", departmentSearchText );
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении списка ведомств. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/**  */
	static getCustomers = async ( type: string, searchText: string = "", page: number = 0 ): Promise<ListJsonResponseType<CustomersType>> => {
		const url = window.host + "/Nvx.ReDoc.MfcUiModule/Step02CustomersController/CustomersList";
		const frmData = {
			clientTimezoneOffset: (new Date()).getTimezoneOffset(),
			clientDate: (new Date((new Date()).setHours(0, 0, 0, 0))).toJSON(),
			type,
			name: searchText,
			page, 
		};
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(frmData)
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении списка заявителей. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				count: 0,
				page: 0,
				hasError: true,
				errorMessage: error.message,
				list: []
			};
		}
	};

	/**  */
	static getQuestions = async ( serviceRecId: string, customerRecIds: string | string[] ): Promise<QuestionsListType> => {
		const url = window.host + "/MfcUiModule/Wizzard/Step3/GetQuestions";
		
		const frmData = new FormData();
		let customers = null;
		if ( !!customerRecIds && Array.isArray(customerRecIds) )
			customers = customerRecIds.join(',');
		else 
			customers = customerRecIds;

		frmData.append( "ServiceRecId", serviceRecId );
		if ( !!customers || !!customers?.length )
			frmData.append( "CustomerRecIds", customers );

		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении опросной формы. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/**  */
	static getComplexQuestions = async ( serviceRecIdList: string[], customerRecId: string ): Promise<QuestionsListType> => {
		const url = window.host + "/Nvx.ReDoc.ComplexRequest/WizzardController/GetQuestions";
		
		const frmData = new FormData();
		frmData.append( "serviceRecIdList", serviceRecIdList.join(',') );
		frmData.append( "customerRecId", customerRecId );

		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении опросной формы. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/**  */
	static getNewService = async ( serviceRecId: string, customerRecIds: string | string[], departmentId: string, answers: Array<AnswerType>, isSkipped: boolean = false ): Promise<NewServiceType> => {
		const url = window.host + "/MfcUiModule/Wizzard/Step5/GetNewServiceInfoFromWizard";
		const frmData = new FormData();
		let customers = null;
		if ( !!customerRecIds && Array.isArray(customerRecIds) )
			customers = customerRecIds.join(',');
		else 
			customers = customerRecIds;

		frmData.append( "ServiceRecId", serviceRecId );
		frmData.append( "CustomerRecIds", customers );
		frmData.append( "QuestionList", JSON.stringify(answers) );
		frmData.append( "DepartmentId", departmentId );
		frmData.append( "IsSkipped", isSkipped.toString() );

		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении опросной формы. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/**  */
	static getGetNewServiceInfoFromWizard = async ( data: RequestNewServiceInfoType ): Promise<ServiceType> => {
		const url = window.host + "/MfcUiModule/Wizzard/Step5/GetNewServiceInfoFromWizard";
		const { ServiceId, QuestionList, CustomerRecIds, DepartmentId, IsSkipped } = data;
		const frmData = new FormData(); 
		frmData.append( "ServiceRecId",  this._getValueToFrmData(ServiceId) );
		frmData.append( "QuestionList", JSON.stringify(QuestionList) );
		frmData.append( "CustomerRecIds", this._getValueToFrmData(CustomerRecIds) );
		frmData.append( "DepartmentId", this._getValueToFrmData(DepartmentId) );
		frmData.append( "IsSkipped", IsSkipped.toString() );
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении опросной формы. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/**  */
	static processChecked = async ( model: any, isComplex: boolean = false ): Promise<any> => {
		const cls = isComplex ? "" : "MfcUiModule";
		const url = `${window.host}/${cls}/Wizzard/Step3/ProcessChecked`;
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: model
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			else if ( request.status === 403 ) {
				var returnUrl = window.location.pathname + window.location.hash;
				returnUrl = encodeURIComponent(returnUrl);
				var redirectUrl = window.host + '/new#/login?returnUrl=' + returnUrl;
				window.location.href = redirectUrl;
			}
			throw new Error("Ошибка при получении опросной формы. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/** регистрация обращения заявителя */
	static registerTreatment = async ( model: any, extendedConsultation: boolean = false ) => {
		const url = window.host + ( extendedConsultation ? '/ExtendedConsultationModule/CreateExtendedConsultation' : '/MfcUiModule/Wizzard/Step5/RegisterTreatment' );
		const frmData = new FormData();
		Object.keys( model ).forEach( key => {
			frmData.append( key, model[key] );
		});
		
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			else if ( request.status === 403 ) {
				var returnUrl = window.location.pathname + window.location.hash;
				returnUrl = encodeURIComponent(returnUrl);
				var redirectUrl = window.host + '/new#/login?returnUrl=' + returnUrl;
				window.location.href = redirectUrl;
			}
			throw new Error("Ошибка при создании обращения. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};

	/** запрос на сервер создание дела */
	static createFile = async ( model: any ) => {
		const url = window.host + '/MfcUiModule/Wizzard/Step5/CreateFile';
		const frmData = new FormData();
		Object.keys( model ).forEach( key => {
			frmData.append( key, model[key] );
		});
		
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			else if ( request.status === 403 ) {
				var returnUrl = window.location.pathname + window.location.hash;
				returnUrl = encodeURIComponent(returnUrl);
				var redirectUrl = window.host + '/new#/login?returnUrl=' + returnUrl;
				window.location.href = redirectUrl;
			}
			throw new Error("Ошибка создании дела. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			};
		}
	};


	/** Запрос на получение группового списка сервисов  */
	static getServices = async ( requestBody: ServicesRequestType, isComplex: boolean = false ): Promise<ServicesResponse> => {
		return await this._getServices(requestBody, isComplex) as ServicesResponse;
	};

	/** Запрос на обновление списка указанной группы сервисов */
	static updateServices = async ( requestBody: ServicesRequestType, isComplex: boolean = false ): Promise<ServiceUpdateGroup> => {
		return await this._getServices(requestBody, isComplex) as ServiceUpdateGroup;
	};

	static _getServices = async ( requestBody: ServicesRequestType, isComplex: boolean = false ): Promise<any> => {
		const url = window.host + (
			isComplex ? "/MfcUiModule/MfcUiWebController/ComplexRequestServiceGroupsList" : '/MfcUiModule/MfcUiWebController/ServiceGroupsList'
		);
		if ( isComplex ) requestBody.isComplexRequest = isComplex;
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody)
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка услуг. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении списка услуг.",
				count: 0,
				location: null,
				page: 0,
				list: []
			}
		}
	};


	/**  */
	static _getValueToFrmData = ( value: string | string[] ) => {
		return Array.isArray(value) ? JSON.stringify(value) : value;
	};

};
