import classNames from 'classnames';
import { ReactComponent as TrRD } from '../../Themes/assets/RedocTrober.svg';
import './style.scss';

interface ITroberProps {
	size?: "sm" | "lg" | "xl";
	className?: string;
};

const ReDocTrober = ({ size = 'sm', className }:ITroberProps) => {
	const classes = classNames("trober", className, size);
	return (
		<div className={classes}>
			<TrRD width={100} height={100} />
		</div>
	);
};

export default ReDocTrober;
