
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TypeDomainParams } from "src/Types";
import { DamaskRedirectParamType, UserPultType } from "src/Types/ReDoc/Damask";
import API from "src/API";

const setMessage = ( dispatch: any, text: string = "Непознанная ошибка", title: string = "Ошибка ЭО" ) => {
	dispatch( {
		type: "infomodal/showError",
		payload: {	title, text }
	});
};

/** Санка на получение настроек электронной очереди */
export const damaskGetConfig = createAsyncThunk( 'eQueueDamask/GetConfig', async () => {
	return await API.DamaskEQ.getConfig() 
});
/** Санка на сохранение настроек электронной очереди */
export const damaskSaveConfig = createAsyncThunk( 'eQueueDamask/SaveConfig', async ( payload: TypeDomainParams ) => await API.DamaskEQ.setConfig( payload ) );

/** Санка на сохранение настроек электронной очереди */
export const damaskGetUserPult = createAsyncThunk( 'eQueueDamask/GetSavedUserPult', async () => await API.DamaskEQ.getUserPult() );
/** Санка на сохранение настроек электронной очереди */
export const damaskSetUserPult = createAsyncThunk( 'eQueueDamask/SaveUserPult', async ( payload: UserPultType ) => await API.DamaskEQ.setUserPult( payload ) );

/** Санка получает список доступных окон */
export const damaskGetStations = createAsyncThunk( 'eQueueDamask/GetOfficeStations', async () => {
	return await API.DamaskEQ.getStations();
});

/** Санка получает список доступных окон */
export const damaskGetOfficeStations = createAsyncThunk( 'eQueueDamask/GetOfficeStations', async ( payload: string ) => {
	return await API.DamaskEQ.getOfficeStations( payload );
});

/** Санка на логин электронной очереди */
export const damaskLogin = createAsyncThunk( 'eQueueDamask/Login', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.login();
	if ( !data.hasError ) {
		thunkAPI.dispatch( damaskGetOfficeStations( data.result.toString() ) );
	}
	return data;
});

/** Санка на старт электронной очереди */
export const damaskStart = createAsyncThunk( 'eQueueDamask/Start', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.start( payload );
	if ( !data.hasError )
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	else {
		// Тут будет вывод ошибок
	}
	return data;
});

/** Санка на получение статуса ЭО */
export const damaskGetStatus = createAsyncThunk( 'eQueueDamask/GetStatus', async (payload, thunkAPI) => {
	const data = await API.DamaskEQ.getStatus();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Санка на получение состояния очереди ЭО */
export const damaskGetQueueState = createAsyncThunk( 'eQueueDamask/GetQueueState', async ( payload, api ) => {
	const data = await API.DamaskEQ.getQueueState();
	const oldStatus = (api.getState() as any).eQueueDamask.status.pultStatus;
	if ( oldStatus !== (data.result as any).pultStatus )
		api.dispatch( damaskGetStatus() );
	return data;
});

/** Санка на получение причин перерыва */
export const damaskGetPauseReasons = createAsyncThunk( 'eQueueDamask/GetPauseReasons', async () => {
	return await API.DamaskEQ.getPauseReasons();
});

/** Санка на вызов перерыва */
export const damaskBeginPause = createAsyncThunk( 'eQueueDamask/BeginPause', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.beginPause( payload );
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Санка на окончание перерыва */
export const damaskEndPause = createAsyncThunk( 'eQueueDamask/EndPause', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.endPause();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Санка на вызов клиента электронной очереди */
export const damaskCall = createAsyncThunk( 'eQueueDamask/Call', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.call();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	} 
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage );
	}
	return data;
});

/** Санка на вызов клиента по ИД */
export const damaskCallById = createAsyncThunk( 'eQueueDamask/CallById', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.callById(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	if ( !!data.result.message ) {
		setMessage( thunkAPI.dispatch,  data.result.message );
	}
	return data;
});

/** Производит повторный вызов текущего клиента */
export const damaskRecall = createAsyncThunk( 'eQueueDamask/Recall', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.recall();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage );
	}
	return data;
});

/** Отменяет обслуживание текущего клиента */
export const damaskDrop = createAsyncThunk( 'eQueueDamask/Drop', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.drop(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Откладывает обслуживание текущего клиента */
export const damaskDelay = createAsyncThunk( 'eQueueDamask/Delay', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.delay(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Перенаправляет посетителя на другое окно */
export const damaskRedirect = createAsyncThunk( 'eQueueDamask/Redirect', async ( payload: DamaskRedirectParamType, thunkAPI ) => {
	const data = await API.DamaskEQ.redirect(payload.window, payload.priority, payload.callback);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	else {
		setMessage( thunkAPI.dispatch, data.errorMessage || "Не удалось перенаправить посетителя.", "Ошибка ЭО" );
	}
	return data;
});

/** Санка Начала обслуживания ЭО */
export const damaskBeginServing = createAsyncThunk( 'eQueueDamask/BeginServing', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.beginServing();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

/** Санка Завершения обслуживания ЭО */
export const damaskEndServing = createAsyncThunk( 'eQueueDamask/EndServing', async ( payload: string, thunkAPI ) => {
	const data = await API.DamaskEQ.endServing(payload);
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/updateState", payload: data.result } );
	}
	return data;
});

// damaskExit
export const damaskExit = createAsyncThunk( 'eQueueDamask/Exit', async ( payload, thunkAPI ) => {
	const data = await API.DamaskEQ.finish();
	if ( !data.hasError ) {
		thunkAPI.dispatch( { type: "eQueueDamask/exit", payload: data.result } );
	}
	return data;
});
