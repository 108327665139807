import classNames from "classnames";
import { ReDocTrober } from "src/Components";
import './content-container.scss';

interface IContentContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    fluid?: boolean;
    isLoading: boolean;
    renderSubMenu?: React.FC;
    renderContent?: React.FC;
};

const ContentContainer: React.FC<IContentContainerProps> = ({ isLoading, fluid = true, children, renderSubMenu, renderContent, className, ...otherProps}) => {

    const classes = classNames('rdc_content-container', { 'rdc-fluid': fluid });

    if ( !!isLoading ) {
        return (
            <div className={classes}>
                <ReDocTrober size='xl' className='m-auto'/>
            </div>
        );
    }

    return (
        <div className={classNames(classes, className)}>
			{ !!renderSubMenu && renderSubMenu( {...otherProps}) }
            { !!children && children }
            { !!renderContent && renderContent(otherProps) }
		</div>
    );
};

export default ContentContainer;