import { RDC_Response } from "src/Types/ReDoc/RDC_Response";
import { CustomerListType, CustomerRequestType, CustomerInfo, CustomerType, CustomerTreatmentListType } from "src/Types/Customer";
import DateFormater from "src/Utils/DateFormater";
import API from ".";

type getCustomersType = (requestParams: CustomerRequestType ) => Promise<CustomerListType>;

export default class APICustomer {

    /** Функция делает запрос за списком заявителей  */
	static getList: getCustomersType = async ( requestParams ) => {  
		const url = window.host + "/MfcUiModule/MfcUiWebController/CustomersGroupsList";
		try {
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestParams)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				const data = await request.json();
                return {
                    hasError: data.hasError,
                    errorMessage: data.errorMessage,
                    count: data.list[0].count,
                    list: data.list[0].list,
                    page: data.list[0].page
                }
			}
			throw new Error("Ошибка при получении списка заявителей. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				list: [],
				count: 0,
				page: 0
			}
		}
	};

	/** Запрос за информации о заявителе */
	static getInfo = async ( id: string ): Promise<Partial<CustomerInfo>> => {
		const url = window.host + "/Nvx.ReDoc.MfcUiModule/Step02CustomersController/GetMfcCustomerInfo?ElementId=" + id ;
		try {
			const request = await fetch( url, { 
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
                return await request.json();
			}
			throw new Error("Ошибка при получении информации о заявителе. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message
			}
		}
	};

	/** Запрос на полную информацию о заявителе */
	static getFullInfo = async ( id: string ): Promise<Partial<CustomerType>> => {
		const url = `${window.host}/Nvx.ReDoc.MfcUiModule/Step02CustomersController/GetEditableCustomerInfo?ElementId=${id}&_=${Date.now()}`;
		try {
			const request = await fetch( url, { 
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
                return await request.json();
			}
			throw new Error("Ошибка при получении информации о заявителе. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message
			}
		}
	};

	/** Запрос на список обращений заявителя  */
	static getTreatments = async ( customerId: string, page: number = 0 ): Promise<CustomerTreatmentListType> => {
		const url = window.host + `/Nvx.ReDoc.MfcUiModule/Step02CustomersController/GetTreatmentList?page=${page}&customerId=${customerId}&_=${Date.now()}`;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
                return await request.json();
			}
			throw new Error("Ошибка при получении списка обращений заявителе. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				count: 0,
				page,
				list: []
			}
		}
	};

	/** Удаление заявителя */
	static delete = async ( customerId: string ): Promise<RDC_Response<null>> => {
		const url = window.host + "/Nvx.ReDoc.MfcUiModule/Step02CustomersController/DeleteCustomer?ElementId=" + customerId;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
                return await request.json();
			}
			throw new Error("Ошибка при удалении заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

	/** Сохранение заявителя */
	static save = async ( values: CustomerType, isNew: boolean = false ): Promise<RDC_Response<null>&{customerId: string}> => {
		const url = window.host + "/Nvx.ReDoc.MfcUiModule/Step02CustomersController/CreateNewCustomer";
		const frmData = getCustomerSaveFormData( values );
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
                return await request.json();
			}
			throw new Error("Ошибка при сохранении заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				customerId: "",
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

};

const getCustomerSaveFormData = ( values: CustomerType ): FormData => {
	const frmData = new FormData();
	const vals = values as any;
	const fields = getCustomerFieldListByType(values.customerType);
	fields.forEach( field => {
		const value = ( vals[field.key] !== null && typeof vals[field.key] === "object") ? JSON.stringify(vals[field.key]) : vals[field.key] || '';
		frmData.append( field.frmKey, value );
	});

	const regAddress = values.addressRegistrationManual ? values.regAddress : values.fiasRegAddress;
	const factAddres = values.registrationAddressMatchFactical 
		? regAddress 
		: ( values.addressFacticalManual ? values.factAddress : values.fiasFactAddress );
	frmData.append( "regAddress", JSON.stringify(regAddress) );
	frmData.append( "factAddress", JSON.stringify(factAddres) );

	frmData.append( "issueDate",  !!values?.physicalPassport ? DateFormater.toDateString(values?.physicalPassport.issueDate) : "null" );
	return frmData;
};

const getCustomerFieldListByType = ( type: string ): Array<FormMapItem> => {
	const fields = CustomerCommonFields.map( f => ({ frmKey: f, key: f }) );
	
	const cmnFields = Object.keys(customerCommonMap)
		.map( key => ({
			frmKey: key,
			key: customerCommonMap[key]
		}));
	fields.push( ...cmnFields );

	const map = type === "ul" ? customerULMap : customerOtherMap;
	const additionFields = Object.keys(map)
		.map( key => ({
			frmKey: key,
			key: map[key]
		}));
	fields.push( ...additionFields );

	return fields
};

const CustomerCommonFields: string[] = [
	"recId",
	"customerType",
	"registrationAddressMatchFactical",
	"addressRegistrationManual",
	"addressFacticalManual",
	"inn",
	"ogrn",
	"kpp",
	"country",
	"allowEpguSending"
];

const customerCommonMap: any = {
	customerContacts: "contacts",
	representativeId: "mfcRepresentativeId",
	isEsiaConfirmed: "esiaConfirmed"
};

const customerULMap: any = {
	okopfCode: "okopfCode",
	companyFullName: "companyFullName",
	companyShortName: "companyShortName",
	countryUl: "countryUl",
	directorName: "directorName",
	representativeName: "representativeName",
	directorPost: "directorPost"
};

const customerOtherMap: any = {
	customerName: "physicalName",
	birthDate: "birthDate",
	birthPlace: "birthPlace",
	gender: "gender",
	customerDocType: "customerDocType",
	customerPassportData: "physicalPassport",
	snils: "snils",
	customerDocTitle: "displayDocType"
};

type FormMapItem = {
	frmKey: string;
	key: string;
};
