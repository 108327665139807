import { ErrorMessage, Field, FieldProps } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Input } from 'reactstrap'

interface ITextFieldProps {
	name: string;
	title: string;
	setData: Function;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	placeHolder?: string;
	readOnly?: boolean;
};

/**
 * Текстовое поле
 */
const TextField = (props: ITextFieldProps) => {
	
	const { name, title, setData, readOnly = false, isRequired = false, disabled = false, placeHolder } = props;

	const onChangeValueHandler = ( e: any, form: any ) : any => {
		e.persist();
		form.setFieldValue(name, e.target.value);
		!!setData && setData( (data: any) => {
			return { ...data, [name]: e.target.value }
		});
	};

	return (
		<Field id={name} name={name}>
			{ ( { form, meta, field }: FieldProps ) => (
				<FormGroup>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<Input
						id={ "input_" + name }
						{...field}
						readOnly={readOnly}
						placeholder={placeHolder}
						value={field.value || ''}
						className={classNames('form-control', { 'is-invalid': (meta.error && meta.touched) })}
						type='text'
						onChange={e => onChangeValueHandler(e, form)}
						disabled={disabled}
					/>
					{ (meta.error && meta.touched ) && <ErrorMessage name={name} render={FeedbackMessage} /> }
				</FormGroup>
			)}
		</Field>
	);
}

export default TextField;