import { showModal } from '../Slicers/infoModalSlice';
import { useDispatch } from 'react-redux';

/** Хук для работы с модальными окнами  */
const useModal = () => {

	const dispatch = useDispatch();

	function showError ( text:string ):void;
	function showError ( text:string, title:string ):void;

	function showError ( text:string, title?: string ):void {
		dispatch(showModal({
			type: 2,
			title: (title || "Ошибка"),
			text
		}));
	};

	function showInfo ( text:string ):void;
	function showInfo ( text:string, title:string ):void;

	function showInfo ( text:string, title?: string ):void {
		dispatch(showModal({
			type: 0,
			title: (title || "Инфо"),
			text
		}));
	};

	return [ showError, showInfo ];
};

export default useModal;