import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ModalTypes } from '../Containers/InfoModal';

interface IInfoModalSlicerState {
	isShow: boolean;
	type: ModalTypes;
	title?: string;
	text: string;
};

const initialState: IInfoModalSlicerState = {
	isShow: false,
	type: ModalTypes.debug,
	title: '',
	text: ''
};

interface ModalState extends ShowMessageType {
	type: ModalTypes;
};

interface ShowMessageType {
	title?: string;
	text: string;
};

export const infoModalSlice = createSlice({
	name: 'infomodal',
	initialState,
	reducers: {
		showModal: (state, action: PayloadAction<ModalState>) => {
			state.type = action.payload.type;
			state.title = action.payload.title;
			state.text = action.payload.text;
			state.isShow = true;
		},
		showError: (state, action: PayloadAction<ShowMessageType>) => {
			state.type = ModalTypes.error;
			state.title = action.payload.title;
			state.text = action.payload.text;
			state.isShow = true;
		},
		unsetShow: (state) => {
			state.isShow = false;
			return state;
		},
		toggleShow: (state) => {
			state.isShow = !state.isShow;
			return state;
		},
	},
});

export const { showModal, unsetShow, toggleShow, showError } = infoModalSlice.actions;

export const infoModalActions = infoModalSlice.actions;
export default infoModalSlice;