import classNames from "classnames";
import "./RDCModal.scss";

interface RDCModalProps extends React.HTMLAttributes<HTMLDivElement> {};

export  const RDCModal: React.FC<RDCModalProps> = ({ children, className, style, ...otherProps }) => {
    const modalClasses = classNames( "rdc-modal", className );
    return (
        <div {...otherProps} className={modalClasses} style={style}>
            {children}
        </div>
    );
};
