import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { IconButton, SearchInput, TextHint } from 'src/Components';
import { ContainerType, DNDContainerProps, IItem } from '.';
import { ReactComponent as RemoveIcon } from './Assests/window-close.svg';
import { ReactComponent as AddIcon } from './Assests/plus.svg';
import './DNDContainer.scss';

const reorderColumn = ( draggedColumnId: string, targetColumnId: string, columnOrder: string[] ): any => {
	columnOrder.splice(
	  columnOrder.indexOf(targetColumnId),
	  0,
	  columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
	)
	return [...columnOrder]
};

export const DNDContainer: React.FC<DNDContainerProps> = (props) => {

	const { isLoading = false, vertical = false, isNotExclude = false, list, availableListItems, mainListTitle, availableListTitle, renderToolbar, onChangeList, searchEnable = true, ...other } = props;

	const wrapperRef = useRef<HTMLDivElement>(null);
	const [ search, setSearch ] = useState<string>('');
	const [ draggableItem, setDraggableItem ] = useState<IItem|null>(null);
	const [ scrollPosition, setScrollPosition ] = useState({ top: 0, left: 0 });

	useEffect(() => {
		const element = wrapperRef.current;
		const onScroll = ( e: any ) => {
			setScrollPosition( {
				top: e.target.scrollTop,
				left: e.target.scrollLeft
			});
		};
		if ( !!element ) {
			element.addEventListener("scroll", onScroll );
		}
		return () => { 
			!!element && element.removeEventListener("scroll", onScroll );
		};
	}, []);

	const mainList = useMemo( () => {
		const _tmp: IItem[] = list
			.filter( i => !search || i.title.toLowerCase().indexOf(search) !== -1 )
			.map( i => ({ ...i, listId: "main" }))
			.sort( (a, b) => a.position - b.position );
		return {
			list: _tmp,
			count: _tmp.length
		};
	}, [list, search]);

	const availableList = useMemo( () => {
		const _tmp = availableListItems
			.filter( i => !search || i.title.toLowerCase().indexOf(search) !== -1 )
			.map( i => ({ ...i, listId: "available" }))
			.sort( (a, b) => a.position - b.position );

		return {
			list: isNotExclude ? _tmp : _tmp.filter( ac => !list.filter(pc => pc.id === ac.id).length ),
			count: _tmp.length
		};
	// eslint-disable-next-line
	}, [availableListItems, search, list]);

	const onSearchingHandler = (text: string) => setSearch(text.trim().toLowerCase());

	const onDrugStart = ( e: any, item: IItem ) => {
		setDraggableItem(item);
	};

	const onDragOver = ( e: any ) => {
		e.preventDefault();
		if ( !!e.target.className && e.target.className.indexOf("rdc_dnbcontainer__item ") !== -1 ) {
			e.target.style.boxShadow = "0 4px 3px gray";
		}
	};

	const onDragLeave = ( e: any ) => {
		e.target.style.boxShadow = "none";
	};

	const onClickItemHandler = ( type: "add" | "remove" | "position", item: IItem, orders: Array<string> = [] ) => {
		!!onChangeList && onChangeList( type, orders, item );
	};

	const onDrop = ( e: React.DragEvent<any>, to: ContainerType, item?: IItem ) => {
		(e.target as HTMLBaseElement).style.boxShadow = "none";
		e.preventDefault();
		if ( !draggableItem ) return;
		if ( !item ) {
			if ( to === "main" ) {
				onClickItemHandler( "add", draggableItem );
			}
			else if ( to === "available" ) {
				onClickItemHandler( "remove", draggableItem );
			}
		}
		else {
			if ( to === "available" ) {
				onClickItemHandler( "remove", draggableItem );
			}
			else if ( draggableItem.listId === "main" ) {
				const orders = reorderColumn(draggableItem.id, item.id, mainList.list.map(column => column.id as string));
				!!onChangeList && onChangeList( "position", orders );
			}
			else {
				const targetIndex = mainList.list.findIndex( i => i.id === item.id );
				const orders = mainList.list.map(column => column.id as string);
				orders.splice( targetIndex, 0, draggableItem.id );
				onClickItemHandler( "add", { ...draggableItem, position: targetIndex }, orders );
			}
		}
		setDraggableItem(null);
		e.stopPropagation();
	};

	const renderItem = ( item: IItem, index: number, type: ContainerType ) => {
		const getType = () => type === "main" ? "remove" : "add";
		const itemClasses = "rdc_dnbcontainer__item " + type;
		const isInMainList = type === "main";
		return (
			<li 
				id={ `dnd-c__${item.id}` }
				key={ ( isInMainList ? "column_" : "aval-column_") + index } 
				className={itemClasses} 
				draggable={true}
				onDragStart={ e => onDrugStart(e, item)}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDrop={ e => onDrop(e, type, item)}
			>
				<div id={ `dnd-c__${item.id}_tb` } className="rdc_dnbcontainer__item__toolbar">
					{ !!renderToolbar && renderToolbar( type, item, scrollPosition) }
				</div>
				<TextHint className='rdc_dnbcontainer__item__text' onParent={false}>
					{item.title}
				</TextHint>
				<div className="rdc_dnbcontainer__item__toolbar-std hint hint-lb" data-hint={ isInMainList ? "Удалить" : "Добавить"}>
					<IconButton outline onClick={ () => onClickItemHandler( getType(), item)}>
						{ isInMainList ? <RemoveIcon /> : <AddIcon /> }
					</IconButton>
				</div>
			</li>
		);
	};

	const renderSpinner = () => (<div className='rdc_dnbcontainer__spiner'><span className='rdc_small_spiner m-auto' /></div>);

	const renderEmpty = () => (<li className='rdc_dnbcontainer__empty'>По вашему запросу ничего не найдено</li>);

	const renderContent = () => (
		<>
			<div className="rdc_dnbcontainer__part">
				{
					!!mainListTitle && 
					<h4 className="rdc_dnbcontainer__title headline-default">{mainListTitle}</h4>
				}
				<ul className="rdc_dnbcontainer__list body_large" onDrop={ e => onDrop(e, "main")} onDragOver={ e => e.preventDefault() }>
					{
						!!mainList.count ? 
							mainList.list.map( (item, index) => renderItem(item, index, "main") )
						:
							renderEmpty()
					}
				</ul>
			</div>
			<div className="rdc_dnbcontainer__part">
				{
					!!availableListTitle && 
					<h4 className="rdc_dnbcontainer__title headline-default">{availableListTitle}</h4>
				}
				<ul className="rdc_dnbcontainer__list body_large" onDrop={e => onDrop(e, 'available')} onDragOver={ e => e.preventDefault() }>
					{
						!!availableList.count ? 
							availableList.list.map( (item, index) => renderItem(item, index, "available") )
						:
							renderEmpty()
					}
				</ul>
			</div>
		</>
	);

	return (
		<div {...other} className="rdc_dnbcontainer">
			{ 
				!!searchEnable && 
				<SearchInput 
					className='search'
					placeholder='Введите наименование фильтра' 
					onSearch={onSearchingHandler}
					debounceDelay={500}
				/> 
			}
			<div id="dnd_container" ref={wrapperRef} className={classNames("rdc_dnbcontainer__wrapper", vertical ? 'vertical' : 'horizontal' )}>
				{ isLoading ? renderSpinner() : renderContent() }
			</div>
		</div>	
	);
};
