import { HtmlHTMLAttributes } from 'react';
import classNames from 'classnames';
import './Badge.scss';

interface BadgeProps extends HtmlHTMLAttributes<HTMLDivElement> {
	color?: 'red' | 'yellow' | 'blue' | 'green' | string; 
	pill?: boolean;
	hint?: string;
	disabled?: boolean;
	align?: "left" | "center" | "right";
};

export const Badge: React.FC<BadgeProps> = ({ children, align = "left", className, pill = false, color = "", hint, disabled = false, ...other }) => {
	
	const classes = classNames( "rdc_badge body-small rdc_badge-" + color, className, {
		"rdc_rounded-pill": pill,
		"rdc_disabled": disabled
	});

	const props: any = {
		className: classes,
		...other,
		style: {
			...other.style,
			textAlign: align
		}
	};

	if ( !!hint ) {
		props["data-tooltip-id"] = "rdc_tooltip";
		props["data-tooltip-content"] = hint;
	}

	return (
		<div {...props}>
			<span>{children}</span>
		</div>
	);
};
