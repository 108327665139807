
export enum EnterPultStatusType {
	Offline, 
	Waiting,
	Calling,
	Working,
	OnPause
};

export type EnterStateType = {
	pultStatus: EnterPultStatusType;
	ticket?: EnterTicketModelType;
	message?: string;
};

export type EnterTicketModelType = {
	// Системный идентификатор талона
	id: string;
	// Номер талона
	number: string;
	// Наименование услуги
	operation: string;
	//Время приглашения
	inviteTime: string;
};

export type EnterPauseReasonType = {
	// идентификатор причины перерыва
	id: string;
	// текст причины перерыва
    name: string; 
	// опциональный норматив перерыва для этой причины, в минутах.
    breakDurationMinutes?: number; 
};

export type EnterQueueCountType = {
	// Кол-во талонов в общей очереди
	queueCount: number;
	// Кол-во талонов в персональной очереди
    personalCount: number; 
};

export type EnterQueueItemType = {
	/** Системный идентификатор талона */
    id: string;
	/** Наименование услуги */
    name: string;
	/** Время создания талона */
    timeCreated: string;
	/** Номер талона */
    ticketNumber: string;
	/** Флаг указывающий на принадлежность к персональной очереди */
	isPersonal: boolean;
};

export type DenyReasonType = {
    /** идентификатор причины неоказания услуг */
	id: string;
    /** текст причины неоказания услуги */
	name: string;
};
