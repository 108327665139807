import classNames from "classnames";
import { useToggle } from "src/Hooks";
import RelationField, { RelationFieldProps } from "./RelationField";
import "./RelationControl.scss";

const RelationControl: React.FC<RelationFieldProps> = (props) => {

	const [ isOpened, toggle, setIsOpened ] = useToggle(false);

	const onStateChangeHandler = ( type: string, args: any ) => {
		if ( type === "open" ) {
			setIsOpened( args );
		}
		!!props.onClose && props.onClose();
	};

	return (
		<RelationField 
			{...props} 
			className={ classNames( "rdc-relationfield", props.className) }
			onClick={toggle} 
			defaultOpen
			isPlaceholder={!isOpened}
			isBlockArrow
			onStateChange={onStateChangeHandler}
			onClose={props.onClose}
		/>
	);
};

export default RelationControl;
