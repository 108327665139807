import { useState } from "react";
import { IconButton, Modal } from "src/Components";
import SimpleSelect, { EnumItemType, ValuesType } from "src/Components/Controls/SimpleSelect/SimpleSelect";
import { useEQSelector } from "src/Hooks";
import { IModalProps } from "..";
import { DenyReasonType } from "src/Types/ReDoc/Enter";

interface ICompleteModalProps extends IModalProps {};

const CompleteModal: React.FC<ICompleteModalProps> = ( { type, isOpen, onAction, onClose } ) => {

	const reasonList = useEQSelector( type, "denyReasons" );
	const [ result, setResult ] = useState<ValuesType>(0);
	const [ reason, setReason ] = useState<ValuesType>(0)

	const onActionHandler = () => {
		if ( !result ) {
			onAction( "complete" );
		}
		else {
			onAction( "reasons", reason );
		}
		onClose();
	};

	const renderBody: React.FC = () => (
		<div className="modal_wrapper modal_wrapper-redirect">
			<p className="headline">Результат оказания услуги</p>
			<SimpleSelect isOwnerList options={results} value={result} setValue={setResult} placeholder="Выберите причину..." />
			{
				!!result &&
				<>
					<p className="headline">Причина неоказания услуги</p>
					<SimpleSelect isOwnerList options={getEnums(reasonList)} value={reason} setValue={setReason} placeholder="Выберите причину неоказания услуги..." />
				</>
			}
		</div>
	);

	const renderModalToolbar = () => {
		return (
			<>
				<IconButton outline onClick={onClose}>Отмена</IconButton>
				<IconButton disable={ !!result && !reason } onClick={onActionHandler} >Завершить прием</IconButton> 
			</>
		)
	};

	return (
		<Modal
			isOpen={isOpen}
			title="Завершение работы с посетителем"
			onClose={onClose}
			color="light"
			className="not_scrolled"
			renderFooter={renderModalToolbar()}
			renderBody={renderBody}
		/>
	);
};

const results: Array<EnumItemType> = [
	{
		id: 0,
		title: "Услуга оказана"
	},
	{
		id: 1,
		title: "Услуга не оказана"
	}
];

const getEnums = ( items: Array<DenyReasonType> ): Array<EnumItemType> => {
	return items.map( (item: any) => ({
		id: item.id,
		title: item.name 
	}));
};

export default CompleteModal;
