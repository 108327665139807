import { ReactComponent as IconClose } from "src/Themes/assets/CloseBtn.svg";
import "./RDCModalHeader.scss";

interface RDCModalHeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    onClose: () => void;
	renderHeaderFrame?: () => React.ReactNode;
};

export const RDCModalHeader: React.FC<RDCModalHeaderProps> = ({ children, onClose, renderHeaderFrame }) => (
    <div className="rdc_modalheader">
		{ !!renderHeaderFrame && renderHeaderFrame() }
		<h2 className="rdc_modalheader__title headline-large">{children}</h2>
		<button className="rdc_modalheader__close_btn" onClick={onClose}>
			<IconClose />
		</button>
	</div>
);
