import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./Logo.scss";

interface ILogoProps {
	isCustom?: boolean;
	min?: boolean;
};

const Logo: React.FC<ILogoProps> = ( { isCustom = false, min = false } ) => {

	const size = min ? '24' : "42";
	const { t } = useTranslation();

	return (
		<div className={classNames("rdc-logo__wrapper", { "rdc-logo__wrapper-min": min } )}>
			<div className="rdc-logo__img">
				{
					!isCustom &&
					<svg width={size} height={size} viewBox="0 0 70 70" fill="inherit">
						<rect width="70" height="70" rx="12" fill="inherit"/>
						<g id="Logo arrows">
							<path d="M41 20H33L45.5 34.5161L33 50H41L54 34.5161L41 20Z" fill="white"/>
							<path d="M24 20H16L28.5 34.5161L16 50H24L37 34.5161L24 20Z" fill="white"/>
						</g>
					</svg>
				}
			</div>

			<h1 className="rdc-logo__title headline">
				<span>{ min ? "Re:" : t("logoShortTitle")}</span>
				<span>{ min ? "Doc" : t("logoTitle")}</span>
			</h1>
			
		</div>
	);
};

export default Logo;
