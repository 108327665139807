import React from "react";
import { ReactComponent as EmptyIcon } from './SearchEmpty.svg';
import styles from "./EmptyPanel.module.scss";

interface IEmptyPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    text?: string;
    children?: React.ReactNode;
};

const EmptyPanel: React.FC<IEmptyPanelProps> = ( { children, text = "", ...otherProps } ) => (
    <div {...otherProps}>
		<div className={styles.emptypanel_icon}>
            <EmptyIcon />
        </div>
        <p className={styles.emptypanel_text}>
            { !!children ? children : text }
        </p>
	</div>
);

export default EmptyPanel;
