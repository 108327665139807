import { useState, useCallback } from 'react';
import api from '../API';

/**
 * Кастомный хук для работы я Cookies. 
 * Позволяет читать значения, обновлять значение или опции и удалять по ключу
 * @param key Ключ 
 * @param defaultValue Дефолтное значение
 * @returns Кортеж из значения, функции обновления и удаление куков
 */
const useCookie = (key:string, defaultValue?:string) => {

	const [value, setValue] = useState(() => {
		try {
			const cookie = api.Cookies.get(key);
			if (cookie) 
				return cookie;
			if ( defaultValue )
				api.Cookies.updateCook(key, defaultValue);
			return defaultValue;
		}
		catch (e) {
			console.warn(e);
			return null;
		}
	});

	/**
	 * Функция для обновления значения и опций
	 * @param key Ключ 
	 * @param value Значение
	 * @param options Опции 
	 * @returns True - Удачно, иначе False
	 */
	const updateCook = useCallback( (value:string, options?:any|undefined ):boolean => {
		try {
			api.Cookies.updateCook(key, value, options);
			setValue(value);
			return true;
		}
		catch ( e ) {
			console.warn(e);
			return false;
		}
	}, [key] );

	/**
	 * Функция для удаление кука с указанным ключом
	 * @param key Ключ
	 * @param options Опции
	 * @returns True - Удачно, иначе False
	 */
	const deleteCook = useCallback( (options?: any):boolean => {
		try {
			api.Cookies.deleteCook(key, options);
			setValue(null);
			return true;
		}
		catch ( e ) {
			console.warn(e);
			return false;
		}
	}, [key] );

	return [value, updateCook, deleteCook];
};

export default useCookie;