import { RefObject, createRef, useEffect, useState } from "react";

type useResizeWidthPanelType = ( enable: boolean, disabledSize: number, min?: number, max?: number ) => [
	RefObject<any>,
	(clientX: number) => void,
	boolean
];

/**
 * Хук инкапсулирует логику работы панели с изменяемой мышью размером
 * @param enable Флаг указывает включен ли функционал
 * @param disabledSize Размер панели при отключенном функционале ( в px )
 * @param minWidth Минимальный размер панели ( в px )
 * @param maxWidth Максимальный размер панели ( в px )
 * @returns Массив с ref'ом панели, функция старта ресайза, флаг указывающий на активность ресайза
 */
const useResizeWidthPanel: useResizeWidthPanelType = ( enable = false, disabledSize = 20, minWidth = 300, maxWidth = 530) => {

	const panelRef = createRef<any>();
	const [ width, setWidth ] = useState<undefined | number>(undefined);
	const [ separatorXPosition, setSeparatorXPosition ] = useState<undefined | number>(undefined);
  	const [ dragging, setDragging ] = useState<boolean>(false);

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("touchmove", onTouchMove);
		document.addEventListener("mouseup", onMouseUp);
	
		return () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("touchmove", onTouchMove);
			document.removeEventListener("mouseup", onMouseUp);
		};
	});

	useEffect(() => {
		if (panelRef.current && enable ) {
			if ( !width ) {
				setWidth(panelRef.current.clientWidth);
				return;
			}
			setTimeout( () => {
				if (panelRef.current) {
					panelRef.current.style.minWidth = `${width}px`;
					panelRef.current.style.maxWidth = `${width}px`;
				}
			});
		}
		else {
			panelRef.current.style.minWidth = '';
			panelRef.current.style.maxWidth = '';
			panelRef.current.style.width = `${disabledSize}px`;
		}
	}, [enable, disabledSize, panelRef, width, setWidth]);

	const onMove = (clientX: number) => {
		if (dragging && width && separatorXPosition) {
			const newLeftWidth = width + clientX - separatorXPosition;
			setSeparatorXPosition(clientX);
	
			if (newLeftWidth <= minWidth) {
				setWidth(minWidth);
				return;
			}
			else if ( newLeftWidth >= maxWidth ) {
				setWidth(maxWidth);
				return;
			}
			setWidth(newLeftWidth);
		}
	};
	
	const onStartResize = (clientX: number) => {
		setSeparatorXPosition(clientX);
		setDragging(true);
	};

	const onMouseMove = (e: MouseEvent) => {
		const element = e.target as HTMLBaseElement;
		if ( !element.classList.contains("rdc_table__cell") && element.tagName !== "SPAN" && !element.classList.contains("rdc_dnbcontainer__item__text") ) {
			e.preventDefault();
		}
		onMove(e.clientX);
	};
	
	const onTouchMove = (e: TouchEvent) => {
		onMove(e.touches[0].clientX);
	};
	
	const onMouseUp = () => {
		setDragging(false);
	};

	return [ panelRef, onStartResize, dragging ];
};

export default useResizeWidthPanel;
