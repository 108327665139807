
import { GarAddressType, GarHintType } from "src/Types/ReDoc/Gar";
import { RDC_Response } from "src/Types/ReDoc/RDC_Response";

export class GarApi {

	/** Запрос за подсказками */
	static getHints: ( text: string ) => Promise<RDC_Response<GarHintType[]>> = async ( text ) => {
		const url = window.host + "/Nvx.ReDoc.DynamicFormsGarControl.Web.Controller.GarController/GetHints?name=" + text;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				const result = await request.json();
				return {
					hasError: false,
					errorMessage: "",
					result: result as Array<GarHintType>
				}
			}
			throw new Error("Ошибка при удалении заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: []
			}
		}
	};

	/** Запрос за адресом по ИД адреса */
	static getAddress: ( id: string ) => Promise<RDC_Response<GarAddressType|null>> = async ( id ) => {
		const url = window.host + "/Nvx.ReDoc.DynamicFormsGarControl.Web.Controller.GarController/GetAddressById/" + id;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				const result = await request.json();
				return {
					hasError: false,
					errorMessage: "",
					result: result as GarAddressType
				}
			}
			throw new Error("Ошибка при удалении заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

};