import classNames from "classnames";
import { ContextMenuItemType } from "../types";
import './ContextMenuItem.scss';
import { useEffect, useState } from "react";

export interface IContextMenuItemProps {
    item: ContextMenuItemType;
    onClick: ( e: React.MouseEvent<HTMLAnchorElement|HTMLDivElement>, item: ContextMenuItemType ) => void;
};

interface IExtendMenuItem extends ContextMenuItemType {
	_extended: boolean;
}

/** Компонент пункта контексного меню */
export const ContextMenuItem: React.FC<IContextMenuItemProps> = ( { item, onClick } ) => {

	const [ extendedItem, setExtendedItem ] = useState<IExtendMenuItem>(getExtendedItem(item));

	useEffect( () => {
		setExtendedItem( getExtendedItem(item) );
	}, [ item ] );

	const onClickHandler = ( e: React.MouseEvent<HTMLAnchorElement|HTMLDivElement> ) => {
		if ( !extendedItem ) return;
		if ( !!onClick ) {
			onClick(e, extendedItem);
		}
		if ( extendedItem?.subItems?.length || !!extendedItem?.subMenu ) {
			setExtendedItem( prev => ({
				...prev,
				_extended: !prev._extended
			}));
		}
	};

	const renderContent = () => (
		<>
			<div className={"rdc-cmenu__item__content"}>
				<span className={ classNames( "rdc-cmenu__item-icon", extendedItem?.icon, { "icon_exist": !extendedItem?.icon } )} />
				<span className={"rdc-cmenu__item-text"}>{extendedItem?.label}</span>
			</div>
			{
				(!!extendedItem?.carret || !!extendedItem?.subItems?.length) &&
				<svg width="14" height="32" viewBox="0 0 14 32" fill="inherit">
					<path d="M4.5 21L9.5 16L4.5 11V21Z" fill="inherit"/>
				</svg>
			}
		</>
	);

	const renderExtendItem = () => {

		if ( !!extendedItem?.subMenu ) {
			return (
				<div className="rdc-cmenu__item__submenu">
					{ extendedItem.subMenu() }
				</div>
			);
		}
	};

	if ( extendedItem?.tag === "a" ) {
		return (
			<a className={"rdc-cmenu__item"} onClick={onClickHandler} download={!!extendedItem.isDownload} href={extendedItem.href} aria-disabled={!!extendedItem.disabled}>
				{ renderContent() }
			</a>
		);
	}

	return (
		<div className={"rdc-cmenu__item"} onClick={onClickHandler} aria-disabled={!!extendedItem?.disabled}>
			{ renderContent() }
			{ extendedItem?._extended && renderExtendItem() }
		</div>
	);
};

const getExtendedItem = ( item: ContextMenuItemType ): IExtendMenuItem => ({
	...item,
	_extended: false
});
