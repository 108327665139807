import { NewsResponseType } from 'src/Types';
import { NewEnableType } from '../Types';
import { ContactType } from 'Src/Types/ReDoc/Contact';

type getType = () => Promise<any>;

type getNewEnableType = () => Promise<NewEnableType>;

type getNewsType = (take: number, skip?: number) => Promise<NewsResponseType>;

/**
 * API для работы с плагинами
 */
class APIPlugins {
	
	/** Получение списка доступных плагинов	 */
	static get: getType = async () => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/Controller/MainPageController/GetRegisteredPlugins';
		try {
			const request = await fetch( url, {credentials: "include" });
			if ( request.status === 200 )
				return await request.json();
			else {
				return request.statusText;
			}	
		}
		catch( e: any ) {
			console.warn(e);
		}
	};

	/**	Получение информации о новостной ленте */
	static getNewEnable: getNewEnableType = async () => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/NewsEnabled';
		try {
			const result = await fetch( url, {credentials: "include"});
			if (result.status === 200 ) {
				return await result.json();
			}
		}
		catch( e: any ) {
			console.warn(e);
		}
	};

	/** Запрос наличия непрочитанных новостей */
	static getUnreadNews = async () => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/HasUnreadNews';
		try {
			const result = await fetch( url, {credentials: "include"});
			if (result.status === 200 ) {
				return await result.json();
			}
		}
		catch( e: any ) {
			console.warn(e);
		}
	};

	/** Получение новостей */
	static getNews: getNewsType = async (take = 3, skip = 0) => {
		const url = `${window.host}/Nvx.ReDoc.WebInterfaceModule/GetNewsList?skipPageCount=${skip}&takeCount=${take}`;
		try {
			const result = await fetch( url, {
				method: "GET",
				credentials: "include"
			});
			if (result.status === 200 ) {
				return await result.json();
			}
		}
		catch( e: any ) {
			console.warn(e);
		}
	};

	/** Пометка новостей как прочитано */
	static setAsRead = async () => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/SetNewsAsRead';
		try {
			const result = await fetch( url, { 
				method: "POST", 
				headers: {
					contentType: 'application/json',
					dataType: 'json'
				},
				credentials: "include"
			});
			if (result.status === 200 ) {
				return await result.json();
			}
		}
		catch( e: any ) {
			console.warn(e);
		}
	};

	/** Запрос на получения списка контактов */
	static getSupportContacts = async (): Promise<ContactType[]> => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/GetSupportContacts';
		try {
			const request = await fetch( url, {credentials: "include" });
			if ( request.status === 200 )
				return await request.json();
		}
		catch( e: any ) {
			console.warn(e);
		}
		return [];
	};

};

export default APIPlugins;