import api from '../API';
// import { getUrlVar } from '../Utils';
import axios from 'axios';
import { IAuthTypes, IUserInfo } from '../Types/ReDoc/Auth';
import { ILastCert } from '../Types/Certificate';
import { IRDAnswer, IDataToSign, TypeDomainParams } from 'Src/Types';

/** Класс-сервис авторизации по сертификату */
class AuthCertificate {
	/** Функция возвращает информацию о последнем сохраненном сертификате  */
	static getLastSavedCert = (): ILastCert => {
		const response = {
			certSaved: false,
			thumbprint: '',
			subject: ''
		};

		const lastLoginPair = api.Cookies.get('_last_login_certificate');

		if (lastLoginPair != null && lastLoginPair.indexOf("#") >= 0) {
			response.certSaved = true;
			response.thumbprint = lastLoginPair.split('#')[0];
			response.subject = lastLoginPair.split('#')[1];
		}
		return response;
	};

	/** Авторизация по сертификату  */
	static AuthByCert = async (th: string) => {
		const certInfo = await api.Certificate.getCertInfoByThumbprint(th);
		const dataFoSign = await this._getDataFoSign(certInfo.base64);

		if (!dataFoSign.hasError) {
			return {
				success: false,
				message: dataFoSign.errorMessage
			};
		}

		const { hash, hashAlgId, thumbprint } = dataFoSign.data as IDataToSign;
		const signHash = await api.Certificate.signHash(hash, hashAlgId, thumbprint);
		if (!signHash.success) {
			return {
				success: false,
				message: signHash.message,
				data: signHash.data
			};
		}

		// Получаем параметр returnUrl из строки запроса
		const returnUrl = process.env.NODE_ENV === "development" ? window.devHost : Auth.getUrlVar('returnUrl') || '/';
		const result = await this.performLoginJson(signHash.data, returnUrl || "/");
		if (!result.success) {
			return {
				success: false,
				message: result.message,
				data: result.data
			};
		} 

		api.Cookies.updateCook("_last_login_certificate", `${th}#${certInfo.subjectName}` );

		return {
			success: true,
			message: '',
			data: result.data
		};
	};

	/** Функция для получения данных для авторизации по сертификату  */
	static _getDataFoSign = async ( thumbprintB64:string ): Promise<IRDAnswer> => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/Controller/AuthenticationController/DataToSign';
		const formData = new FormData();
		formData.append( "exportedBase64", thumbprintB64 );
		try {
			const response =  await fetch( url, {
				method: "POST",
				credentials: "include",
				body: formData
			});
			if ( response.status === 200 ) {
				const data = await response.json();
				if ( !!data.hasError ) {
					return {
						hasError: false,
						errorMessage: data.errorMessage,
						data: null
					}
				}
				return {
					hasError: true,
					errorMessage: '',
					data: data
				}
			} 
			else {
				return {
					hasError: false,
					errorMessage: "Не удалось получить с сервера данные для подписи.",
					data: null
				}
			}
		}
		catch ( e ) {
			return {
				hasError: false,
				errorMessage: "Не удалось получить с сервера данные для подписи.",
				data: null
			}
		}
	};

	/** Отправляет запрос на авторизацию по сертификату  */
	static performLoginJson =async ( digest:string, return_url:string ) => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/Controller/AuthenticationController/PerformLoginJson';
		const formData = new FormData();
		formData.append( "digest", digest );
		formData.append( "returnUrl", return_url );
		const result = await fetch( url, {
			method: "POST",
			credentials: "include",
			body: formData
		});
		if (result.status === 200 ) {
			const data = await result.json();
			if ( !!data.hasError ) {
				return {
					success: false,
					message: data.errorMessage,
					data: data
				};
			}
			return {
				success: true,
				data: data.returnUrl
			};
		 }
		 return {
			success: false,
			message: "Не удалось выполнить вход по сертификату."
		};
	}

	static login = async ( thumbprint: string ): Promise<string> => {
		const result = await Auth.Certificate.AuthByCert(thumbprint);
		if ( result?.success ) {
			return result.data;
		} 
		else if ( !!result?.message ) {
			throw new Error(result?.message);
		}
		else {
			throw new Error( "Не удалось получить с сервера данные для подписи.");
		}
	};
}

/** Класс-сервис авторизации KeyCloak */
class AuthKeyCloak {
	/** Запрос статуса авторизации через KeyCloak  */
	static check = async ():Promise<any> => {
		const url = window.host + '/oauth/keycloak/status';
		const response = await axios.get(url);
		if (response.status === 200) {
			return response.data;
		}
		return false;
	};

	/** Запрос редиректа авторизации KeyCloak  */
	static redirectUrlByKeyCloak = async (): Promise<any> => {
		const returnUrl = window.authURLRedirect; //getUrlVar('returnUrl') || "./";
		const url = window.host + "/oauth/keycloak/auth/endpoint?return_url=" + returnUrl; 
		const response = await fetch( url, {credentials: "include"} );
		if (response.ok) {
			const data = await response.json();
			return data;
		} else {
			throw new Error( "Ошибка при авторизации KeyCloak. ");
		}
	};

	static login = () => {
		this.redirectUrlByKeyCloak()
			.then( data => {
				window.location.replace(data.result);
			})
			.catch( e => {
				throw new Error( "Ошибка при авторизации KeyCloak. ", e)
			});
	};
};

/** Класс-сервис авторизации по учетной записи */
class AuthAccount {

	static check = async (): Promise<boolean> => {
		const url = window.host + '/Nvx.ReDoc.DomainAuthModule/Alive';
		try {
			const result = await fetch( url, { credentials: "include" });
			return result.status === 200;
		}
		catch( error: any) {
			return false;
		}
	}

	static login = async ( values: TypeDomainParams ) => {
		const url = window.host + "/Nvx.ReDoc.DomainAuthModule/Login";
		const formData = new FormData();
		formData.append( "login", values.login );
		formData.append( "password", values.password );
		const result = await fetch( url, { method: "POST", credentials: "include", body: formData }); 
		if (result.ok) {
			return result;
		}
		else {
			throw new Error("Ошибка авторизации.")
		}
	};

};

/** Класс-сервис авторизации ЕСИА */
class AuthESIA {
	/** Запрос статуса авторизации через ЕСИА  */
	static check = async (): Promise<any> => {
		const url = window.host + "/WebInterfaceModule/Authentication/esia/authn/show";
		const response = await axios.get( url );
		if (response.status) {
			return response.data;
		}
		return false;
	};

	static login = async () => {
		const returnUrl = Auth.getUrlVar('returnUrl') || '/'; // window.mainURLRedirect; getUrlVar('returnUrl') || "/?ownerId=fileList";

		const url = window.host + '/WebInterfaceModule/EsiaControllers/OAuthLogin/?withCustomer=1&returnUrl=' + returnUrl;
		const result = await fetch(url, {
			credentials: "include",
		});

		if (result.ok) {
			const data = await result.json();
			if (!!data.hasError)
				throw Error(data.errorMessage);
			if (data.redirectUrl) {
				window.location.replace(data.redirectUrl);
				return;
			}
			else {
				throw Error("Адрес для перенаправления пустой");
			}
		}
		else
			throw Error(result.statusText);
	};
};

/** Класс-сервис авторизации */
export default class Auth {

	static redirectToAuthorize = ( url: string = window.location.href ) => {
		// API.Toast.show( false, "Авторизация просрочена. Осуществляется переход на страницу авторизации." );
		setTimeout(() => {
			window.location.href = window.host + "/new#/login?returnUrl=" + url;
		}, 2000 );
	};

	/** Функция выхода ( ДЕ-авторизации )  */
	static logoute = async(): Promise<any> => {
		this.resetData();
		window.location.replace( `${window.host}/WebInterfaceModule/Authentication/Logout?returnUrl=${window.devHost}/new#` );
	};

	/** Сброс пользовательских данных  */
	static resetData = () => {
		//удалеине куки номера окна МФЦ
		api.Cookies.deleteCook('_justLoggedIn', '/');
		api.Cookies.deleteCook('mfcWindowNumber', '/');
		localStorage.removeItem('getOrganizationListcurrentUserOrg');
		localStorage.removeItem('currentGetRegisteredPlugins');
	};

	/** Проверка валидности токена, по его времени жизни */
	static isAuthenticated = () => {
		try {
			const ncfa = api.Cookies.get("_ncfa");
			const justLoggedIn = api.Cookies.get("_justLoggedIn", "false");
			return justLoggedIn === "true" && ncfa !== null;
		} 
		catch (err) {
			console.error("Authentication error:", err);
			return false;
		}
	}

	/** Получение информации пользователя */
	static getUserInfo = async (): Promise<IUserInfo|null> => {
		const url =  window.host + '/Nvx.ReDoc.WebInterfaceModule/Controller/DefaultController/ContactInfoBase';
		try {
			const request = await fetch( url, {	credentials: "include"	});
			if ( request.status === 200 ) {
				const result = await request.json();
				return ( result as IUserInfo);
			}
			return null;
		}
		catch(e:any) {
			console.warn(e);
			return null;
		}
	};

	static getAuthTypes = async (): Promise<IAuthTypes> => {
		const url =  window.host + '/WebInterfaceModule/Authentication/Modules';
		let res = this.initAuthTypes;
		try {
			const crypt = await api.Certificate.validateCryptSystem();
			res.certificate = crypt;
		}
		catch( e: any ) {
			res.certificate = false;
		}
		try {
			const request = await fetch( url, {	credentials: "include"	});
			if ( request.status === 200 ) {
				const result = await request.json();
				return {
					...res, 
					...result 
				};
			}
			return res;
		}
		catch( e: any ) {
			console.warn(e);
			throw e;
		}
	};

	/**  */
	static getUserOrgPrintInfo = async (): Promise<string> => {
		const url = window.host + '/MfcUiModule/MfcUiWebController/GetUserOrgPrintInfo';
		try {
			const request = await fetch( url, {	credentials: "include"	});
			if ( request.status === 200 ) {
				const result = await request.text();
				return result;
			}
			return "Не удалось получить информацию.";
		}
		catch(e:any) {
			console.warn(e);
			return e.message;
		}
	};

	/** сервис авторизации по сертификату */
	static Certificate = AuthCertificate;

	/** сервис авторизации KeyCloak */
	static KeyCloak = AuthKeyCloak;

	/** сервис авторизации ЕСИА */
	static ESIA = AuthESIA;

	/** сервис авторизации по учетной записи */
	static Domain = AuthAccount;

	static initAuthTypes = {
		esia: false,
		domain: false,
		keycloak: false,
		certificate: false
	};

	static getUrlVar = (paramName: string): string => {
		const regex = RegExp('(?<=' + paramName + '=)[^&]*');
		let arr = window.location.href.match(regex);
		return arr ? arr[0] : '';
	}
};
