
import Cookies from './Cookies';
import Auth from './Auth';
import Certificate from './Certificate';
import Plugins from './Plugins';
import Menu from './Menu';
import APIReestr from './Resstr';
import File from './File';
import DamaskAPI from './DamaskAPI';
import APIToast from './Toaste';
import APICustomer from './Customer';
import APIService from './Service';
import { GarApi } from './Gar';
import CashApi from './Cash';
import APITemplate from './Template';
import EnterAPI from './EnterAPI';
import FileWizardAPI from './FileWizard';
import { IResult } from 'Src/Types';
import StepQuestionsService from "./TStepQuestionsService";
import APIReport from "./Reports";

export default class API {

	static Cookies = Cookies;
	static Auth = Auth;
	static Certificate = Certificate;
	static Plugins = Plugins;
	static Menu = Menu;
	static Reestr = APIReestr;
	static File = File;
	static DamaskEQ = DamaskAPI;
	static EnterEQ = EnterAPI;
	static Toast = APIToast;
	static Customer = APICustomer;
	static Service = APIService;
	static Gar = GarApi;
	static Cash = CashApi;
	static Template = APITemplate;
	static FileWizard = FileWizardAPI;
    static StepQuestions = StepQuestionsService;
    static Report = APIReport;

	static prepareJSONResult = async <T>( request: Response ): Promise<IResult<T>> => {
		const result: IResult<T> = {
			success: true,
			message: '',
			data: null
		};
		if ( request.status === 200 ) {
			result.data = await request.json();
			return result;
		}
		else if ( request.status === 401 ) {
			API.Auth.redirectToAuthorize();
			result.message = "Авторизация просрочена. Осуществляется переход на страницу авторизации.";
			return result;
		}
		else {
			result.success = false;
			result.message = `Ошибка при получении реестров. Сервер вернул - (${request.status}): ${request.statusText}`;
		}
		return result;
	};

	static checkUnauthorized = ( request: Response, showMessage: boolean = false ): boolean => {
		if ( [ 401, 403 ].includes(request.status) ) {
			if ( showMessage ) {
				API.Toast.show( false, "Авторизация просрочена. Осуществляется переход на страницу авторизации." );
			}
			API.Auth.redirectToAuthorize();
		}
		return false;
	};

	/**   */
	static getFilterValues = async (url: string, searchTern: string = "", page: number = 0, limit: number = 40 ): Promise<any> => {
		try {
			const uri = window.host + `${url}?term=${searchTern}&page=${page}&limit=${limit}`;
			const result = await fetch( uri, {credentials: "include"} );
			if ( result.status === 200 ) {
				const answer = await result.json();
				if ( Array.isArray(answer ) )
					return answer;
				return answer.list;
			}
			throw new Error("Не удалось получить список доступных фильтров!");
		}
		catch( e: any ) {
			console.warn( "Не удалось получить список доступных фильтров!", e);
		}
	};

}