import React from "react";
import classNames from "classnames";
import { Field, FieldProps, FormikProps } from 'formik';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label } from 'reactstrap'
import { IFieldProps } from '../';
import SimpleSelect from "src/Components/Controls/SimpleSelect";


/**
 * Рендер поля выпадающего списка
 */
const SelectBoxField = (props: IFieldProps) => {

	const { name, title, setData, options, isRequired = false, disabled = false, placeholder } = props;
	
	const onChangeValueHandler = ( value: any, form: FormikProps<any> ) => {
		form.setFieldTouched( name, true );
		form.setFieldValue(name, value);
		!!setData && setData( (data:any) => {
			return { ...data, [name]: value };
		});
	};

	return (
		<Field id={name} name={name}>
			{ ( { form, meta, field }: FieldProps ) => (
				<FormGroup>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<SimpleSelect
						disabled={disabled} 
						placeholder={placeholder}
						value={field.value}
						className={ classNames({ 'is-invalid': ( meta.error && meta.touched ) }) }
						options={options.map( opt => ({ id: opt.value, title: opt.display }) )}
						setValue={ val => onChangeValueHandler( val, form ) }
						onClose={ () => form.setFieldTouched( name ) }
					/>
					{ ( meta.error && meta.touched ) && FeedbackMessage(meta.error) }
				</FormGroup>
			)}
		</Field>
	);
};

export default SelectBoxField;
