
import classNames from "classnames";
import "./UTextList.scss";

interface IUTextListProps extends React.HTMLAttributes<HTMLUListElement>{
	splitBy: string;
	children: string;
	renderMark?: React.FC;
};

const UTextList: React.FC<IUTextListProps> = ( props ) => {
	const { splitBy, renderMark, className, children, ...otherProps } = props;
	const items = children.split(splitBy);
	return (
		<ul {...otherProps} className={classNames("rdc_ul_text", className)} >
			{
				items.map( (item, index) => (
					<li key={ "rdc_ul_text__item_" + index } className="rdc_ul_text__item">
						{ !!renderMark ? renderMark(props) : <div className="rdc_ul_text__item__mark" /> }
						{item}
					</li>
				))
			}
		</ul>
	);
};

export default UTextList;
