import classNames from "classnames";
import "./RDCModalBody.scss";

interface RDCModalBodyProps extends React.HTMLAttributes<HTMLDivElement> {
	pTextCentered?: boolean;
};

export  const RDCModalBody: React.FC<RDCModalBodyProps> = ({ children, className, style, pTextCentered = false }) => {
	const modalClasses = classNames( "rdc_modal__body", className, { "pcenter": pTextCentered } );
	return (
		<div className={modalClasses} style={style}>
			{children}
		</div>
	);
};
