
import classNames from 'classnames';
import { useRef } from 'react';
import { ReactComponent as MenuRight } from 'src/Themes/assets/menu-right.svg';
import { PointType } from '.';

interface IButtonProps {
	caret?: boolean;
	isWait?: boolean;
	iconClass: string;
	title: string; 
	disabled?: boolean;
	color?: string; 
	isActive?: boolean;
	onClick: ( position: PointType ) => void;
};

export const EQMenuButton: React.FC<IButtonProps> = ({ isActive = false, isWait = false, iconClass, title, disabled, onClick, caret = false, color }) => {

	const btnRef = useRef<HTMLLIElement>(null);
	const itemClasses = classNames("menu_item menu_button enter-eq", {
		"menu_item-disabled": disabled,
		"active": isActive
	});
		
	const onClickHandler = (e: any) => {
		e.stopPropagation();
		if ( !!onClick && !!btnRef?.current ) {
			onClick( { left: btnRef.current.offsetLeft, top: ( btnRef.current.offsetTop - 55) });
		}
	};
	
	const renderCaret = () => {
		if ( caret )
			return <MenuRight className="menu_item__arrow" />;
		else 
			return <span className="menu_item__arrow" />;
	};
	
	return (
		<li ref={btnRef} className={itemClasses} style={{ backgroundColor: !!color ? color : '' }} onClick={ (e) => onClickHandler(e)}>
			<div className="menu_item-wrapper" data-tooltip-id="menu-tooltip" data-tooltip-content={title}>
				{ renderCaret() }
				<span className={'menu-icon ' + iconClass} />
				<span className="menu_item-title">{title}</span>
				{ isWait && <span className='rdc_small_spiner' style={{  marginLeft: '1rem', color: 'white', backgroundColor: "white" }}/> }
			</div>
		</li>
	);
};
