import "./style.scss";
import { ReactComponent as Alert } from "./alert_info.svg";

const FeedbackMessage = (message: string) => (
	<div className='rdc invalid-feedback help-block' style={{ display: 'block' }}>
		<Alert />
		{message}
	</div>
);

export default FeedbackMessage;