
import classNames from "classnames";
import "./ScrollContainer.scss";

interface IScrollContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    unstyle?: boolean;
};


const ScrollContainer: React.FC<IScrollContainerProps> = ( { children, className, unstyle = false, ...otherProps } ) => (
    <div className={classNames("scroll-container", className, { "styled": !unstyle } )} {...otherProps}>
        <div className="scroll-container__wrapper">
            { children }
        </div>
    </div>
);

export default ScrollContainer;
