import { TreeListInfo } from "./TreeListInfo";
import { TreeListInfoItem } from "./TreeListInfoItem";

export interface TreeListInfoItemType {
	subTitle: string;
	notMarkerSubTitle?: boolean;
    children?: React.ReactNode | string;
	value?: React.ReactNode | string;
};

export default TreeListInfo;
export { TreeListInfoItem };
