import { EQueueType } from "src/Types/ReDoc/EQueue";
import { useAppSelector } from ".";


const useEQSelector = ( type: EQueueType, filedName: string ) => {
    const part = type === EQueueType.Damask ? "eQueueDamask" : "eQueueEnter";
    const selectorFunc = ( state: any ) => state[part][filedName];
    return useAppSelector( selectorFunc );
};

export default useEQSelector;
