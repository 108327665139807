import './expand-column.scss';

interface HeaderEmptyBoxProps {
	width?: number;
};

export const HeaderEmptyBox: React.FC<HeaderEmptyBoxProps> = ({ width = 32 }) => (
	<th className="rdct_header_cell__emptybox" style={{ maxWidth: width }}>
		<div className="rdct_header_cell__emptybox-wrapper" />
	</th>
);