import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * 
 * @param cashKey 
 * @param initialValue 
 */
const useCashedArray = <T>( cashKey: string, initValue: Array<T> = [] ): [
	Array<T>, 
	// (key: string) => T,
	// (key: string, value: T) => void,
	() => void,
	Dispatch<SetStateAction<Array<T>>>,
	() => Array<T>
] => {

	const getObjectFromCash = () => {
		const strData = localStorage.getItem(cashKey);
		if ( !!strData ) {
			return JSON.parse(strData) as Array<T>;
		}
		else if ( !!initValue )
			return initValue;

		return [];
	};

	const [ data, setData ] = useState<Array<T>>(getObjectFromCash());

	useEffect(() => {
		const strData = JSON.stringify(data);
		localStorage.setItem( cashKey, strData );
	}, [data, cashKey] );

	const updateFromCash = () => {
		const updated = getObjectFromCash();
		setData( updated );
		return updated;
	};

	// const getValue: GetValueType<ValueType<ValueTypes>> = ( key: string ) => {
	//     return data?.[key] ?? null;
	// };

	// const setValue = ( key: string, value: ValueType<ValueTypes>) => {
	//     setData( (prev: any) => ({
	//         ...prev,
	//         [key]: value
	//     }))
	// };

	const resetDictionary = () => {
		localStorage.setItem( cashKey, "[]" );
		setData([]);
	};

	return [ data, resetDictionary, setData, updateFromCash ];
};

export default useCashedArray;
