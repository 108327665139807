import React from 'react';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Tooltip } from 'react-tooltip';
import { Provider } from 'react-redux'
import { InfoModal, BlockedTrober, ThemeProvider } from './Containers';
import { WaitBlock } from './Components';
import RDC, { RDCType } from './Utils/Develop';
import App from './App';
import 'moment/locale/ru';
import store from './store';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Themes/MainApp.scss';

declare global {
	interface Window {
		RDC: RDCType;
		cadesplugin?: any;
		devHost: string;
		host: string;
		authURLRedirect: string;
		mainURLRedirect: string;
		cfg: {
			logRedux: boolean;
		}
	}
}

const globalConfig = () => {
	window.RDC = RDC;
	const url = new URL( window.location.href );
	window.host =  url.origin;
	window.cfg = {
		logRedux: false
	};
	if ( process.env.NODE_ENV === "development" ) {
		console.warn( process.env.NODE_ENV + " mode." );
		window.host =    'http://localhost:29929';
		window.devHost = 'http://localhost:3000';
		window.authURLRedirect = "/#/login";
	} 
	else {
		window.authURLRedirect = "/new#/login";
		window.devHost = window.location.origin;
	}
	window.mainURLRedirect = '#/fileList';
};

const container = document.getElementById("root");
const root = createRoot(container!);

globalConfig();

root.render(
	<React.StrictMode>
		<ThemeProvider>
			<React.Suspense fallback={<WaitBlock />}>
				<Provider store={store}>
						<App />
						<ToastContainer />
						<InfoModal />
						<BlockedTrober />
						<Tooltip id="rdc_tooltip" className='reacttooltip' place='bottom' />
				</Provider>
			</React.Suspense>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
