import { Response, oListItemType } from "src/Types/ReDoc";
import { RDCServiceFullInfoType, ServiceType, ServiceUpdateGroup, ServicesRequestType, ServicesResponse } from "src/Types/ReDoc/Service";
import API from ".";

	
/** */
export default class APIService {
	
	/** Запрос на получение собранной ( Для печати ) информации о сервисе */
	static getServiceInfo = async ( id: string ): Promise<ServiceType> => this._getInfo( id ) as Promise<ServiceType>;
	
	/** Запрос на получение полной информации о сервисе  */
	static getServiceFullInfo = async ( id: string ): Promise<RDCServiceFullInfoType> => this._getInfo( id, true ) as Promise<RDCServiceFullInfoType>;

	static _getInfo = async ( id: string, isJson: boolean = false ): Promise<RDCServiceFullInfoType|ServiceType> => {
		const url = `${window.host}/MfcUiModule/MfcUiWebController/GetServiceInfo/${id}?version=${isJson ? "1" : "0"}`;
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при удалении заявителя. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message
			}
		}
	};

	/** Запрос на получение группового списка сервисов  */
	static getServices = async ( requestBody: ServicesRequestType ): Promise<ServicesResponse> => {
		return await this._getServices(requestBody) as ServicesResponse;
	};

	/** Запрос на обновление списка указанной группы сервисов */
	static updateServices = async ( requestBody: ServicesRequestType ): Promise<ServiceUpdateGroup> => {
		return await this._getServices(requestBody) as ServiceUpdateGroup;
	};

	static _getServices = async ( requestBody: ServicesRequestType ): Promise<any> => {
		const url = window.host + '/MfcUiModule/MfcUiWebController/ServiceGroupsList';
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка услуг. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении списка услуг.",
				count: 0,
				location: null,
				page: 0,
				list: []
			}
		}
	};

	/** Запрос на добавление/удаление сервиса в избранное  */
	static toggleFavorite = async ( recid: string ): Promise<Response<null>> => {
		const url = window.host + "/MfcUiModule/MfcUiWebController/SetServiceInfoFavourite?ElementId=" + recid;
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении списка заявителей. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

	/** Запрос на получение списка местоположений  */
	static getLocations = async ( term: string = '' ): Promise<Array<oListItemType>> => {
		const url = window.host + "/MfcUiModule/MfcUiWebController/LocationSelect2List" + ( !!term ? "?term=" + term : "" );
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении списка местоположений. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return [];
		}
	};

};
