import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * 
 * @param cashKey 
 * @param initialValue 
 */
const useCashedDictionary = <T>( cashKey: string, initValue: T ): [
	T, 
	(key: string) => any,
	(key: string, value: any) => void,
	() => void,
	Dispatch<SetStateAction<T>>,
	() => T
] => {

	const getObjectFromCash = () => {
		const strData = localStorage.getItem(cashKey);
		if ( !!strData ) {
			return JSON.parse(strData) as T;
		}
		else if ( !!initValue )
			return initValue;

		return ( {} as T );
	};

	const [ data, setData ] = useState<T>(getObjectFromCash());

	useEffect( () => {
		setData(getObjectFromCash())
	// eslint-disable-next-line
	}, [cashKey]);

	useEffect(() => {
		if ( !!data ) { 
			const strData = JSON.stringify(data);
			localStorage.setItem( cashKey, strData );
		} 
		else {
			localStorage.setItem(cashKey, "");
		}
	// eslint-disable-next-line
	}, [data, cashKey] );

	const getValue = ( key: string ): any => {
		return (data as any)?.[key] ?? '';
	};

	const setValue = ( key: string, value: any) => {
		setData( (prev: any) => ({
			...prev,
			[key]: value
		}))
	};

	const updateFromCash = () => {
		const data = getObjectFromCash();
		setData( data );
		return data;
	};

	const resetDictionary = () => setData( ( {} as T ) );

	return [ data, getValue, setValue, resetDictionary, setData, updateFromCash ];
};

export default useCashedDictionary;
