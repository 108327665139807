import { Response } from "src/Types/ReDoc";
import { TemplatesRequestType, TemplatesResponse, TemplatesUpdateGroup } from "src/Types/ReDoc/Template";

	
/** */
export default class APITemplate {
	
	/**  */
	static getTemplates = async ( requestBody: TemplatesRequestType ): Promise<TemplatesResponse> => {
		return await this._getTemplates(requestBody) as TemplatesResponse;
	};

	/**  */
	static updateTemplates = async ( requestBody: TemplatesRequestType ): Promise<TemplatesUpdateGroup> => {
		return await this._getTemplates(requestBody) as TemplatesUpdateGroup;
	};

	/**  */
	static _getTemplates = async ( requestBody: TemplatesRequestType ): Promise<any> => {
		const url = window.host + '/WebInterfaceModule/Search/CurrentUserTemplateGroups';
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody)
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка шаблонов. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении списка шаблонов.",
				count: 0,
				location: null,
				page: 0,
				list: []
			}
		}
	};

	/**  */
	static toggleFavorite = async ( recid: string ): Promise<Response<null>> => {
		const url = window.host + "/Nvx.ReDoc.WebInterfaceModule/Search/SetFileTemplateFavorite";
        const frmData = new FormData();
        frmData.append( "id", recid );
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
                body: frmData
			});
			if ( request.status === 200 ) {
				const data = await request.json();
				return data;
			}
			throw new Error("Ошибка при получении списка заявителей. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

};
