
import classNames from "classnames";
import classList from "./Toolbar.module.scss";

interface IToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
    dir?: "start" | "center" | "end";
    column?: boolean;
    alignItems?: "start" | "center" | "right" | "stretch";
    gap?: number;
    padding?: string;
};

/** Компонента для отображения тулбара с возможностью настройки его позиционирования без применения стилей */
const Toolbar: React.FC<IToolbarProps> = ( { children, className, style, alignItems = "start", dir = "left", column = false, gap = 16, padding = '16px', ...otherProps } ) => {

    const getJustifyContent = () => {
        if ( dir === "center" ) return dir;
        else if ( dir === "start" ) return "flex-start";
        else return "flex-end";
    };

    const classes = classNames("rddc_toolbar", className, classList.wrapper );
    const styles: React.CSSProperties = {
        ...style,
        height: column ? "100%" : '',
        alignItems,
        flexDirection: column ? "column" : "row",
        padding,
        gap: `${gap}px`,
        justifyContent: getJustifyContent()
    };

    return (
        <div {...otherProps} className={classes} style={styles}>
            {children}
        </div>
    );
};

export default Toolbar;
