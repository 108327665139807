import classNames from "classnames";
import { TreeListInfoItemType } from ".";
import { TreeListInfoItem } from "./TreeListInfoItem";
import "./TreeListInfo.scss";
import React from "react";

interface ITreeListInfoProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string;
	children?: React.ReactNode | React.ReactNode[] | string[];
	items?: Array<TreeListInfoItemType>;
};

function check (layer: any): layer is React.FC {
	return !!layer.props;
 }

export const TreeListInfo: React.FC<ITreeListInfoProps> = ( { title, children, items, className, ...otherProps } ) => {
	const renderChildren = () => {
		if ( !children ) return null;
		if ( Array.isArray(children) && !!children.length ) {
			return children.map( (child, index) => (
				<TreeListInfoItem key={ "tree-info__item-" + index} subTitle="">
					{ child }
				</TreeListInfoItem>
			));
		}
		else if ( check(children) ) {
			return children;
		}
		else {
			return (
				<TreeListInfoItem subTitle="">
					{ children }
				</TreeListInfoItem>
			);
		}
	};

	const renderItems = () => {
		if ( !!items && !!items.length ) {
			return items.map( (item, index) => (
				<TreeListInfoItem key={ "tree-info__item-" + index} subTitle={item.subTitle}>
					{ !!item.children ? item.children : (item.value || "") }
				</TreeListInfoItem>
			))
		}
	};

	return (
		<div className={classNames("tree-info", className)} {...otherProps}>
			<h5 className="tree-info__title headline-small">{title}</h5>
			<ul className="tree-info__list body-small">
				<>
					{ renderChildren() }
					{ renderItems() }
				</>
			</ul>
		</div>
	);
};
