import { MenuItemType } from "../Types/Menu";

export default class Menu {

	/** Получение списка доступных меню  */
	static getMainMenu: () => Promise<MenuItemType[]> = async () => {
		const url = window.host + '/Nvx.ReDoc.WebInterfaceModule/Controller/MainPageController/NavigationMenu';
		try {
			const request = await fetch(url, { credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при получении меню.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при получении меню', e);
		}
	};

}