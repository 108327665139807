import { useState, useEffect } from 'react';
import { RelationFieldType } from 'src/Types/Form';
import { SelectModal } from "../";
import { EnumsType } from "../SelectModal/SelectModal";
import API from 'src/API';

export interface RelationFieldProps extends React.HTMLAttributes<HTMLDivElement>  {
	
	isPlaceholder?: boolean;
	placeholder?: string;
	disabled?: boolean;
	searchEnable?: boolean;
	showClearBtn?: boolean;

	value?: string | Array<string>;
	setValue?: React.Dispatch<React.SetStateAction<string|Array<string>>>;
	onClear?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	onClose?: () => void;
	onStateChange?: ( type: string, props: any ) => void;

	minSearchLength?: number;
	onSearch?: ( tern: string ) => void;

	relation: RelationFieldType;

	multiple?: boolean;
	defaultOpen?: boolean;
	isOwnerList?: boolean;
	isBlockArrow?: boolean;
};

const RelationField: React.FC<RelationFieldProps> = (props) => {
	
	const { relation, isPlaceholder, value, minSearchLength = 3, isOwnerList = false, isBlockArrow = false, onStateChange, onClose } = props;

	const [ isWait, setIsWait ] = useState<boolean>(false);
	const [ data, setData ] = useState<EnumsType>([]);
	const [ search, setSearch ] = useState<string>('');

	useEffect( () => { 
		if ( !isPlaceholder || !!value ) {
			setIsWait(true);
			API.getFilterValues(relation.url, search)
				.then( result => setData( prepareEnumData(result, relation) ) )
				.catch( (e: any) => {
					console.warn(e);
				})
				.finally( () => setIsWait(false) );
		}
	// eslint-disable-next-line
	}, [ search, isPlaceholder, value ] );

	const onSearchHandler = ( text: string ) => setSearch(text);

	const setValueHandler = (val: string|Array<string>) => {
		if ( !!props.setValue )
			props.setValue(val);
	};

	return (
		<SelectModal 
			{...props}
			setValue={setValueHandler}
			enums={data}
			isWait={isWait}
			onSearch={ relation.serverSideSearch ? onSearchHandler : undefined }
			searchEnable={relation.serverSideSearch}
			minSearchLength={minSearchLength}
			isOwnerList={isOwnerList}
			isBlockArrow={isBlockArrow}
			onStateChange={onStateChange}
			onClose={onClose}
		/>
	);
};

const prepareEnumData = ( data: any, relation: RelationFieldType ): EnumsType => data.map( (item: any) => ({
	id: item[relation.valueKey],
	title: !!relation.titleKey ? item[relation.titleKey] : item[relation.valueKey]
}) );

export default RelationField;
