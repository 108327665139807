import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

interface IWaitingSlicerState {
	isWaiting: boolean;
	variant?: string;
	title?: string;
	isBlockContent?: boolean;
};

const initialState: IWaitingSlicerState = {
	isWaiting: false,
	variant: "global",
	title: "Пожалуйста, подождите...",
	isBlockContent: false
};

export const waitingSlicer = createSlice({
	name: 'waiting',
	initialState,
	reducers: {
		showModal: (state, action: PayloadAction<IWaitingSlicerState>) => {
			state.title = action.payload.title;
			state.variant = action.payload.variant || "global";
			state.isWaiting = true;
		},
		reset: (state) => {
			state.isWaiting = false;
			state.variant = "global";
			state.title = "Пожалуйста, подождите...";
			state.isBlockContent = false;
		},
		unsetShow: (state) => {
			state.isWaiting = false;
		},
		toggleShow: (state, action: PayloadAction<{isShow?: boolean}>) => {
			const newState = action.payload?.isShow === undefined ? !state.isWaiting : action.payload?.isShow;
			state.isWaiting = !!newState;
		},

		setBlockContent: (state, action: PayloadAction<boolean>) => {
			state.isBlockContent = action.payload;
		},
		toggleBlockContent: (state) => {
			state.isBlockContent = !state.isBlockContent;
		},
	},
});

export const getIsBlockContent = (store:RootState):boolean => !!store.waiting.isBlockContent;
export const getIsWait = (store:RootState):boolean => store.waiting.isWaiting;
export const getTitle = (store:RootState):string|undefined => store.waiting.title; 
export const getType = (store:RootState):string|undefined => store.waiting.variant; 

export const { showModal, unsetShow, toggleShow, setBlockContent, toggleBlockContent } = waitingSlicer.actions;

export const isWaitingActions = waitingSlicer.actions;
export default waitingSlicer;