import React, { forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { IconButton } from "src/Components";
import SimpleSelect, { EnumsType } from '../Controls/SimpleSelect';
import { ReactComponent as Shevron } from './Assets/chevron-left.svg';
import clx from './calendar.module.scss'

/** Тип позиции ( top, left ) */
export type PositionType = {
	top: number;
	left: number;
} | null;

interface DatePickerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
	/** Тип календаря */
	type?: "once" | "between" | "list";
	/** Максимальное количество выбранных дат */
	maxSelectedDate?: number;

	values: Array<Date>;
	onSelectDate?: ( value: Date ) => void;
	onChangeValues?: ( values: Date[] ) => void;

	disabled?: boolean;

	minDate?: Date;
	maxDate?: Date;

	position?: PositionType
};

/** Компонента календаря */
const Calendar: React.FC<DatePickerProps> = forwardRef<HTMLDivElement, DatePickerProps>( (props, ref) => {

	const { className, style, type, maxSelectedDate = -1, disabled = false, values, position,
		onSelectDate, onChangeValues,
		minDate = new Date(OLD_YEAR, 0, 1),
 		maxDate = new Date(MAX_YEAR, 11, 31)
	} = props;

	// Текущая Дата ( ЮТС )
	const [ now ] = useState<Date>( () => {
		const tmp = new Date( Date.now() );
		return new Date( tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0,0,0,0 );
	});
	// Месяц
	const [ month, setMonth ] = useState<number>(now.getMonth());
	// Год
	const [ year, setYear ] = useState<number>(now.getFullYear());
	// Массив с датами на текущий месяц + граничные ( 42 значения )
	const [ calendar, setCalendar ] = useState<Date[]>([]);
	// Массив с доступными для выбора годами
	const [ yearList, setYearList ] = useState<Array<number>>([]);
	// Флаг установки начального значения
	const [ changeStart, setChangeStart ] = useState<boolean>(true);

	/** При монтировании компонента, вычисляем список доступных годов  */
	useEffect( () => {
		const years = Array(maxDate.getFullYear() - minDate.getFullYear() + 1)
			.fill(0)
			.map((_, index) => (maxDate.getFullYear() - index));
		setYearList(years);
	// eslint-disable-next-line
	}, []);

	/** Получаем текущие значения 
	 * и устанавливаем на основе флага установки начального значения, 
	 * месяц и год календаря  
	 */
	useEffect(() => {
		setCurrentParams( values[0] );
	// eslint-disable-next-line
	}, [] );

	/** Генерируем массив с днями  */
	useEffect(() => {
		// День недели первого дня, установленного месяца
		const firstDayThisMonth = new Date(year, month, 1).getDay();
		const temp = [];
		for (let i = 0; i < 42; i++) {
			// Онотоле Отаке !!!
			// Определяем отступ (в днях) для отображения соседних дат
			const day = getShiftDayOfFirstDayThisMonth(firstDayThisMonth);
			temp.push(new Date(year, month, i - day, 0, 0, 0, 0 ));
		}
		setCalendar(temp);
	}, [ month, year ] );

	/** Функция вызова одноименной функции из пропсов ( если она назначена ) */
	const onSelectDayHandler = ( day: Date ) => {
		if ( onSelectDate ) {
			onSelectDate(day);
		}
	};

	/** Функция вызова одноименной функции из пропсов ( если она назначена ) */
	const onChangeValuesHandler = ( values: Date[] ) => {
		if ( onChangeValues ) {
			onChangeValues( values );
		}
	};

	/** Устанавливает месяц и год по указанной дате */
	const setCurrentParams = ( date: Date|null ) => {
		const cur = !!date && date.toString() !== "Invalid Date" ? date : now;
		setMonth( cur.getMonth() );
		setYear( cur.getFullYear() );
	};

	/** Функция устанавливает дату  */
	const selectDate = ( e: any, day: Date) => {
		if ( e.nativeEvent.pointerType !== "mouse" ) return;
		setMonth(day.getMonth());
		setYear(day.getFullYear());
		onSelectDayHandler(day);
		if ( type === "between" ) {
			const newDates = [ ...values ];
			if ( changeStart ) {
				newDates[0] = day;
				setChangeStart(false);
			} 
			else {
				newDates[1] = day;
				setChangeStart(true);
			}
			onChangeValuesHandler( newDates );
		} 
		else if ( type === "list" && ( maxSelectedDate === -1 || values.length < maxSelectedDate ) ) {
			values.push(day);
			onChangeValuesHandler( values );
		} 
	};

	/** Слайд месяцев  */
	const changeMonth = ( inc: number ) => {
		let curMonth = month + inc;
		let curYear = year;
	
		if (curMonth === 12) {
			curMonth = 0;
			curYear++;
		} 
		else if (curMonth === -1) {
			curMonth = 11;
			curYear--;
		}
	
		setMonth(curMonth);
		setYear(curYear);
	};

	/** Получение списка классов для дня ( Текущая дата, установленные даты, выходные и дни выходящий из текущего месяца ) */
	const getClassesDay = ( day: Date ) => {
		const isInner = day.getMonth() === month;
		const curDate = day.getTime();
		const vals = values.map( sd => sd.getTime() );
		return [ clx.date,  
			isInner ? '' : clx.outside,
			curDate === now.getTime() ? clx.current : '',
			vals.includes(curDate) ? clx.changed : '',
			isInner && [ 6, 0 ].includes( day.getDay() ) ? clx.weekend : '', 
		].join(' ');
	};

	/** Возвращает массив с именами классов для обвертки над днем ( для подсветки дней находящихся в диапазоне ) */
	const getDayWrapperClasses = (day: Date) => {
		if ( type === "between" ) {
			const curDate = day.getTime();
			const val0 = values[0]?.getTime();
			const val1 = values[1]?.getTime();
			return [ 
				( !!val0 && !!val1 && ( curDate > val0 && curDate < val1 ) ) ? clx.inBetween : '',
			].join(' ');
		}
		return "";
	};

	/** Проверка на доступность дня */
	const isDisabledDay = ( day: Date ) => {
		const curDate = day.getTime();
		if ( curDate < minDate.getTime() || curDate > maxDate.getTime() )
			return true;
		if ( type === "between" ) {
			const startValue = values[0]?.getTime() ?? 0;
			const endValue = values[1]?.getTime() ?? 0;

			if ( !changeStart ) {
				return !!startValue && curDate < startValue;
			}
			else if ( !!endValue )
				return endValue < curDate;
		}
		return false;
	};

	/** Отрисовка дня */
	const renderDay = ( day: Date, index: number ) => (
		<li key={index} className={getDayWrapperClasses(day)}>
			<button 
				type="button"
				className={getClassesDay(day)} 
				onClick={(e) => selectDate(e, day)}
				disabled={isDisabledDay(day) || disabled}
			>
				{day.getDate()}		  	
			</button>
		</li>
	);

	/** Отрисовка тела календаря  */
	const renderBody = () => (
		<div className={clx.body}>
			<ul className={clx.header_days}>
				{
					DAY_NAMES.map( (day, i) => (
						<li className={ cn( clx.header_day, (i > 4 ? clx.weekend : "") ) } key={day}>
							{day}
						</li>
					))
				}
			</ul>
			<ul className={clx.days_container}>
				{ calendar.map(renderDay) }
		 	</ul>
		</div>
	);

	/** Рендер контролов  */
	const renderControls = () => (
		<div className={clx.calendar__controls}>
			<SimpleSelect options={MONTH_NAMES} value={month} setValue={ (e) => setMonth(+e) } placeholder="Месяц"/>
			<SimpleSelect options={yearList} value={year} setValue={ (e) => setYear(+e) } />
			<div className={clx.navbtns}>
				<IconButton outline disable={minDate.getFullYear() === year && minDate.getMonth() === month} onClick={() => changeMonth(-1)}>
					<Shevron width={32} />
				</IconButton>
				<IconButton outline disable={maxDate.getFullYear() === year && maxDate.getMonth() === month} onClick={() => changeMonth(+1)}>
					<Shevron width={32} />
				</IconButton>
			</div>
		</div>
	);

	return (
		<div ref={ref} className={ cn( clx.calendar, className )} style={{...style, ...position}}>
			{renderControls()}
			{renderBody()}
		</div>
	);
});

/** Возвращает сдвиг по дням недели для отображения граничных значений */
const getShiftDayOfFirstDayThisMonth = (firstDayThisMonth: number): number => firstDayThisMonth === 0 ? 5 : ( firstDayThisMonth - 2 );

/** Перечисление с месяцами */
const MONTH_NAMES: EnumsType = [
	{
		id: 0,
		title: 'Январь'
	},
	{
		id: 1,
		title: 'Февраль'
	},
	{
		id: 2,
		title: 'Март'
	},
	{
		id: 3,
		title: 'Апрель'
	},
	{
		id: 4,
		title: 'Май'
	},
	{
		id: 5,
		title: 'Июнь'
	},
	{
		id: 6,
		title: 'Июль'
	},
	{
		id: 7,
		title: 'Август'
	},
	{
		id: 8,
		title: 'Сентябрь'
	},
	{
		id: 9,
		title: 'Октябрь'
	},
	{
		id: 10,
		title: 'Ноябрь'
	},
	{
		id: 11,
		title: 'Декабрь'
	}
];
// Минимальный год
const OLD_YEAR = 1970;
// Максимальный год
const MAX_YEAR = new Date().getFullYear() + 5;
// Дни недели
const DAY_NAMES = ['пн','вт','ср','чт','пт','сб','вс'];

export default Calendar;
