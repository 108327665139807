import { RefObject, useEffect, useRef } from 'react';

const useOutsideClick = <T>( callback: (event: MouseEvent) => void ) => {

	const ref = useRef<T>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !(ref.current as any).contains(event.target as Node)) {
				callback(event);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [callback]);

	return ref as RefObject<T>;
};

export default useOutsideClick;
