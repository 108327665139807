import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { BlockedContainerProps } from '.';
import "./BlockedContainer.scss";

export const BlockedContainer: React.FC<BlockedContainerProps> = ({children, className, style = {}, center = true, isFullScreen = true }) => {

	const renderContent = () => (
		<div className={classNames("rdc_container-block", className, { "fcentered": center })} style={style}>
			{children}
		</div>
	);

	return isFullScreen ? createPortal( renderContent(), document.body ) : renderContent();
}
