import { useEffect, useState } from "react";
import classNames from "classnames";
import { IconButton, Modal, SearchInput } from "src/Components";
import { CheckBox } from "src/Components/Controls";
import SimpleTable, { ColumnType } from "src/Components/SimpleTable";
import { useEQSelector } from "src/Hooks";
import { IModalProps } from "..";
import { EQueueType } from "src/Types/ReDoc/EQueue";
import { EnterQueueItemType } from "src/Types/ReDoc/Enter";
import "../modals.scss";
import "./CallByNumber.scss";

interface ICallByNumberProps extends IModalProps {};

/** Массив с описанием колонок для таблицы с талонами */
const enterColumns: Array<ColumnType> = [
	{
		id: "ticketNumber",
		title: "Номер талона",
		type: "number",
		width: 155
	},
	{
		id: "timeCreated",
		title: "Время выдачи",
		width: 165
	},
	{
		id: "name",
		title: "Услуга"
	}
];

/** Компонента для отображения модального окна для выбора талона */
const CallByNumber: React.FC<ICallByNumberProps> = ({ type, isOpen, onClose, onAction }) => {

	const enterQueue = useEQSelector( type, "queue" );
	const [ talons, setTalons ] = useState<any[]>([]);
	const [ selected, setSelected ] = useState<string>("");
	const [ filter, setFilter ] = useState<string>("");
	const [ isPersonal, setIsPersonal ] = useState<boolean>(false);

	const isEnter = type === EQueueType.Enter;

	useEffect( () => {
		if ( isEnter ) {
			if ( !filter )
				setTalons( enterQueue?.filter( (q: EnterQueueItemType) => q.isPersonal === isPersonal ) );
			else {
				const operations = enterQueue.filter(
					(q: EnterQueueItemType) => {
						const tn = q.ticketNumber.toLowerCase().trim();
						return q.isPersonal === isPersonal && !!tn && tn.indexOf( filter ) !== -1;
					}
				);
				setTalons( operations );
			}
		}
	// eslint-disable-next-line
	}, [ enterQueue, filter, isPersonal ] );

	const onSearchHandler = ( value: string ) => {
		if ( isEnter )
			setFilter( value );
		else 
			setSelected( value );
	};

	const selectHandler = ( index: number ) => setSelected( talons[index].id );

	const onActionClickHandler = () => {
		onAction('inviteByNumber', selected );
		onClose();
	};

	const renderBody: React.FC = ( props ) => (
		<div className={classNames("modal_wrapper", { "modal_wrapper-callbn" : isEnter })}>
			<SearchInput onSearch={onSearchHandler} toLowerCase={isEnter} placeholder={ isEnter ? "Поиск по номеру талона" : "Номер талона" }/>
			{ isEnter && <SimpleTable columns={ enterColumns } data={talons} selectType="single" onSelectRow={selectHandler} /> }
		</div>
	);

	const renderModalToolbar = () => (
		<>
			{ isEnter && <CheckBox name="isPersonal" value={isPersonal} setValue={ v => setIsPersonal(v)}>Персональная очередь</CheckBox> }
			<IconButton outline onClick={onClose}>Закрыть</IconButton>
			<IconButton disable={!selected} onClick={onActionClickHandler} >Выбрать</IconButton> 
		</>
	);

	return (
		<Modal
			isOpen={isOpen}
			title="Вызвать посетителя по талону"
			onClose={onClose}
			color="light"
			className={ classNames("not_scrolled", { "damask": !isEnter } )}
			renderFooter={renderModalToolbar()}
			renderBody={renderBody}
		/>
	);
};

export default CallByNumber;
