import "./Footer.scss";

interface IFooterProps {
	isWidget?: boolean;
	version?: string 
};

const Footer: React.FC<IFooterProps> = ( { version } ) => (
    <footer className="login__footer">
		<div className="login__footer-main">
			{ !!version && <span className="login__footer__ccopy">Создано на базе:</span> }
			<div className="login__footer__logo"></div>
			<span>Re:</span>
			<span className="logo-part">Doc</span>
			<div className="delimiter" />
			<span className="login__footer__copy">
				Единое деловое пространство
			</span>	
		</div>
		{
			!!version && <span className="login__footer__ccopy login__footer__ccopy-ver">{version}</span>
		}
	</footer>
);

export default Footer;
