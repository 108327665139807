import { ChangeEvent, useRef, useState } from "react";

/** Возвращает свойства для работы с инпутом */
const useInputHandler = ( initialValue: string, cb?: ( value: string ) => void, debounceMS: number = 0 ) => {

	const timerRef = useRef<NodeJS.Timeout>();
	const [ value, setValue ] = useState<string>( initialValue );

	const onChange = ( e: ChangeEvent<HTMLInputElement> ) => {
		const val = e.target.value;
		setValue( val );
		if ( !!cb ) {
			if ( !!timerRef?.current ) {
				clearTimeout( timerRef.current );
			}
			timerRef.current = setTimeout( () => {
				cb(val);
				clearTimeout( timerRef.current );
				timerRef.current = undefined;
			}, debounceMS );
		}
	};

	return {
		value,
		onChange,
		setValue
	} as const;
};

export default useInputHandler;
