import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { oListItemType } from "src/Types/ReDoc";
import { EQAuthParamType } from "src/Types";
import { EQueueType } from "src/Types/ReDoc/EQueue";
import { useEQDispatch, useEQSelector } from "src/Hooks";
import { ClearBtn, IconButton, TroberButton } from "src/Components";
import { CheckBox } from "src/Components/Controls";
import { EQLoginForm } from "src/Forms/LoginForm";
import { ReactComponent as LogoIcon } from "../Assets/Logo.svg";
import SimpleSelect, { EnumItemType, ValueType } from "src/Components/Controls/SimpleSelect/SimpleSelect";
import { damaskLogin, damaskStart, damaskGetUserPult } from "src/Slicers/eQueue/DamaskThunks";

interface ILoginModalProps {
	type: EQueueType;
	onLogin: ( isSuccess: boolean ) => void;
	setError: ( text: string, title?: string ) => void;
	onClose: () => void;
};

const LoginModal: React.FC<ILoginModalProps> = ( { type, onLogin, setError, onClose } ) => {

	const navigate = useNavigate();
	const config = useEQSelector( type, "config" );
	const offices = useEQSelector( type, "offices" );

	const [ isWaiting, setIsWaiting ] = useState<boolean>(false);
	const [ renderType, setRenderType ] = useState<number>(0);
	const [ saveAcc, setSaveAcc ] = useState<boolean>(true);
	const [ winNumber, setWinNumber ] = useState<ValueType|ValueType[]>(config.pultId);
	const [ initFields, setInitFields ] = useState<EQAuthParamType>();

	const { dispatchStd, saveConfig, start } = useEQDispatch( type );

	useEffect( () => {
		setInitFields({
			type: type,
			login: config.login,
			password: config.password,
			pultId: config.pultId
		});
	// eslint-disable-next-line
	}, [ config ] );

	useEffect( () => {
		setWinNumber(config.pultId);
	}, [ config.pultId ] );

	const onLoginHandler = ( values: EQAuthParamType ) => {
		setIsWaiting(true);
		if ( saveAcc || type === EQueueType.Enter ) {
			saveConfig( values );
		}
		if ( type === EQueueType.Damask )
			onDamaskLogin();
		else
			opnEnterStart();
	};

	const opnEnterStart = () => {
		start( config.pultId ).unwrap()
		.then ( data => {
			if ( data.hasError ) {
				setError( data.errorMessage || "123", "Ошибка EO" );
				return;
			}
			if ( !!onLogin )
				onLogin( !data.hasError );
		})
		.finally( () => setIsWaiting(false) );
	};

	const onDamaskLogin = () => {
		dispatchStd( damaskLogin() ).unwrap()
			.then ( answer => {
				if ( !answer.hasError ) {
					dispatchStd( damaskGetUserPult() );
					setRenderType(1);
				} 
				else {
					setError( answer.errorMessage || "123", "Ошибка EO" );
				}
			})
			.finally( () => setIsWaiting(false) );
	};

	const onStartHandler = () => {
		setIsWaiting(true);
		dispatchStd( damaskStart( winNumber.toString() ) ).unwrap()
			.then ( data => {
				if ( !!onLogin )
					onLogin( !data.hasError );
			})
			.finally( () => setIsWaiting(false) );
	};

	const onRedirectHandler = () => {
		onClose();
		navigate( "./help", { relative: "path" } );
	};

	const renderChoicesWindow = () => {
		return (
			<div className="eq_login__content-part">
				<p className="eq_login__title">Выбор рабочего места</p>
				<SimpleSelect options={getEnumsByChoiceses(offices)} value={winNumber} setValue={setWinNumber} placeholder="Выберите рабочее место..." />
				<TroberButton block isWaiting={isWaiting} disabled={!winNumber} onClick={onStartHandler}>
					Продолжить
				</TroberButton>
				<IconButton outline onClick={onRedirectHandler}>Не удается войти? Свяжитесь со службой поддержки</IconButton>
			</div>
		);
	};

	const renderLoginForm = () => (
		<div className="eq_login__content-part">
			<p className="eq_login__title">Войти в электронную очередь</p>
			<EQLoginForm
				isWaiting={isWaiting}
				initFields={initFields}
				onSaveHandler={onLoginHandler} 
			/>
			<CheckBox value={saveAcc} setValue={setSaveAcc} name="save_acc" isBlock>Запомнить логин и пароль</CheckBox>
			<IconButton outline onClick={onRedirectHandler}>Не удается войти? Свяжитесь со службой поддержки</IconButton>
		</div>
	);

	return createPortal((
		<div className="eq_login">
			<div className="eq_login__wrapper">
				<ClearBtn className="eq_login__wrapper__closebtn" isClose onClick={onClose} />
				<header className="eq_login__header">
					<LogoIcon />
				</header>
				<div className="eq_login__content">
					{ renderType === 0 && renderLoginForm() }
					{ renderType === 1 && renderChoicesWindow() }
				</div>
			</div>
		</div>
	), document.body );
};

const getEnumsByChoiceses = ( items: oListItemType[] ): Array<EnumItemType> => {
    return items.map( (item: any) => ({
        id: item.id,
        title: !!item.text ? item.text : item.name 
    }));
};

export default LoginModal;
