import { ReestrColumnType, ReestrListType, ReestrType } from "src/Types";
import { IResult } from "src/Types";
import { getAvailableColumns } from "src/mocks";
import API from ".";

/**
 * API для работы с реестрами
 */
export default class APIReestr {

	static getReestrList = async (): Promise<IResult<ReestrListType>> => {
		const url = window.host + '/registry?section=files';
		const request = await fetch(url, { credentials: 'include' });
		return API.prepareJSONResult( request );
	};

	static getReestrItem = async ( id: string): Promise<IResult<ReestrType>> => {
		const url = window.host + '/registry/' + id;
		const request = await fetch(url, { credentials: 'include' });
		return API.prepareJSONResult( request );
	};

	/**  */
	static getAvailableColumns = async (): Promise<IResult<Array<ReestrColumnType>>> => {
		// const url = window.host + '/WebAdmin2/RequestRegistryController/GetAvailableColumns?page=0&limit=40';
		const result: IResult<Array<ReestrColumnType>> = {
			success: true,
			message: '',
			data: null
		};
		try {
			// const request = await fetch(url, { credentials: 'include' });
			// if ( request.status === 200 ) {
				// const data = await request.json();
				const data = await getAvailableColumns(300);
				if ( data.iserror ) {
					result.success = false;
					result.message = data.message || "";
					return result;
				}
				result.data = data.data;
				return result;
			// } else {
			// 	result.success = false;
			// 	result.message = `Ошибка при получении списка доступных столбцов. Сервер вернул - (${request.status}): ${request.statusText}`;
			// }
		}
		catch( error: any ) {
			result.success = false;
			result.message = `Ошибка при получении списка доступных столбцов. ${error.message}`;
		}
		return result;
	}

	/**  */
	static savePreset = async ( newReestrName: string, preset: ReestrType, isReplace: boolean ): Promise<IResult<string>> => {
		const url = window.host + '/registry/' + (isReplace ? newReestrName : '');
		const result: IResult<string> = {
			success: true,
			message: '',
			data: ''
		};
		try {
			const request = await fetch( url, { 
				method: isReplace ? "PUT" : "POST", 
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify(preset)
			});
			if ( request.status === 200 ) {
				const res = await request.json();
				if ( res.hasError ) {
					result.success = false;
					result.message = `Ошибка при сохранении реестра. ${res.errorMessage}`;
				}
				return result;
			}
			else {
				result.success = false;
				result.message = `Ошибка при сохранении реестра. Сервер вернул - (${request.status}): ${request.statusText}`;
			}
		}
		catch( error: any ) {
			result.success = false;
			result.message = `Ошибка при сохранении реестра. ${error.message}`;
		}
		return result;
	};

	/**  */
	static remove = async ( id: string ): Promise<IResult<any>> => {
		const url = window.host + '/registry/' + id;
		const result: IResult<string> = {
			success: true,
			message: '',
			data: ''
		};
		try {
			const request = await fetch( url, { 
				method: "DELETE", 
				headers: {'Content-Type': 'application/json'},
				credentials: 'include'
			});
			if ( request.status === 200 ) {
				const res = await request.json();
				if ( res.hasError ) {
					result.success = false;
					result.message = `Ошибка при удалении реестра. ${res.errorMessage}`;
				}
				return result;
			}
			else {
				result.success = false;
				result.message = `Ошибка при удалении реестра. Сервер вернул - (${request.status}): ${request.statusText}`;
			}
		}
		catch( error: any ) {
			result.success = false;
			result.message = `Ошибка при удалении реестра. ${error.message}`;
		}
		return result;
	};

};
