import { useEffect, useState, MouseEvent } from "react";
import classNames from "classnames";
import { IconButton } from "src/Components";
import { RdcGroupItemType } from ".";
import { ReactComponent as StartIcon } from 'src/Themes/assets/Icon Star.svg';
import { ReactComponent as StarNotFilled } from 'src/Themes/assets/StarNotFilled.svg';
import st from "./RdcGroupItem.module.scss";

interface IRdcGroupItemProps {
	searchText: string;
	item: any;
	enableFavorite?: boolean;
	selectedIdList: Array<string>;
	onAction: ( e: MouseEvent, item: any, action: string ) => void;
	renderItemTb?: ( item: any ) => React.ReactNode;
	onGetParams: ( item: any ) => RdcGroupItemType;
};

export const RdcGroupItem: React.FC<IRdcGroupItemProps> = ( props ) => {

	const { enableFavorite = false, item, searchText, selectedIdList, 
		onAction, renderItemTb, onGetParams
	} = props;

	const [ groupItem ] = useState<RdcGroupItemType>(onGetParams( item ));
	const [ innerText, setInnerText ] = useState(groupItem.title);

	/// TODO: Вынести в хук, если гденибудь потребуется еще такая реализация
	useEffect( () => {
		let search = searchText.replace(new RegExp(/0x00[0-9A-F]{2}/, 'gi'), fromHex);
		const value = groupItem.title;
		if ( !!value ) {
			search = prepareSearchString(search);
			search = escapeRegexpSpecials(search);
			let modifiedValue = value;
			const searchArray = search.split(' ');
			for ( let i = 0; i < searchArray.length; i++ ) {
				if ( searchArray[i].length === 0) {
					continue;
				}
				const searchItem = buildRegexpString(searchArray[i]);
				modifiedValue = modifiedValue.replace(new RegExp(searchItem, 'gi'), replacer);
			}
			setInnerText( modifiedValue );
		}
	// eslint-disable-next-line
	}, [ searchText ] );

	const onActionHandler = ( e: MouseEvent, actionName: string ) => {
		e.preventDefault();
		e.stopPropagation();
		onAction( e, item, actionName );
	};

	const recid = "textFor-" + groupItem.id;
	return (
		<div className={classNames(st.RdcGroupItem, { [st.selected]: selectedIdList.includes(groupItem.id) })} id={item.id} onClick={(e) => onActionHandler(e, "itemClick" )} >
		{
			!!enableFavorite && 
			<div className={st.RdcGroupItem__toolbar_bef}>
				<IconButton 
					outline
					onClick={ e => onActionHandler( e, "toggleFavorite" )} 
					data-tooltip-id="rdc_tooltip" 
					data-tooltip-content={ groupItem.isFavorite ? "Убрать из избранного" : "Добавить в избранное" }
				>
				{
					groupItem.isFavorite
					? <StartIcon width={24} height={24} />
					: <StarNotFilled className={st.snf} width={32} height={32} />
				}
				</IconButton>
			</div>
		}
		<span id={recid} className="body-small" dangerouslySetInnerHTML={{ __html: innerText }} />
		{
			!!renderItemTb &&
			<div className={st.RdcGroupItem__toolbar}>
				{ renderItemTb(item) }
			</div>
		}
		</div>
	);
};

/// TODO: Вынести в хук, если гденибудь потребуется еще такая реализация
const fromHex = ( substring: any ): string => String.fromCharCode(substring);
const replacer = ( text: string ) => '<mark class="rdc_txtmark">' + text + '</mark>';
const escapeRegexpSpecials = ( text: string ) => text.replace(/\\/g, '\\\\').replace(/\+/g, '\\+')
	.replace(/\*/g, '\\*').replace(/\?/g, '\\?')
	.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
	.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
// eslint-disable-next-line			
const prepareSearchString = ( text: string ) => text.replace(/[-\\(\\)"\'«»:;\/_+.,]/g, ' ').replace(/ё/gi, 'е');
const buildRegexpString = ( text: string ) => {
	const initBoundary = '(?<=^|\\s|-|\\(|\\)|"|\'|«|»|:|;|/|_|\\+|\\.|,)';
	if ( text.length > 5 ) {
		return initBoundary + text.substring(0, text.length - 3) + '[а-яёa-z]{0,5}';
	} 
	else if ( text.length < 4 ) {
		return initBoundary + text;
	} 
	else if ( text.length === 4 || text.length === 5 ) {
		return initBoundary + text;;
	}
	return text;
}
