import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export { default as useLocalStorage } from './useLocalStorage';
export { default as useDebounce } from './useDebounceValue';
export { default as useCookie } from './useCookie';

export { default as useModal } from './useModal';
export { default as useIsWaiting } from './useIsWaiting';
export { default as useToggle } from './useToggle';
export { default as useScrollList } from './useScrollList';
export { default as useMediaQuery } from './useMediaQuery';

export { default as useCashedDictionary } from './useCashedDictionary';
export { default as useGridFilters } from './useGridFilters';
export { default as useCashedArray } from './useCashedArray';

export { default as useOutsideClick } from './useOutsideClick';
export { default as useResizePanel } from './useResizePanel';
export { default as useInputHandler } from './useInputHandler';
export { default as useAccessibleFocus } from './useAccessibleFocus';
export { default as useContextMenuHelper } from './useContextMenuHelper';
export { default as usePageTitle } from './usePageTitle';
export { default as useManyClickHandlers } from './useManyClickHandlers';
export { default as useEQDispatch } from './useEQDispatch';
export { default as useEQSelector } from './useEQSelector';
export { default as useUrlParams } from './useUrlParams';
export { default as useRdcNavigate } from './useRdcNavigate';
