import Regex from "src/Utils/Regex";
import { ColumnType, TableColumnEnumType } from "../..";
import DateFormater from "src/Utils/DateFormater";
import './table_cell.scss';

interface ITableCell {
	column?: ColumnType;
	children?: React.ReactNode;
	data?: any;
	value?: any;
	width?: number;
	isExpand?: boolean;
};

/** Вспомогательный метод для поиска элемента перечисления по значению */
const onFindItem = ( item: TableColumnEnumType|string, value: any ): boolean => {
	if ( typeof item === "string" ) {
		return item === value.toString();
	}
	return item.id === value.toString();
};

/** Рендер Ячейки таблицы с данными  */
const TableCell: React.FC<ITableCell> = ( { column, data, children, value, width = 100, isExpand = false} ) => {

	const renderEnum = ( column: ColumnType ) => {
		let val;
		if ( !column.enums || !value )
			val = '-';
		else if ( !!column.enums ) {
			const item = column.enums.find( ei => onFindItem(ei, value) );
			if ( !item ) 
				val = "-";
			else 
				val = typeof item === "string" ? item : item.title;
		}	
		return <span title={val} >{val}</span>;
	};

	const renderContent = () => {
		let val: string = value;
		if ( !column ) {
			if ( !!value && Regex.ISO_8601_FULL.test(value) ) {
				val = DateFormater.toShortString(value);
				return <span title={val}>{val}</span>;
			}
			else
				return <span title={val}>{val}</span>;
		}
		else if ( !!column.cellRender )
			return column.cellRender(isExpand, data);
		else {
			switch ( column.type?.toLowerCase() ) {

				case "datetime":
				case "date": {
					val = DateFormater.toShortString(value);
					return <span title={val} >{val}</span>;
				}
				case "enum": 
					return renderEnum(column);

				case "bool": {
					val = !!value ? 'Да' : 'Нет';
					return <span title={val}>{val}</span>;	
				}

				default: {
					val = data[column.id];
					return <span title={val}>{val}</span>;
				}
			}
		}	
	};

	return (
		<div className="rdc_table__cell" style={{ width: width }} > 
			{ (!column || !column.cellRender) && <span className="rdc_table__cell-icon" /> }
			<div className="rdc_table__cell-content">
				{ renderContent() }
				{ !!children && children }
			</div>
		</div>
	);
};

export default TableCell;
