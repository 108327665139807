
import { ReactNode } from "react";
import { DNDContainer } from "./DNDContainer";

export type ContainerType = "main" | "available";

export interface IItem {
	id: string;
	title: string;
	position: number;
	listId?: string;
};

export interface DNDContainerProps extends React.BaseHTMLAttributes<HTMLDivElement> {

	isLoading?: boolean;
	vertical?: boolean;
	isNotExclude?: boolean;

	list: Array<IItem>;
	availableListItems:  Array<IItem>;
	mainListTitle?: string;
	availableListTitle?: string;

	searchEnable?: boolean;
	renderToolbar?: ( type: ContainerType, item: IItem, scrollPosition: any ) => ReactNode;
	onChangeList?: ( type: "add" | "remove" | "position", list: Array<string>, item?: IItem ) => void;
};

export default DNDContainer;
