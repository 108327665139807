import { useEffect, useState } from "react";

const PREFIX = "Re:Doc ";

const usePageTitle = ( initialName: string, withPrefix: boolean = true ) => {
    const [ title, setTitle ] = useState<string>(withPrefix ? PREFIX + initialName : initialName);
    useEffect(() => { document.title = title; }, [ title ]);
    const changeTitle = ( newTitle: string ) => setTitle( withPrefix ? PREFIX + newTitle : newTitle );
    return [ title, changeTitle ] as const;
};

export default usePageTitle;
