import classNames from "classnames";
import "./Alert.scss";

interface IAlertProps extends React.HTMLAttributes<HTMLDivElement> {
    color?: "red";
};

const Alert: React.FC<IAlertProps> = ({ color, className, children, ...otherProps }) => (
    <div className={classNames( "rdc-alert", className, `rdc-alert-color`)} {...otherProps}>
        { children }
    </div>
);

export default Alert;
