import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EQueueConfigType, WidgetType } from 'src/Types/ReDoc/EQueue';
import { EQModalStates } from 'src/Views/EQueue';
import { DamaskPultStatusType, DamaskQueueStateType, DamaskStatusType } from 'src/Types/ReDoc/Damask';
import { oListItemType } from 'Src/Types/ReDoc';
import { damaskGetConfig, damaskSaveConfig, damaskLogin, damaskStart, damaskGetOfficeStations,
	damaskGetQueueState, damaskGetUserPult, damaskGetPauseReasons 
} from './DamaskThunks';

interface IDamaskSlicerState {
	// Loading states
	loading: 'idle' | 'pending' | 'succeeded' | 'failed';
	error?: string;
	// Common
	config: EQueueConfigType;

	ticket?: {
		id: string;
		number: string;
		inviteTime?: Date|string;
		description: string;
	};

	status: DamaskStatusType;
	queueState: DamaskQueueStateType;
	offices: Array<any>;
	pauseReasons: Array<oListItemType>;
	// Visual 
	widgetState: WidgetType;
	modalState: EQModalStates;
	holdMode: number;
	allCount: number;
};

const initialState: IDamaskSlicerState = {
	loading: 'idle',
	modalState: EQModalStates.None,
	widgetState: WidgetType.None,
	config: {
		contactId: '',
		login: '',
		password: '',
		officeId: -1,
		pultId: '',
		pultName: '',
		queueServerAddress: ''
	},
	status: {
		pultStatus: DamaskPultStatusType.Offline,
		message: ''
	},
	queueState: {
		pultStatus: DamaskPultStatusType.Offline,
		message: '',
		operations: []
	},
	offices: [],
	pauseReasons: [],
	holdMode: 1,
	allCount: 0
};

export const damaskSlicer = createSlice({
	name: 'eQueueDamask',
	initialState,
	reducers: {
		closeModal: ( state ) => {
			state.modalState = 0;
		},
		// Открытие формы входа
        openLoginForm: ( state ) => {
            state.modalState = EQModalStates.Login;
        },
		// Модалка об уходе на перерыв
		askCapModal: ( state ) => {
			state.modalState = EQModalStates.AskCap;
		},
		// Модалка завершения перерыва
		cancelCapModal: ( state ) => {
			state.modalState = EQModalStates.AskExitCap;
		},
		// Модалка выходе из ЭО
		askExitModal: ( state ) => {
			state.modalState = EQModalStates.Exit;
		},
		// Вызов по номеру
		inviteByNumberModal: ( state ) => {
			state.modalState = EQModalStates.AscCallByNumber;
		},
		updateState: ( state, action: PayloadAction<DamaskStatusType> ) => {
			state.status = action.payload;
			state.widgetState = getWidgetStatus(action.payload.pultStatus);
			const ticket = action.payload.ticket;
			if ( !!ticket  ) {
				state.ticket = {
					...ticket,
					id: ticket.systemId,
					number: ticket.idQ,
					inviteTime: !!ticket.startServingTime ? getDateTime( ticket.startServingTime ).toUTCString() : undefined,
					description: ticket.client.name
				};
			} 
			else
				state.ticket = undefined; 
		},
		exit: ( state, action: PayloadAction<DamaskStatusType> ) => {
			state.status = action.payload;
			state.modalState = 0;
			state.widgetState = 0;
		},
		askCancelCall: ( state ) => {
			state.modalState = EQModalStates.AskCancelCall;
		},
		ascDelay: ( state ) => {
			state.modalState = EQModalStates.AskPostPone;
		},
		ascRedirect: ( state ) => {
			state.modalState = EQModalStates.AskRedirect;
		}
	},
	extraReducers: (builder) => {
		// Получение настроек
		builder.addCase( damaskGetConfig.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskGetConfig.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.config = action.payload.result;
				state.loading ='succeeded';
			}
			return state;
		});
		builder.addCase( damaskGetConfig.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// Сохранение настроек
		builder.addCase( damaskSaveConfig.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskSaveConfig.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.config = {
					...state.config,
					...action.meta.arg
				};
				state.loading ='succeeded';
			}
			return state;
		});
		builder.addCase( damaskSaveConfig.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// Login
		builder.addCase( damaskLogin.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskLogin.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				if ( !action.payload.hasError ) {
					state.config.officeId = action.payload.result || -1;
					state.loading ='succeeded';
				}
				else {
					state.error = action.payload.errorMessage;
					state.loading = "failed";
				}
			}
			return state;
		});
		builder.addCase( damaskLogin.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		
		// Получение окон
		builder.addCase( damaskGetOfficeStations.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskGetOfficeStations.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				if ( !action.payload.hasError ) {
					state.loading ='succeeded';
					state.offices = action.payload.result;
				}
				else {
					state.error = action.payload.errorMessage;
					state.loading = "failed";
				}
			}
		});
		builder.addCase( damaskGetOfficeStations.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// Start
		builder.addCase( damaskStart.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskStart.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				if ( !action.payload.hasError ) {
					state.loading ='succeeded';
					state.status = action.payload.result;
					// Закрываем модалки ВСЕ
					state.modalState = 0;
				}
				else {
					state.error = action.payload.errorMessage;
					state.loading = "failed";
				}
			}
		});
		builder.addCase( damaskStart.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// Получение состояния очереди
		builder.addCase( damaskGetQueueState.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskGetQueueState.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				if ( !action.payload.hasError ) {
					state.loading ='succeeded';
					state.queueState = action.payload.result;
				}
				else {
					state.loading = "succeeded";
					state.error = action.payload.errorMessage;
					state.status.pultStatus = action.payload.result.pultStatus;
					state.queueState.pultStatus = action.payload.result.pultStatus;
					state.widgetState = getWidgetStatus(action.payload.result.pultStatus);
					let count = 0;
					action.payload.result.operations.forEach( op => {
						count += op.waitCount;
					});
					state.allCount = count;
					const ticket = action.payload.result.ticket;
					if ( !!ticket  ) {
						state.ticket = {
							id: ticket.systemId,
							number: ticket.idQ,
							inviteTime: getDateTime( ticket.startServingTime ).toUTCString(),
							description: ticket.client.name
						};
					} 
					else
						state.ticket = undefined; 
				}
			}
		});
		builder.addCase( damaskGetQueueState.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// Получение пульта
		builder.addCase( damaskGetUserPult.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskGetUserPult.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.config.pultId = action.payload.pultId;
				state.config.pultName = action.payload.pultName;
			}
		});
		builder.addCase( damaskGetUserPult.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});

		// damaskGetPauseReasons
		builder.addCase( damaskGetPauseReasons.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( damaskGetPauseReasons.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.pauseReasons = action.payload.result;
			}
		});
		builder.addCase( damaskGetPauseReasons.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		
	}
});

const getDateTime = ( value: string| Date ): Date => {
	if ( typeof value === "string" ) {
		const date = new Date( value );
		date.setSeconds( new Date().getSeconds() );
		return date;
	}
	return value;
};

const getWidgetStatus = ( status: number ): WidgetType => {
	switch( status ) {
		case DamaskPultStatusType.Calling: return WidgetType.Calling;
		case DamaskPultStatusType.OnPause: return WidgetType.OnPause;
		case DamaskPultStatusType.Idle:
		case DamaskPultStatusType.Waiting: return WidgetType.Waiting;
		case DamaskPultStatusType.Working: return WidgetType.Working;
		default:
		case DamaskPultStatusType.Offline: return WidgetType.None;
	} 
};

export default damaskSlicer;
