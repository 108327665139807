import * as Yup from 'yup';

export const LoginFormScheme = Yup.object().shape({
	type: Yup.string().nullable(),
	login: Yup.string().required("Необходимо заполнить"),
	password: Yup.string().required("Необходимо заполнить"),

	pultId: Yup.string().nullable()
		.when('type', {
			is: (type: string) => !!type && type === "1",
			then: (schema) => schema.required('Необходимо заполнить')
		})

});

