
import { useMemo, useState } from "react";
import classNames from "classnames";
import { ColumnType } from ".";
import SortBtn, { SortType } from "src/Components/SortBtn";
import "./SimpleTable.scss";

interface ISimpleTableProps {
	columns: Array<ColumnType>;
	data: Array<any>;
	selectType?: "single" | "multiple" | "none";
	initSelectedIndexes?: Array<number>;
	onSelectRow?: ( index: number, list: Array<number> ) => void;
};

type SortStateType = {
	columnName: string;
	sortType: SortType; 
};

export const SimpleTable: React.FC<ISimpleTableProps> = ( props ) => {

	const { columns, data = [], selectType = "none", initSelectedIndexes = [], onSelectRow } = props;
	const [ selected, setSelected ] = useState<Array<number>>(initSelectedIndexes);
	const [ sortState, setSortState ] = useState<SortStateType>( { columnName: '', sortType: null } );

	const checkSelected = ( index: number ) => selected.includes( index );

	const onRowClick = ( e: any, rowIndex: number ) => {
		if ( selectType !== "none" ) {
			if ( selectType === "single" ) {
				setSelected([rowIndex]);
				if ( !!onSelectRow ) 
					onSelectRow( rowIndex, [rowIndex] );
			}
			else {
				const newList = [...selected];
				const index = newList.findIndex( i => i === rowIndex );
				if ( index === -1 ) {
					newList.push(rowIndex);
				} 
				else {
					newList.splice( index, 1 );
				}
				setSelected( newList ); 
				if ( !!onSelectRow ) 
					onSelectRow( rowIndex, newList );
			}
			
		}
	};

	const getSortDir = ( columnName: string ) => sortState?.columnName === columnName ? sortState.sortType : null;
	const onSortChange = ( columnName: string, dir: SortType ) => {
		setSortState( { columnName,	sortType: dir } );
	};

	const sortedData = useMemo( () => {
		if ( !sortState?.columnName || !sortState?.sortType ) {
			return [...data];
		}
		else {
			const column = columns.find( c => c.id === sortState.columnName );
			if ( !column ) {
				throw new Error( "При сортировки не удалось найти описание колонки с ид " + sortState.columnName );
			}
			let newData = [];
			if ( !column.type || column.type === "string" ) {
				newData = [...data].sort( ( a, b) => {
					const x = a[sortState.columnName].toLowerCase();
					const y = b[sortState.columnName].toLowerCase();
					if (x < y) return -1;
					if (x > y) return 1;
					return 0;
				});
			}
			else if ( column.type === "number" ) {
				newData = [...data].sort( (a,b) => a[sortState.columnName] - b[sortState.columnName]);
			}
			if ( sortState.sortType === "DESC" )
				return newData.reverse();
			else 
				return newData;
		}
	}, [ columns, data, sortState ] );

	const renderHeaderCell = (column: ColumnType) => (
		<div className="rdc-stable__head__cell_wrapper">
			<SortBtn enable={column.sortable} dir={getSortDir(column.id)} onChange={ (newDir) => onSortChange( column.id, newDir) } />
			<div className="head-cell__content headline-xsmall">
				{ !!column.title ? column.title : column.id }
			</div>
			<span className="head-cell__icon" />
		</div>
	);

	/** Отрисовка ячейки строки с данными  */
	const renderDataCell = ( rowData: any, column: ColumnType, index: number ) => (
		<td key={`dcell_${column.id}-${index}`} className="rdc-stable__datacell">
			<div className="rdc-stable__datacell__wrapper">
				<span className="data-cell__icon" />
				<div className="data-cell__content headline-xsmall">
					{ rowData[column.id] }
				</div>
			</div>
		</td>
	);

	/** Отрисовка строки с данными  */
	const renderDataRow = ( rowData: any, index: number ) => (
		<tr 
			id={index.toString()} 
			className={classNames("rdc-stable__datarow", { "selected": checkSelected(index) })} 
			key={"data_row-"+ index}
			onClick={ e => onRowClick(e, index) }
		>
			{ columns.map( (column: ColumnType, index: number ) => renderDataCell(rowData, column, index) ) }
		</tr>
	);

	/** Отрисовка ячейки шапки таблицы  */
	const renderHeadCell = ( column: ColumnType, index: number ) => (
		<th key={ "column_" + index } className="rdc-stable__head__cell" style={{ width: column.width }}>
			{ renderHeaderCell(column) }
		</th>
	);

	/** Отрисовка шапки таблицы  */
	const renderHead = () => (
		<thead className="rdc-stable__head">
			<tr className="rdc-stable__head__row">
				{ columns.map( renderHeadCell ) }
			</tr>
		</thead>
	);

	const renderEmpty = () => (
		<tr id="tr_empty">
			<td colSpan={columns.length}>Не найдено</td>
		</tr>
	);

	return (
		<div className="rdc-stable__wrapper">
			<table className="rdc-stable">
				{renderHead()}
				<tbody className="rdc-stable__body">
					{ 
						sortedData.length 
							? sortedData.map( renderDataRow ) 
							: renderEmpty()
					}
				</tbody>
			</table>
		</div>
	);
};
