import { debounce } from 'lodash';

export interface IExtendsClick extends React.UIEvent<HTMLElement> {
	params?: any
};

const useManyClickHandlers = ( ...handlers: Array<(e: IExtendsClick ) => void>) => {
	
	const callEventHandler = (e: React.UIEvent<HTMLElement>, params?: any) => {
		if (e.detail <= 0) return;
		const handler = handlers[e.detail - 1];
		if (handler) {
			handler( {...e, params } );
		}
	};

	const debounceHandler = debounce( function( e: React.UIEvent<HTMLElement>, params ) {
		callEventHandler(e, params);
  	}, 250);

	return ( e: React.UIEvent<HTMLElement>, params: any) => {
		e.persist();
		debounceHandler(e, params);
	};
};

export default useManyClickHandlers;
