import { ReestrColumnType } from "src/Types";

export const availableColumns: Array<ReestrColumnType> = [
	{
		id: "identificator",
		title: "Номер",
		position: 1,
		width: 0,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 2,
		id: "title",
		title: "Наименование дела",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		id: "fileStatus",
		title: "Статус",
		position: 0,
		width: 115,
		filterable: true,
		sortable: true,
		type: "string",
		filter: {
			type: "Relation", 
			multiple: true, 
			fromserver: true, 
			method: "fileStatus", 
			enum: null,
			useDateInterval: false
		}
	},
	{
		position: 5,
		id: "createdAt",
		title: "Дата создания",
		width: 0,
		filterable: true,
		sortable: true,
		type: "date",
		filter: {
		  type: "Date",
		  multiple: false,
		  fromserver: false,
		  method: null,
		  enum: null,
		  useDateInterval: true
		}
	},
	{
		position: 3,
		id: "dueDate",
		title: "Срок выполнения",
		width: 0,
		filterable: true,
		sortable: false,
		type: 'date',
		filter: {
			type: "Date",
			enum: null,
			fromserver: false, 
			method: null, 
			multiple: false,
			useDateInterval: true
		}
	},
	{
		position: 4,
		id: "lastUpdateDate",
		title: "Дата изменения",
		width: 0,
		filterable: true,
		sortable: true,
		type: 'dateTime',
		filter: {
			type: "bool",
			enum: null,
			fromserver: false, 
			method: null, 
			multiple: false,
			useDateInterval: false
		}
	},
	{
		position: 5,
		id: "isArchived",
		title: "В архиве",
		width: 0,
		filterable: true,
		sortable: false,
		type: 'bool',
		filter: {
			type: "bool",
			enum: null,
			fromserver: false, 
			method: null, 
			multiple: false,
			useDateInterval: false
		}
	},
	{
		position: 5,
		id: "owner",
		title: "Поставщик (Владелец)",
		width: 0,
		filterable: true,
		sortable: true,
		type: "string",
		filter: {
			type: "Relation",
		  	multiple: false,
		  	fromserver: true,
		  	method: "owner",
		  	enum: null,
		  	useDateInterval: false
		}
	},
	{
		position: 6,
		id: "requestType",
		title: "Тип запроса",
		width: 0,
		filterable: true,
		sortable: true,
		type: "string",
		filter: {
			type: "Relation",
		  	multiple: false,
		  	fromserver: true,
		  	method: "requestType",
		  	enum: null,
		  	useDateInterval: false
		}
	},
	{
		position: 7,
		id: "author",
		title: "Автор",
		width: 0,
		filterable: true,
		sortable: true,
		type: "string",
		filter: {
			type: "Relation",
		  	multiple: true,
		  	fromserver: true,
		  	method: "author",
		  	enum: null,
		  	useDateInterval: false
		}
	},
	{
		position: 8,
		id: "organisation",
		title: "Организация",
		width: 0,
		filterable: true,
		sortable: true,
		type: "string",
		filter: {
			type: "Relation",
		  	multiple: true,
		  	fromserver: true,
		  	method: "organisation",
		  	enum: null,
		  	useDateInterval: false
		}
	},
	{
		position: 9,
		id: "title1",
		title: "Наименование дела 1",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title2",
		title: "Наименование дела 2",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title3",
		title: "Наименование дела 3",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title4",
		title: "Наименование дела 4",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title5",
		title: "Наименование дела 5",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title6",
		title: "Наименование дела 6",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	},
	{
		position: 9,
		id: "title7",
		title: "Наименование дела 7",
		width: 400,
		filterable: true,
		sortable: true,
		type: "string"
	}
];
