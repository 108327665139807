import React from "react";
import { Field } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Input } from 'reactstrap'
import { IFieldProps, getValue } from '../';

/**
 * Рендер поля выпадающего списка
 */
const SelectBoxField = (props: IFieldProps) => {

	const { name, title, setData, options, errors, touched, isRequired = false, disabled = false } = props;

	const onChangeValueHandler = (e: any, form: any) => {
		e.persist();
		const value = e.target.value;
		form.setFieldValue(name, value);
		!!setData && setData((data: any) => {
			return { ...data, [name]: value };
		});
	}

	const error = !!errors && getValue(errors, name);
	const touch = !!touched && getValue(touched, name);

	return (
		<Field id={name} name={name}>
			{(props: any) => (
				<FormGroup>
					<FormGroup tag="fieldset">
						{
							!!title &&
							<Label htmlFor={name}>
								<b className={isRequired ? 'field_asterix' : ''}>
									{title}
								</b>
							</Label>
						}
						{
							options.map((o, index) => (
								<FormGroup check key={index} className={classNames({ 'is-invalid': (error && touch) })} disabled={disabled}>
									<Input
										type="radio"
										id={"options_"+ o.value}
										{...props.field}
										defaultChecked={o.value === props.field.value}
										value={o.value}
										onChange={e => onChangeValueHandler(e, props.form)}
									/>
									<Label check htmlFor={"options_" + o.value}>{o.display}</Label>
								</FormGroup>
							))
						}
						
					</FormGroup>
					{(error && touch) && FeedbackMessage(error)}
				</FormGroup>
			)}
		</Field>
	);
}

export default SelectBoxField;