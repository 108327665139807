
export default class Regex {

	/**
	* RegExp to test a string for a ISO 8601 Date spec
	*  YYYY
	*  YYYY-MM
	*  YYYY-MM-DD
	*  YYYY-MM-DDThh:mmTZD
	*  YYYY-MM-DDThh:mm:ssTZD
	*  YYYY-MM-DDThh:mm:ss.sTZD
	* @see: https://www.w3.org/TR/NOTE-datetime
	* @type {RegExp}
	*/
	static ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

   /**
	* RegExp to test a string for a full ISO 8601 Date
	* Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
	*  YYYY-MM-DDThh:mm:ss
	*  YYYY-MM-DDThh:mm:ssTZD
	*  YYYY-MM-DDThh:mm:ss.sTZD
	* @see: https://www.w3.org/TR/NOTE-datetime
	* @type {RegExp}
	*/
	static ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

	/**  */
	static DateS = /^\d{2}.\d{2}.\d{4}$/i;

	/**  */
    static INN = /^\d{10}$/;
    /**  */
    static OGRN = /^\d{13}$/;
	/**  */
	static KPP = /^\d{9}$/;
    /**  */
    static OGRIP = /^\d{15}$/;
    /**  */
    static SNILS = /^\d{3}-\d{3}-\d{3} \d{2}$/;
    /**  */
    static PassportSeries = /^\d{2} \d{2}$/;
    /**  */
    static PassportNumber = /^\d{6}$/;
	/**  */
    static PassportIssuedByCode = /^\d{3}-\d{3}$/;
    /**  */
    static Numbers = /^\d+$/;
    /**  */
    static PostIndex = /^\d{6}$/; 

	/** Регулярка на номер телефона с маской */
	static phoneMasked = /^\+\d-[\d]{3}-[\d]{3}-[\d]{2}-[\d]{2}$/;
	/** Регулярка на 11-значный Российский номер телефона ( пр. 79374483468 ) */
	static phone_Rus11 = /^7\d{10}$/;

};