import { useState } from 'react';
import { TextHint } from "src/Components";
import classNames from "classnames";
import { ReactComponent as Arrow } from './arrow.svg';
import { TreeListItemType } from '..';

interface ITreeListItemPros {
	index: number;
	item: TreeListItemType;
	activePreset?: string;
	deathIndex?: number;
	paddingEnclose: number;
	onClick?: (item: TreeListItemType) => void;
	renderToolbar?: ( item: TreeListItemType ) => React.ReactNode;
};

const TreeListItem: React.FC<ITreeListItemPros> = ( { item, activePreset, paddingEnclose, onClick, renderToolbar, index, deathIndex = 0 } ) => {

	const [ exItem, setExItem ] = useState<TreeListItemType>(item);

	const onClickHandler = (e: React.MouseEvent<HTMLLIElement>, item: TreeListItemType ) => {
		e.stopPropagation();
		setExItem( prev => {
			return {
				...prev,
				isExpand: !prev.isExpand
			};
		});
		if ( !!onClick && !exItem.isDisabled ) {
			onClick(item);
		} 
	};

	const getNodeClasses = (item: TreeListItemType) => classNames("treelist_node", {
		"treelist_node_end": !item.subItems?.length,
		"open": item.isExpand,
		"active": exItem.isActive || ( !!item.id && activePreset === item.id),
		"disable": exItem.isDisabled,
		"tb": item.isTbEnable
	});

	const getItemID = () => {
		if ( exItem.isActive || ( !!item.id && activePreset === item.id) ) {
			return "treelist_item_active";
		}
		return "treelist_item_" + (!!exItem.id ? exItem.id : index.toString() );
	}
	const itemSort = ( a: TreeListItemType, b: TreeListItemType ) => a.title.localeCompare( b.title );

	return (
		<li 
			className={getNodeClasses(exItem)} 
			key={exItem.id} 
			id={ getItemID() }
			onClick={ e => onClickHandler(e, exItem) }
		>
			<div className="treelist_node-mnu" style={{ paddingLeft: deathIndex * paddingEnclose }}>
				<Arrow className='treelist-expand_icon' aria-hidden={!exItem.subItems?.length}/>
				<TextHint className="treelist_node-title">{exItem.title}</TextHint>
				{ ( !!renderToolbar && !!item.isTbEnable ) && renderToolbar(exItem) }
			</div>
			{
				(!!exItem.subItems?.length && !!exItem.isExpand ) &&
				<ul className="treelist_node__sub_node">
					{ 
						exItem.subItems.sort( itemSort )
						.map( (i, index) => (
							<TreeListItem 
								key={i.id + '_' + index} 
								paddingEnclose={paddingEnclose} 
								item={i} 
								index={index} 
								renderToolbar={renderToolbar} 
								activePreset={activePreset} 
								onClick={onClick}  
								deathIndex={deathIndex+1}
							/>
						))
					}
				</ul>
			}
		</li>
	);
};

export default TreeListItem;
