import { SetStateAction, useState } from "react";
import { createPortal } from "react-dom";
import { ClearBtn, ConfirmationModal, Footer, IconButton, Logo } from "..";
import { ContactList } from "src/Containers";
import { ContactType } from "src/Types/ReDoc/Contact";
import { isCustomTheme } from "src/Utils";
import { CheckBox } from "../Controls";
import API from "src/API";
import { useLocalStorage } from "src/Hooks";
import './Help.scss';

interface IHelpProps {
    version?: string;
    contactList: Array<ContactType>;
	onClose?: () => void;
	showCloseBtn?: boolean;
};

const Header = (isCustom:boolean) => (
	<header className="login__header">
		<Logo isCustom={isCustom} />
	</header>
);

/** Компонента вывода окна помощи */
const Help: React.FC<IHelpProps> = ( { version, contactList, showCloseBtn = false, onClose } ) => {

	const [ isShowLearning, setIsShowLearning ] = useLocalStorage( "isShowLearning", "false" );
	const [ showConfirm, setShowConfirm ] = useState<boolean>(false);

	const onSetShowLearning = ( value: SetStateAction<boolean> ) => {
		const val = value.valueOf();
		setIsShowLearning( val.toString() );
	};

	const confirmModal = (
		<ConfirmationModal
			color="red"
			title="Сброс кэша браузера"
			text="Вы действительно хотите сбросить кэш браузера? "
			onConfirm={ () => API.Cash.reset() }
			onClose={() => setShowConfirm(false)}
		>
			Сбросятся размеры колонок, фильтры, сортировка, для всех реестров, до состояния по умолчанию заданного в конструкторе.
		</ConfirmationModal>
	);

	return (
		<>
			<div className="help_wrapper">
				{ (showCloseBtn && onClose) && <ClearBtn isClose className="help_wrapper__close" onClick={onClose}></ClearBtn> }
				{Header(isCustomTheme())}
				<ContactList list={contactList}/>
				<div className="controls">
					<CheckBox name="learning" value={ isShowLearning !== "false" } setValue={onSetShowLearning}>
						Включить обучение
					</CheckBox>
					<IconButton color="error" outline onClick={ () => setShowConfirm(true) }>
						Сбросить кэш
					</IconButton>
				</div>
				<Footer version={version} />
			</div>
			{ showConfirm && createPortal( confirmModal, document.body ) }
		</>
	);
};

export default Help;
