import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

interface IPanelSlicerState {
	mainMenuExpanded: boolean;
	subMenuExpanded: boolean;
	subMenuAltMode: boolean;
	inLearning: boolean;
	presetName: string;
};

const initialState: IPanelSlicerState = {
	mainMenuExpanded: true,
	subMenuExpanded: true,
	subMenuAltMode: false,
	inLearning: false,
	presetName: ""
};

export const panelSlicer = createSlice({
	name: 'panelState',
	initialState,
	reducers: {
		setMainMenuExpanded: (state, action: PayloadAction<boolean>) => {
			state.mainMenuExpanded = action.payload;
		},
		toggleMainMenuExpanded: (state, action: PayloadAction) => {
			state.mainMenuExpanded = !state.mainMenuExpanded;
		},

		setSubMenuExpanded: (state, action: PayloadAction<boolean>) => {
			state.subMenuExpanded = action.payload;
		},
		toggleSubMenuExpanded: (state, action: PayloadAction) => {
			state.subMenuExpanded = !state.mainMenuExpanded;
		},

		setSubMenuMode: (state, action: PayloadAction<boolean>) => {
			state.subMenuAltMode = action.payload;
		},
		toggleSubMenuMode: (state, action: PayloadAction<string>) => {
			state.subMenuAltMode = !state.subMenuAltMode;
		},
		setPresetName: ( state, action: PayloadAction<string> ) => {
			if ( !!action?.payload )
				state.presetName =  action.payload;
		},
		toggleLeaningNode: ( state, action: PayloadAction<boolean> ) => {
			state.inLearning = action.payload !== undefined ? action.payload : !state.inLearning;
		},

		setState: (state, action: PayloadAction<IPanelSlicerState>) => {
			state.mainMenuExpanded = action.payload.mainMenuExpanded;
			state.subMenuExpanded = action.payload.mainMenuExpanded;
			state.subMenuAltMode = action.payload.subMenuAltMode;
		}
	},
});

export const getLearningMode = ( store:RootState ): boolean => store.panelState.inLearning;
export const getMainMenuExpanded = (store:RootState):boolean => store.panelState.mainMenuExpanded;
export const getSubMenuExpanded = (store:RootState):boolean => store.panelState.subMenuExpanded; 
export const { setMainMenuExpanded, setSubMenuExpanded, setSubMenuMode, toggleSubMenuMode,
	toggleMainMenuExpanded, toggleSubMenuExpanded, setState, toggleLeaningNode
} = panelSlicer.actions;
export const panelSlicerActions = panelSlicer.actions;
export default panelSlicer;