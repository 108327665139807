import classNames from "classnames";
import "./RDCModalFooter.scss";

interface RDCModalFooterProps extends React.HTMLAttributes<HTMLDivElement> {
	renderFooterLine?: boolean;
};

export  const RDCModalFooter: React.FC<RDCModalFooterProps> = ({ children, className, renderFooterLine = true, ...otherProps }) => {
	const modalClasses = classNames( "rdc_modal__footer", className, { "rdc_modal__footer-topbordered": renderFooterLine } );
	return (
		<div className={modalClasses} {...otherProps}>
			{children}
		</div>
	);
};
