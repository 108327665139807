import { SortOrder, SortState, SortType } from '../../';

export default class Sorting {

    static update = (state: SortState, columnId: string ): SortState => {
        const item = this._getSortItem( state, columnId );
        item.order = this._getNextOrder(item);
        return [ ...state,  item];
    };

    static getOrder = ( state: SortState, columnId: string ): SortOrder => {
        const item = this._getSortItem( state, columnId );
        return item.order;
    }

    static _getSortItem = (state: SortState, columnId: string): SortType => {
        const item = state.find( i => i.column === columnId );
        if ( !!item ) return item;
        else {
            const index = state.push({
                index: state.length,
                column: columnId,
                order: null
            });
            return state[ (index - 1)] ;
        }
    };

    /** Вычисляет следующие состояние сортировки  */
    static _getNextOrder = ( item: SortType ) => {
		if ( !item.order )
			return "ASC";
		else if ( item.order === "ASC" )
			return "DESC";
		else if ( item.order === "DESC" )
			return null;
	};

};