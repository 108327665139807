import { RDC_Response } from "Src/Types/ReDoc/RDC_Response";
import { ReportDataType, ReportsResponse, ReportsUpdateGroup } from "src/Types/Reports";

/** */
export default class APIReport {

    /** Запрос на получение группового списка сервисов  */
	static getGroupList = async (): Promise<ReportsResponse> => {
		return await this._getReports( 0 ) as ReportsResponse;
	};

	/** Запрос на обновление списка указанной группы сервисов */
	static updateGroupList = async ( page: number = 0 ): Promise<ReportsUpdateGroup> => {
		return await this._getReports( page ) as ReportsUpdateGroup;
	};

	/** Получат/обновляет список отчетов */
	static getReport = async ( id: string ): Promise<ReportDataType> => {
		const url = window.host + "/WebInterfaceModule/ReportsDesignerController/GetReport/" + id;
		try {
			const request = await fetch( url, {	credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении лицензии отчета. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении отчета.",
				id: '',
				title: "Ошибка",
				reportTemplate: ""
			}
		}
	};

	/** Получает лицензионный ключ стимулсофта  */
	static getLicense = async (): Promise<RDC_Response<string>> => {
		const url = window.host + "/WebInterfaceModule/ReportsDesignerController/GetLicense";
		try {
			const request = await fetch( url, {
				method: "GET", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении лицензии Stimulsoft'а. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении лицензии Stimulsoft'а.",
				result: ""
			}
		}
	};

	/** Сохраняет отчет  */
	static saveReport = async ( id: string, title: string, reportTemplate: string ): Promise<RDC_Response<string>> => {
		const data = { id, title, reportTemplate };
		const url = window.host + "/WebInterfaceModule/ReportsDesignerController/UpdateReport";
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify( data )
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при сохранения отчета. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при сохранения отчета",
				result: ""
			}
		}
	}

	/** Функция получения/обновления списка отчетов */
	static _getReports = async ( page: number ): Promise<any> => {
		const url = window.host + '/WebInterfaceModule/ReportsDesignerController/GetReportsList';
        const requestBody = {
			clientTimezoneOffset: (new Date()).getTimezoneOffset(),
			clientDate: new Date((new Date()).setHours(0, 0, 0, 0)),
			groupBy: "",
			sortBy: "FullName",
			sortOrder: "ASC",
			page
        };
		try {
			const request = await fetch( url, {
				method: "POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody)
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка отчетов. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			return {
				hasError: true,
				errorMessage: "Ошибка при получении списка отчетов.",
				count: 0,
				location: null,
				page: 0,
				list: []
			}
		}
	};

};
