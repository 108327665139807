import { Zoom, toast } from "react-toastify";

/**
 * API для c уведомлениями
 */
export default class APIToast {

	static show( isSuccess: boolean, message: string, timeout: number|false = 3000 ) {

		const func = isSuccess ? "success" : "error";

		toast[func]( message, {
			position: "top-center",
			autoClose: timeout,
			hideProgressBar: true,
			closeOnClick: true,
			className: isSuccess ? "rdc_toast-success" : "rdc_toast-error",
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored",
			transition: Zoom,
			closeButton: false
		});

	};



}
