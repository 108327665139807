import { useState, useRef } from "react";
import { Formik, Form } from 'formik';
import {Col, Row, ButtonToolbar, Button } from 'reactstrap';

import { IField, ISection, FieldsComponent } from './';
import { TextField } from './Fields';
import './style.scss';

/**
 * Проверка на нулевое значение
 * ( значения 0 и false - считаются. )
 */
export const isNullOrEmpty = (value: any) => !value && value !== false && value !== 0;

interface IUniversalFormProps {
	renderToolbar?: Function;
	btnTitle?:string;
	cols?: number;
	fields: IField[];
	sourceData?: any;
	formSchema?: any;
	disabled?: boolean;
	onSaveHandler: ((values: any) => void | Promise<any>) & Function;

	margin: number;
}

const UniversalForm = (props: IUniversalFormProps) => {

	const { renderToolbar, cols = 3, margin = 12, fields, sourceData, formSchema, onSaveHandler, btnTitle, disabled } = props;

	const formikRef = useRef();

	/**
	 * Возвращает объект с данными для инициализации формы
	 * @param {Array} fields Поля формы
	 * @param {Object} sourceData Объект с данными
	 * @returns 
	 */
	const getInitialValues = (fields: IField[], sourceData: any) => {
		if (!sourceData) {
			let initData: any = {};
			fields.forEach((el: IField) => {
				initData[el.name] = !isNullOrEmpty(el.defaultValue) ? el.defaultValue : null
			});
			return initData;
		} 
		else {
			return { ...sourceData };
		}
	}

	const [data, setData] = useState(getInitialValues(fields, sourceData));

	/**
	 * Разделяет поля на секции
	 * Секция - строка. Если поле имеет параметр полная строка, то занимает всю секцию ( вся ширина ),
	 * иначе секция содержит указанные кол-во равных колонок
	 */
	const calcSections = () : ISection[] => {
		const fieldGroupSections: ISection[] = [{ fields: [], isFull: false }];
		let sectionIndex = 0;
		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];

			if (!field.isFullRow) {
				if (fieldGroupSections[sectionIndex].fields.length < cols) {
					fieldGroupSections[sectionIndex].fields.push(field);
				}
				else {
					sectionIndex = fieldGroupSections.push({ fields: [field], isFull: false }) - 1;
				}
			}
			else {
				fieldGroupSections.push({ fields: [field], isFull: true });
			}
		}
		return fieldGroupSections;
	}

	/**
	 * Отрисовка секции
	 */
	const renderSection = (index: number, section : ISection, errors:any, touched:any) => {
		if (section.isFull)
			return (
				<Row key={"row_" + index}>
					<Col key={"col_" + index} md={12}>
						{renderField(index, section.fields[0], errors, touched)}
					</Col>
				</Row>
			);
		else
			return (
				<Row key={"row_" + index}>
					{section.fields.map((c:any, i: number) => (
						<Col key={"col_" + i} md={12 / cols}>
							{renderField(i, c, errors, touched)}
						</Col>
					))}
				</Row>
			)
	}

	/**
	 * Отрисовка поля
	 */
	const renderField = (index: number, field: IField, errors: any, touched: any) => {

		const FieldComponent = !!FieldsComponent[field.type] ? FieldsComponent[field.type] : TextField;

		return (
			<FieldComponent
				key={field.name + index}
				{...field}
				disabled={disabled}
				value={data[field.name]}
				errors={errors}
				touched={touched}
				setData={setData}
			/>
		);
	}

	/**
	 * Отрисовка тулбара
	 */
	const renderButtons = (isValid: boolean) => {

		if (!!renderToolbar)
			return renderToolbar( isValid, data);

		return (
			<ButtonToolbar style={{ marginTop: margin + 'px' }}>
				<Button color='primary' size='sm' type="submit" disabled={!isValid} block>
					{btnTitle || "Отправить"}
				</Button>
			</ButtonToolbar>
		);
	}

	return (
		<Formik
			refs={ (ref:any) => formikRef.current = ref}
			validationSchema={formSchema}
			initialValues={getInitialValues(fields, sourceData)}
			onSubmit={onSaveHandler}
			validateOnBlur={true}
			enableReinitialize={true}
			validateOnMount={true}
		>
			{({ errors, touched, isValid }) => (
				<Form>
					{calcSections().map((item: ISection, index: number) => renderSection(index, item, errors, touched))}
					{renderButtons(isValid)}
				</Form>
			)}
		</Formik>
	);
};

export const RenderField = (field: IField, disabled: boolean = false) => {
	const FieldComponent = !!FieldsComponent[field.type] ? FieldsComponent[field.type] : TextField;

	return (
		<FieldComponent
			key={field.name}
			{...field}
			disabled={disabled}
		/>
	);
};

export default UniversalForm;