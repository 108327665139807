import { Alert } from "reactstrap";
import { useLocation } from "react-router-dom";

const NotImplemented:React.FC = () => {
    const location = useLocation();
    return (
        <>
            <Alert color="danger">
                <h2 className="headline-xlarge text-center">Находится в разработке</h2>
                <h4 className="headline-large">Роут - {location.pathname}</h4>
            </Alert>
        </>
    );
};

export default NotImplemented;
