import classNames from "classnames";
import { TreeListInfoItemType } from ".";

interface ITreeListInfoItemProps extends React.HTMLAttributes<HTMLLIElement>, TreeListInfoItemType {};

export const TreeListInfoItem: React.FC<ITreeListInfoItemProps> = ( { subTitle, notMarkerSubTitle = false, children, value, className, ...otherProps } ) => (
	<li className={classNames("tree-info__item", className)} {...otherProps}>
		{ 
			!!subTitle && 
			<span className={ "tree-info__item__sub-title " + (notMarkerSubTitle ? "" : "tree-info__item__sub-title-mrk" )}>
				{subTitle}
			</span>
		}
		<span className="tree-info__item__value">
			{ !!children ? children : (value || "") }
		</span>
	</li>
);
