import { EQueue } from "./EQueue";
import { WidgetType } from "src/Types/ReDoc/EQueue";

import { ReactComponent as InviteIcon }  from './Assets/queue_invite.svg';
import { ReactComponent as InviteNumberIcon }  from './Assets/queue_invite-number.svg';
import { ReactComponent as CupIcon }  from './Assets/queue_cup.svg';
import { ReactComponent as ExitIcon }  from './Assets/queue_exit.svg';

import { ReactComponent as AppearIcon }  from './Assets/queue_appear.svg';
import { ReactComponent as RepeatIcon }  from './Assets/queue_repeat.svg';
import { ReactComponent as NotAppearIcon }  from './Assets/queue_notAppear.svg';

import { ReactComponent as PostponeIcon }  from './Assets/queue_postpone.svg';
import { ReactComponent as RedirectIcon }  from './Assets/queue_redirect.svg';
import { ReactComponent as EndServiceIcon }  from './Assets/queue_end-service.svg';

import { ReactComponent as CancelCapIcon }  from './Assets/queue_cap_cancel.svg';

import { ReactComponent as ExitCapIcon } from './Assets/cup-cancel.svg';
import { ReactComponent as BExitIcon } from './Assets/exit-run.svg';
import { ReactComponent as CancelCallIcon } from './Assets/account-remove.svg';


export type EQActionType = {
	name: string;
	title?: string;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	subAction?: Array<EQActionType>;
};

export type EQStateType = {
	text: string;
	color: string;
	actions?: Array<EQActionType>;
};

export type EQStatesType = {
	[key: number]: EQStateType;
};

export const EQStates: EQStatesType = {
	[WidgetType.None]: {
		text: "",
		color: ""
	},
	[WidgetType.Waiting]: {
		text: "Вызвать посетителя",
		color: "#42CD69",
		actions: [
			{
				name: "invite",
				title: "Пригласить посетителя",
				icon: InviteIcon
			},
			{
				name: "asc-inviteByNumber",
				title: "Пригласить посетителя по номеру",
				icon: InviteNumberIcon
			},
			{
				name: "cap",
				title: "Перерыв",
				icon: CupIcon
			},
			{
				name: "asc-exit",
				title: "Выйти",
				icon: ExitIcon
			}
		]
	},
	[WidgetType.Calling]: {
		text: "Ожидание посетителя",
		color: "#FFA900",
		actions: [
			{
				name: "beginServing",
				title: "Посетитель явился",
				icon: AppearIcon
			},
			{
				name: "repeatCall",
				title: "Повторный вызов",
				icon: RepeatIcon
			},
			{
				name: "asc-notAppear",
				title: "Заявитель не явился",
				icon: NotAppearIcon
			}
		]
	},
	[WidgetType.Working]: {
		text: "Работа с посетителем",
		color: "#FF3140",
		actions: [
			{
				name: "asc-delay",
				title: "Отложить прием посетителя",
				icon: PostponeIcon
			},
			{
				name: "asc-redirect",
				title: "Перенаправить посетителя",
				icon: RedirectIcon
			},
			{
				name: "endService",
				title: "Завершить обслуживание посетителя",
				icon: EndServiceIcon,
				subAction: [
					{
						name: "endServiceAndInviteByNumber",
						title: "Завершить обслуживание посетителя и вызвать посетителя по номеру",
						icon: InviteNumberIcon
					},
					{
						name: "endServiceAndCap",
						title: "Завершить обслуживание посетителя и уйти на перерыв",
						icon: CupIcon
					},
					{
						name: "endServiceAndExit",
						title: "Завершить обслуживание посетителя и выйти",
						icon: ExitIcon
					}
				]
			}
		]
	},
	[WidgetType.OnPause]: {
		text: "Перерыв",
		color: "#828F9C",
		actions: [
			{
				name: "cancelCap",
				title: "Завершить перерыв",
				icon: CancelCapIcon
			}
		]
	},
	[WidgetType.Unknown]: {
		text: "",
		color: ""
	}
};

export const enum EQModalStates {
	None,
	Login,
	AskCap,
	AskExitCap,
	AskCancelCall,
	QueueIsEmpty,
	AscCallByNumber,
	AskPostPone,
	AskRedirect,
	AskComplete,
	Exit
};

export type EQModalType = {
	title: string;
	text: string;
	color: string;
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	onCloseTitle?: string;
	onNextState: {
		title: string;
		onAction?: string;
	};
};

export type EQModalTypes = {
	[key: number]: EQModalType;
};

export const EQModals: EQModalTypes = {
	[EQModalStates.AskCap]: {
		title: "Уйти на перерыв",
		text: "Вы действительно хотите уйти на перерыв?",
		color: "error",
		// icon: CapIcon,
		onCloseTitle: "Отмена",
		onNextState: {
			title: "Уйти",
			onAction: "beginPause"
		}
	},
	[EQModalStates.AskExitCap]: {
		title: "Завершить перерыв",
		text: "Вы действительно хотите завершить перерыв?",
		color: "error",
		icon: ExitCapIcon,
		onCloseTitle: "Отмена",
		onNextState: {
			title: "Завершить",
			onAction: "endPause"
		}
	},
	[EQModalStates.AskCancelCall]: {
		title: "Отмена вызова посетителя",
		text: "Вы действительно хотите отменить вызов посетителя?",
		color: "error",
		icon: CancelCallIcon,
		onCloseTitle: "Закрыть",
		onNextState: {
			title: "Отменить",
			onAction: "notAppear"
		}
	},
	[EQModalStates.QueueIsEmpty]: {
		title: "Посетителей нет",
		text: "На данный момент посетителей нет. Попробуйте вызвать посетителя позже.",
		color: "error",
		icon: CancelCallIcon,
		onNextState: {
			title: "Закрыть"
		}
	},
	[EQModalStates.Exit]: {
		title: "Выйти из электронной очереди",
		text: "Вы действительно хотите выйти из электронной очереди?",
		color: "error",
		icon: BExitIcon,
		onCloseTitle: "Отмена",
		onNextState: {
			title: "Выйти",
			onAction: "exit"
		}
	}
};

export type PointType = {
	left: number;
	top: number;
};

export default EQueue;
