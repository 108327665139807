
import classNames from "classnames";
import st from "./WaitBlock.module.scss";

interface IWaitBlockProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
	type?: "short" | "full";
    title?: string
	subTitle?: string;
};

const WaitBlock: React.FC<IWaitBlockProps> = ( { type = "full", title = "Загрузка данных", subTitle = "Пожалуйста, подождите...", className, ...otherProps } ) => (
    <div id="rdc_saveprocessing" className={classNames(st.waiting_block, className)} {...otherProps}>
		<div className={classNames(st.waiting_block__spiner, "rdc_spiner")} />
		<div className={st.waiting_block__texts}>
			<h4 className="headline-large centered">{title}</h4>
			{ type === "full" && <h5 className="headline-small centered">{subTitle}</h5> }
		</div>
	</div>
);

export default WaitBlock;