import { DenyReasonType, EnterQueueCountType } from "src/Types/ReDoc/Enter";
import { EQAuthParamType } from "src/Types";
import { Response } from "src/Types/ReDoc";
import { EQueueConfigType } from "src/Types/ReDoc/EQueue";

export default class EnterAPI {

	static _urls = {
		loadConfig: "/Nvx.ReDoc.EnterEQ/PultController/LoadConfig",
		saveConfig: "/Nvx.ReDoc.EnterEQ/PultController/SaveConfig",
		start: "/Nvx.ReDoc.EnterEQ/PultController/Start",
		// Получает состояние пульта
		pultStatus: "/Nvx.ReDoc.EnterEQ/PultController/PultStatus",
		// Состояние очереди
		getQueueState: "/Nvx.ReDoc.EnterEQ/PultController/QueueCount",
		// Осуществляет попытку вызова следующего человека из очереди
		call: "/Nvx.ReDoc.EnterEQ/PultController/InviteNext",

		personalQueue: "/Nvx.ReDoc.EnterEQ/PultController/PersonalQueue",
		queue: "/Nvx.ReDoc.EnterEQ/PultController/Queue",

		//Завершает сеанс взаимодействия с сервером электронной очереди
		finish: "/Nvx.ReDoc.EnterEQ/PultController/Finish",
		//Осуществляет попытку вызова следующего человека из очереди
		callById: "/Nvx.ReDoc.EnterEQ/PultController/InviteById",
		//Производит повторный вызов текущего клиента
		recall: "/Nvx.ReDoc.EnterEQ/PultController/InviteAgain",
		//Начинает обслуживание текущего клиента
		beginServing: "/Nvx.ReDoc.EnterEQ/PultController/BeginServing",
		//Посетитель не явился
		reject: "/Nvx.ReDoc.EnterEQ/PultController/Reject",
		//Успешное завершение обслуживания посетителя
		complete: "/Nvx.ReDoc.EnterEQ/PultController/Complete",
		// Неуспешное завершение обслуживания посетителя
		deny: "/Nvx.ReDoc.EnterEQ/PultController/Deny",
		// Неуспешное завершение обслуживания посетителя
		denyReasons: "/Nvx.ReDoc.EnterEQ/PultController/GetDenyReasons",
		// Неуспешное завершение обслуживания посетителя
		hold: "/Nvx.ReDoc.EnterEQ/PultController/Hold",
		// Запрос режима откладывания посетителя (дефолт или на время)
		holdMode: "/Nvx.ReDoc.EnterEQ/PultController/HoldMode",
		// Начало паузы
		beginPause: "/Nvx.ReDoc.EnterEQ/PultController/BeginBreak",
		// Возобновление работы после паузы
		endPause: "/Nvx.ReDoc.EnterEQ/PultController/EndBreak",
		// Запрос списка причин перерыва
		pauseReasons: "/Nvx.ReDoc.EnterEQ/PultController/GetBreakReasons"
	};

	/** Получение конфигурации ЭО  */
	static getConfig = async (): Promise<Response<EQueueConfigType>> => {
		try {
			const url = window.host + this._urls.loadConfig;
			const request = await fetch( url, { credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при получении настроек ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при получении настроек ЭО.', e);
		}
	};

	/** Сохранение конфигурации ЭО  */
	static setConfig: ( params: EQAuthParamType ) => Promise<Response<null>> = async ( params ) => {
		const frmData = new FormData();
		frmData.append( "rt", "dfd" );
		frmData.append( "pultId", params.pultId );
		frmData.append( "login", params.login );
		frmData.append( "password", params.password );
		try {
			const url = window.host + this._urls.saveConfig;
			const request = await fetch( url, { method: "POST", credentials: "include", body: frmData });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при сохранении настроек ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при сохранении настроек ЭО.', e);
		}
	};

	/** Авторизация к ЭО  */
	static start = async ( id: string ): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.start;
			const frmData = new FormData();
			frmData.append( "id", id );
			const request = await fetch( url, { method: "POST", credentials: "include", body: frmData });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка входа электронной очереди.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка входа электронной очереди.', e);
		}
	};

	/** Запрос статуса ЭО  */
	static getStatus = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.pultStatus;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения статуса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения статуса ЭО.', e);
		}
	};

	/** Запрос состояния ЭО  */
	static getQueueState = async (): Promise<Response<EnterQueueCountType>> => {
		try {
			const url = window.host + this._urls.getQueueState;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения статуса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения статуса ЭО.', e);
		}
	};

	/** Вызов ЭО  */
	static call = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.call;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка вызова клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка вызова клиента ЭО.', e);
		}
	};

	/** Осуществляет попытку вызова следующего человека из очереди по номеру билета */
	static callById = async ( idQ: string ): Promise<Response<any>> => {
		const frmData = new FormData();
		frmData.append( "id", idQ);
		try {
			const url = window.host + this._urls.callById;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка вызова клиента по ИД ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка вызова клиента ИД ЭО.', e);
		}
	};

	/** Получает список очереди */
	static getQueue = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.queue;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса на получение очереди.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса на получение очереди.', e);
		}
	};

	/** Получает список персональной очереди */
	static getPersonalQueue = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.personalQueue;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса на получение очереди.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса на получение очереди.', e);
		}
	};

	/** Старт перерыва ЭО  */
	static beginPause = async ( reason: string ): Promise<Response<any>> => {
		const frmData = new FormData();
		frmData.append( "reason", reason );
		try {
			const url = window.host + this._urls.beginPause;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запросе на перерыв статуса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запросе на перерыв ЭО.', e);
		}
	};

	/** Остановка перерыва ЭО  */
	static endPause = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.endPause;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса на окончание перерыва ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса на окончание перерыва ЭО.', e);
		}
	};

	/** Получает список разрешённых причин перерыва с сервера ЭО  */
	static getPauseReasons = async (): Promise<Response<any[]>> => {
		try {
			const url = window.host + this._urls.pauseReasons;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения списка разрешённых причин перерыва ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения списка разрешённых причин перерыва ЭО.', e);
		}
	};

	/** Завершение работы с сервером ЭО  */
	static finish = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.finish;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include"
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка завершении сеанса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка завершении сеанса ЭО.', e);
		}
	};

	/** Производит повторный вызов текущего клиента */
	static recall = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.recall;
			const request = await fetch( url, { method: "POST", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка повторного вызова клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка повторного вызова клиента ЭО.', e);
		}
	};

	/** Отменяет обслуживание текущего клиента  */
	static reject = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.reject;
			const request = await fetch( url, { 
				method: "POST",
				credentials: "include"
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при отмене обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при отмене обслуживания клиента ЭО.', e);
		}
	};

	/** Начало обслуживания ЭО  */
	static beginServing = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.beginServing;
			const request = await fetch( url, { method: "POST", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка начала обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка начала обслуживания клиента ЭО.', e);
		}
	};

	/** Получение режима откладывания посетителя */
	static getHoldMode = async (): Promise<Response<number>> => {
		try {
			const url = window.host + this._urls.holdMode;
			const request = await fetch( url, { method: "GET", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса режима откладывания посетителя ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса режима откладывания посетителя ЭО.', e);
		}
	};

	/** Запрос списка причин неоказания услуги */
	static getDenyReasons = async (): Promise<Response<DenyReasonType[]>> => {
		try {
			const url = window.host + this._urls.denyReasons;
			const request = await fetch( url, { method: "GET", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса списка причин неоказания услуги ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса списка причин неоказания услуги ЭО.', e);
		}
	};

	/** Перемещение обслуживаемого посетителя в персональную очередь */
	static hold = async ( minutes: string = "-1" ): Promise<Response<any>> => {
		const withTime = minutes !== "-1";
		const frmData = new FormData();
		frmData.append( "minutes", minutes );
		try {
			const url = window.host + this._urls.hold;
			const request = await fetch( url, { method: "POST", credentials: "include", body: withTime ? frmData : null } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при перемещение обслуживаемого посетителя в персональную ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при перемещение обслуживаемого посетителя в персональную ЭО.', e);
		}
	};

	/** Неуспешное завершение обслуживания посетителя */
	static deny = async ( id: string ): Promise<Response<any>> => {
		const frmData = new FormData();
		frmData.append( "id", id );
		try {
			const url = window.host + this._urls.deny;
			const request = await fetch( url, { method: "POST", credentials: "include", body: frmData } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса на неуспешное завершение обслуживания посетителя ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса на неуспешное завершение обслуживания посетителя ЭО.', e);
		}
	};

	/** Успешное завершение обслуживания посетителя */
	static complete = async (): Promise<Response<any>> => {
		try {
			const url = window.host + this._urls.complete;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include"
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запросе на успешное завершение обслуживания посетителя ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запросе на успешное завершение обслуживания посетителя ЭО.', e);
		}
	};

};
