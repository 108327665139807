
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { CertificateItem, ModalHeader, ReDocTrober } from '../../Components';
import api from '../../API';
import { showModal } from '../../Slicers/infoModalSlice';
import { ICertificateInfo } from '../../Types/Certificate';
import { ModalTypes } from '../InfoModal';

import style from './style.module.scss';
import "./style.scss";


interface ICertificatesModalProps {
	isOpen: boolean;
	toggleOpen: () => void;
	onChangeCert: ( cert: ICertificateInfo ) => void;
	currentThumbprint: string;
};

/** Окно выбора сертификата */
const CertificatesModal: React.FC<ICertificatesModalProps> = ({ isOpen, toggleOpen, onChangeCert, currentThumbprint }) => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [certList, setCertList] = useState<ICertificateInfo[]>([]);

	useEffect(() => {
		/** Функция получения списка сертификатов  */
		const getCertList = async () => {
			setIsLoading(true);
			try {
				const certs = await api.Certificate.getCertList();
				setCertList(certs);
			}
			catch 
			{
				toggleOpen();
				dispatch(showModal({
					type: ModalTypes.error,
					title: "Ошибка",
					text: "Ошибка при работе с плагином КриптоПро"
				}));
			}
			setIsLoading(false);
		};
		if ( isOpen ) {
			getCertList();
		}
	// eslint-disable-next-line
	}, [isOpen]);

	/** Вызывается при клике по сертификату  */
	const onCertificateClickHandler = ( cert: ICertificateInfo ) => {
		// Если сертификат валидны, вызываем функцию логина из пропсов
		if ( cert.valid ) {
			onChangeCert(cert);
			toggleOpen();
		}
	};

	/** Рендер списка сертификатов  */
	const renderCertList = () => (
		<div className="scrollable_container">
			<ul className={style.certificate_list}>
				{
					certList.map( ( item: ICertificateInfo, index: number )  => (
						<li id={ "cert_" + item.thumbprint} className="certificate__item" key={index} onClick={ () => onCertificateClickHandler(item)}>
							<CertificateItem {...item} currentThumbprint={currentThumbprint}/>
						</li>
					))
				}
			</ul>
		</div>
	);

	const renderContent = () => {

		if( isLoading || !certList.length ) {
			return (
				<div className="centered_content">
					<ReDocTrober size='xl' />
				</div>
			);
		}
		else 
			return renderCertList();
	}

	return (
		<Modal scrollable className={style.modalWindow} isOpen={isOpen} toggle={toggleOpen} centered>
			<ModalHeader onClose={toggleOpen} >
				{t("modal.changeCert.title")}
			</ModalHeader>
			<ModalBody className={style.modal_body}>
				{renderContent()}
			</ModalBody>
		</Modal>
	);
};

export default CertificatesModal;