import Cookies from 'js-cookie';

/**
 * API для работы с куками
 */
class APICookies {
	
	/**
	 * Функция возвращает значения
	 * @param key Ключ
	 * @param defaultValue Значение по умолчанию
	 * @returns 
	 */
	static get = (key:string, defaultValue?:string) => {
		try {
			const cookie = Cookies.get(key);
			if ( !!cookie ) return cookie;
			if (defaultValue)
				Cookies.set(key, defaultValue);
			return defaultValue;
		}
		catch (e) {
			console.warn(e);
			return null;
		}
	};

	/**
	 * Функция возвращает все доступные значения
	 * @param key Ключ
	 * @param defaultValue Значение по умолчанию
	 * @returns 
	 */
	static getAll = () => {
		try {
			const cookie = Cookies.get();
			if (cookie) 
				return cookie;
			return null;
		}
		catch (e) {
			console.warn(e);
			return null;
		}
	};

	/**
	 * Функция для обновления значения и опций
	 * @param key Ключ 
	 * @param value Значение
	 * @param options Опции 
	 * @returns True - Удачно, иначе False
	 */
	static updateCook = (key:string,value:string, options?:any|undefined ):boolean => {
		try {
			Cookies.set(key, value, options);
			return true;
		}
		catch ( e ) {
			console.warn(e);
			return false;
		}
	};

	/**
	 * Функция для удаление кука с указанным ключом
	 * @param key Ключ
	 * @param options Опции
	 * @returns True - Удачно, иначе False
	 */
	static deleteCook = (key:string,options?: any):boolean => {
		try {
			Cookies.remove(key, options);
			return true;
		}
		catch ( e ) {
			console.warn(e);
			return false;
		}
	};

	/**
	 * Возвращает массив ключей всех доступных кук
	 */
	static getAllKeys = () :string[]|undefined => {
		const all = this.getAll();
		return !!all ? Object.keys(all) : [];
	};

	/**
	 * Проверка на существование и доступность ключа в куках
	 * @param key Ключ
	 * @returns True - ключ присутствует и доступен, иначе False
	 */
	static isKeyExist = (key:string):boolean => {
		const all = this.getAllKeys();
		return !!all ? all.includes(key) : false;
	};

};

export default APICookies;