import React from "react";
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Input } from 'reactstrap'

interface ITextFieldProps {
	name: string;
	title: string;
	setData: Function;
	isRequired: boolean;
	disabled: boolean;
	readOnly: boolean;
	defaultValue?: any;
}

/**
 * Текстовое поле
 */
const TextAreaField = (props: ITextFieldProps) => {
	const { name, title, setData, isRequired = false, readOnly = false, disabled = false } = props;

	const onChangeValueHandler = (e: any, form: any): any => {
		e.persist();
		form.setFieldValue(name, e.target.value);
		!!setData && setData((data: any) => {
			return { ...data, [name]: e.target.value }
		});
	};

	return (
		<Field id={name} name={name}>
			{({ form, meta, field }: FieldProps ) => (
				<FormGroup>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<Input
						{...field}
						value={field.value || ''}
						className={classNames('form-control', { 'is-invalid': (meta.error && meta.touched) })}
						type='textarea'
						onChange={e => onChangeValueHandler(e, form)}
						disabled={disabled}
						readOnly={readOnly}
					/>
					{ ( meta.error && meta.touched) && FeedbackMessage(meta.error) }
				</FormGroup>
			)}
		</Field>
	);
}

export default TextAreaField;