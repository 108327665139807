
import classNames from 'classnames';
import { ITableRowToolbar } from '../..';
import { ReactComponent as TBIcon } from './row_tb.svg';
import './RowToolbar.scss';

interface IRowToolbarCellProps {
	active?: boolean;
	data: any;
	onClick: ( e: React.MouseEvent, data: any ) => void;
	options: ITableRowToolbar;
};

/** Отрисовка ячейки с тулбаром строки */
const RowToolbarCell: React.FC<IRowToolbarCellProps> = ({ active = false, data, options, onClick  }) => {

	const onClickHandler: React.MouseEventHandler = (e)  => {
		e.preventDefault();
		e.stopPropagation();
		onClick(e, data);
	};

	const renderContent = () => {
		if ( options?.onRender ) {
			return options.onRender( active, data );
		}
		else if ( options?.renderIcon ) {
			return (
				<div className="wrapper">
					{ options.renderIcon(data) }
				</div>
			)
		}
		return (
			<div className="wrapper">
				<TBIcon />
			</div>
		)
	};

	return (
		<td id={"rowtoolbar-" + data.id} className={classNames('rdc_datarow-cm ', { 'active': active, 'custom_tb': !!options?.onRender })} onClick={onClickHandler}>
			{ renderContent() }
		</td>
	);
};

export default RowToolbarCell;
