import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UniversalForm, IField } from "../UniversalForm";
import { LoginFormScheme } from './LoginFormScheme';
import TroberButton from '../Components/TroberButton';
import api from '../API';
import { EQAuthParamType, TypeDomainParams } from 'src/Types/';
import { EQueueType } from 'src/Types/ReDoc/EQueue';

interface ILoginFormProps {
	showErrorModal: (type: string, msg: string, title?: string, showHelpBtn?: boolean ) => void;
};

const fields: Array<IField> = [
	{
		name: "login",
		placeHolder: "Логин",
		type: "text"
	},
	{
		name: "password",
		placeHolder: "Пароль",
		type: "password"
	}
];

const enterFields: Array<IField> = [
	...fields,
	{
		name: "pultId",
		title: "Номер окна",
		type: "text"
	}
]

/** Форма авторизации по логину и паролю  */
const LoginForm: React.FC<ILoginFormProps> = ( { showErrorModal  } ) => {

    const { t } = useTranslation();
    const [isWaiting, setIsWaiting ] = useState(false);

	const onSaveHandler = async ( values: TypeDomainParams ) => {
		setIsWaiting(!isWaiting);
		try {
			const result = await api.Auth.Domain.login(values);
			const data = await result.text();
			if (result.status === 200) {
				window.location.replace(window.mainURLRedirect);
			} else {
				const msg = data ? data : "Неверно введен логин или пароль. Попробуйте ввести данные заново, либо обратитесь в службу поддержки для восстановления доступа.";
				showErrorModal( "error", msg, "Ошибка логина или пароля", true);
			}
		}
		catch (e:any) {
			console.warn(e);
			showErrorModal( "unknownError", "Попробуйте войти другим способом или обратитесь в службу поддержки.", e.message, true );
		}
		finally {
			setIsWaiting(false);
		}
	};

	const toolbar = (isValid: boolean) => (
		<TroberButton id="login_btn_login-domain" disabled={!isValid} type="submit" block isWaiting={isWaiting}>{t('login.byAccountBtn')}</TroberButton>
	);

	return (
		<UniversalForm 
            margin={24}
			disabled={isWaiting}
			renderToolbar={toolbar}
			cols={1} 
			formSchema={LoginFormScheme} 
			fields={fields} 
			onSaveHandler={onSaveHandler} 
		/>
	);
};

interface IEQ_LoginFormProps {
	isWaiting?: boolean;
    initFields?: EQAuthParamType;
	onSaveHandler: ( values: EQAuthParamType ) => void;
};

export const EQLoginForm: React.FC<IEQ_LoginFormProps> = ( { isWaiting = false, initFields, onSaveHandler }) => {

    const { t } = useTranslation();

	const toolbar = (isValid: boolean) => (
		<TroberButton style={{ marginTop: '12px'}} disabled={!isValid} type="submit" block isWaiting={isWaiting}>{t('login.byAccountBtn')}</TroberButton>
	);

    return (
        <UniversalForm 
            margin={24}
            disabled={isWaiting}
            renderToolbar={toolbar}
            sourceData={initFields}
            cols={1} 
            formSchema={LoginFormScheme} 
            fields={ initFields?.type === EQueueType.Enter ? enterFields : fields } 
            onSaveHandler={onSaveHandler} 
        />
	);
};


export default LoginForm;