import React from "react";
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { FormGroup, Label } from 'reactstrap'
import InputMask from 'react-input-mask';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";

interface IMaskFieldProps {
	name: string;
	title: string;
	setData: Function;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	mask?: string;
};

/**
 * Текстовое поле
 */
const MaskField = (props: IMaskFieldProps) => {
	const { name, title, setData, isRequired = false, disabled = false, mask= "" } = props;


	const onChangeValueHandler = (e: any, form: any): any => {
		e.persist();
		form.setFieldValue(name, e.target.value);
		!!setData && setData((data: any) => {
			return { ...data, [name]: e.target.value }
		});
	};

	return (
		<Field id={name} name={name}>
			{({ form, meta, field }: FieldProps ) => (
				<FormGroup>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<InputMask
						{...field}
						mask={mask}
						alwaysShowMask 
						value={field.value || ''}
						className={classNames('form-control', { 'is-invalid': ( meta.touched && meta.error) })}
						type='text'
						onChange={ (e:any) => onChangeValueHandler(e, form)}
						disabled={disabled}
					/>
					{ ( meta.touched && meta.error) && FeedbackMessage(meta.error) }
				</FormGroup>
			)}
		</Field>
	);
};

export default MaskField;
