import { Field } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label } from 'reactstrap'
import { getValue } from '..';


interface IFileFiledProps {
	name: string;
	title: string;
	setData: Function;
	errors: any;
	touched: any;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	accept?: string;
	multiple?: boolean
}

/**
 * Текстовое поле
 */
const FileFiled = (props: IFileFiledProps) => {
	const { name, title, setData, errors, touched, isRequired = false, disabled = false, accept = "" } = props;


	const onChangeValueHandler = (e: any, form: any): any => {
		e.persist();
		form.setFieldValue(name, e.target.files[0]);
		!!setData && setData((data: any) => {
			return { ...data, [name]: e.target.files[0] }
		});
	}

	const error = !!errors && getValue(errors, name);
	const touch = !!touched && getValue(touched, name);

	return (
		<Field id={name} name={name}>
			{(props: any) => (
				<FormGroup>
					<Label htmlFor={name}>
						<b className={isRequired ? 'field_asterix' : ''}>
							{title}
						</b>
					</Label>
					<input
						accept={accept}
						className={classNames('form-control', { 'is-invalid': (error && touch) })}
						type='file'
						onChange={e => onChangeValueHandler(e, props.form)}
						disabled={disabled}
					/>
					{(error && touch) && FeedbackMessage(error)}
				</FormGroup>
			)}
		</Field>
	);
}

export default FileFiled;