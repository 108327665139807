import { ReactEventHandler } from "react";
import { ReactComponent as IconClose } from "./CloseBtn.svg";
import classNames from "classnames";
import style from "./style.module.scss";

interface IModalHeaderProps {
    className?: string;
	children: React.ReactNode;
    onClose: ReactEventHandler;
}

const ModalHeader:React.FC<IModalHeaderProps> = ({onClose, children, className}) => (
    <div className={classNames(className, style.modal__header)}>
		<h4 className={style.modal__header_title} >
		    {children}
		</h4>
		<button className={style.close_button} onClick={onClose}>
			<IconClose className={style.close_button_icon}/>
		</button>
	</div>
);

export default ModalHeader;
