
const RDC: RDCType = {

    log: ( message?: any, ...optionalParams: any[] ) => {
        if ( process.env.NODE_ENV === "development" ) {
            console.log(message, ...optionalParams);
        }
    }

};

export type RDCType = {
    log: ( message?: any, ...optionalParams: any[] ) => void;
};

export default RDC;