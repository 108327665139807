import { useEffect, useState } from "react";
import classNames from "classnames";
import { useOutsideClick } from "src/Hooks";
import { ContextMenuItemType, Point } from "../types";
import { ContextMenuItem } from "../MenuItem/ContextMenuItem";
import styles from "./ContextMenuWindow.module.scss";

export interface IContextMenuWindowProps {
	id?: string;
	isEmpty: boolean;
    position: Point;
    data: unknown;
    right?: boolean;
    paddingX?: number; 
    paddingY?: number; 
    onPrepareContextMenu: ( data: unknown ) => Array<ContextMenuItemType>;
	onOutSideClick?: ( e: MouseEvent | React.MouseEvent ) => void;
	onClose?: ( e?: MouseEvent | React.MouseEvent ) => void;
};

export const ContextMenuWindow: React.FC<IContextMenuWindowProps> = ( props ) => {

	const { isEmpty, id, position, data, right = true, paddingX = 10, paddingY = 0, onPrepareContextMenu, onOutSideClick, onClose } = props;

	const onOutSideClickHandler = ( e: MouseEvent| React.MouseEvent ) => {
		if ( !!onOutSideClick ) 
			onOutSideClick(e);
		else 
			setShow(false);
	};

	const [ show, setShow ] = useState<boolean>(!isEmpty);
	const [ isRight, setIsRight ] = useState<boolean>(right);
	const [ items, setItems ] = useState<ContextMenuItemType[]>([]);
	const wrapperRef = useOutsideClick<HTMLDivElement>( onOutSideClickHandler );

	useEffect(() => {
		if ( isEmpty ) {
			setShow(false);
			!!onClose && onClose();
		} 
		else {
			const _items = onPrepareContextMenu( data );
			setItems( _items );
			setShow( !!_items.length );
		}
	// eslint-disable-next-line
	}, [ position, data, isEmpty ] );

	useEffect( () => {
		if ( !wrapperRef?.current ) return;
		if ( show && !isEmpty  ) {
			const sizeX = position.x + paddingX + wrapperRef.current.clientWidth;
			const parentX = ( wrapperRef.current.parentElement?.getBoundingClientRect().right || 0 );
			if ( sizeX > parentX ) {
				setIsRight(false);
				return;
			}
		}
		setIsRight(right);
	// eslint-disable-next-line
	}, [show, isEmpty, position] );

	const onClickHandler = ( e: any, item: ContextMenuItemType ) => {
		if ( item.autoClose ) {
			setShow(false);
			!!onOutSideClick && onOutSideClick(e);
			!!onClose && onClose( e );
		}
		if ( !!item.onClick ) {
			item.onClick(e);
		}
		if ( item.tag === "a" && !!onOutSideClick ) {
			onOutSideClick(e);
		}
	};

	/** Упаковываем объект с стилями */
	const getStyles = () => ({
		left: position.x + paddingX,
		top: position.y + paddingY
	});

	if ( !show ) return null;

	return (
		<div ref={wrapperRef} id={id} className={classNames(styles.rdc_cmenu__window, isRight ? '' : styles.rdc_cmenu__window_left )} style={getStyles()}>
			<ul className={styles.rdc_cmenu__buttons}>
				{
					items.map( (item, index) => <ContextMenuItem key={index} item={item} onClick={ e => onClickHandler(e, item) } /> )
				}
			</ul>
		</div>
	);
};
