import { FC, ReactNode, HtmlHTMLAttributes } from "react";
import classNames from "classnames";
import { useToggle } from "src/Hooks";
import "./Collapse.scss";


interface ICollapseItemProps extends HtmlHTMLAttributes<HTMLDivElement> {
	title: string;
	defaultOpen?: boolean;
	disable?: boolean;
	render?: ( props: ICollapseItemProps ) => ReactNode;
};

const CollapseItem: FC<ICollapseItemProps> = ( props ) => {

	const { title, defaultOpen = false, disable, render, className, children, ...otherProps } = props;

	const [ isExpanded, toggleExpand ] = useToggle(defaultOpen);

	const renderHeader = () => {
		return (
			<div className={classNames("rdc-collapseitem__header", {"disabled": disable} )} onClick={ () => !disable && toggleExpand()}>
				<button>
					<i className="fa fa-chevron-down" aria-hidden="true" />
				</button>
				<p className="headline">{ title }</p>
			</div>
		);
	};

	const renderBody = () => {
		if ( !isExpanded ) return null;
		if ( !!render ) return render(props);
		else return children;
	};

	return (
		<div className={classNames("rdc-collapseitem", className, { "expanded": isExpanded, "disabled": disable } )} {...otherProps}>
			{ renderHeader() }
			{ renderBody() }
		</div>
	);
};

export default CollapseItem;
