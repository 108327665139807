import classNames from "classnames";
import { ReactComponent as Alert } from "./alert_info.svg";
import "./AlertMessage.scss";

interface IAlertMessageProps extends React.HTMLAttributes<HTMLDivElement> {
	message?: string;
	children?: React.ReactNode;
	type?: "success" | "info" | "warning" | "error";
	iconSize?: number;
};

const AlertMessage: React.FC<IAlertMessageProps> = ({ message, children, type = "error", className, iconSize = 16, ...otherProps }) => (
	<div className={classNames('rdc_invalid-feedback', className, ("rdc_invalid-feedback--" + type ) )} {...otherProps}>
		<Alert width={iconSize} height={iconSize} />
		{ !!message && message }
		{ !!children && children }
	</div>
);

export default AlertMessage;