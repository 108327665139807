import React from "react";
import classNames from "classnames";
import { BlockedContainer } from "src/Containers";
import { RDCModal, RDCModalBody, RDCModalFooter, RDCModalHeader } from "src/Containers/RDCModal";
import { IconButton } from "src/Components";
import "./ConfirmationModal.scss";

interface IConfirmationModalProps extends React.HtmlHTMLAttributes<HTMLDivElement>{
	title: string;
	text: string;
	children?: React.ReactNode;

	renderFooterLine?: boolean;
	color?: "red" | "";

	confirmTextButton?: string;
	cancelTextButton?: string;

	isShow?: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

const ConfirmationModal: React.FC<IConfirmationModalProps> = ( props ) => {

	const { isShow = true, title, text, children, confirmTextButton = "Да", cancelTextButton = "Отмена", className, color = "", 
	renderFooterLine, onClose, onConfirm, ...otherProps 
	} = props;

	const onConfirmHandler = () => {
		onClose();
		onConfirm();
	};

	if ( !isShow ) return null;
	
	return (
		<BlockedContainer isFullScreen>
			<RDCModal id="rdc_ask_savepreset_modal" className={classNames(className, color)} { ...otherProps }>
				<RDCModalHeader onClose={onClose}>{title}</RDCModalHeader>
				<RDCModalBody pTextCentered>
					<>
						<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M44.1067 16L16 44.1067V83.8933L44.1067 112H83.8933C93.3333 102.613 112 83.8933 112 83.8933V44.1067L83.8933 16M48.5333 26.6667H79.4667L101.333 48.5333V79.4667L79.4667 101.333H48.5333L26.6667 79.4667V48.5333M58.6667 80H69.3333V90.6667H58.6667V80ZM58.6667 37.3333H69.3333V69.3333H58.6667V37.3333Z" fill="#FF3140"/>
						</svg>
						<div className="body_large">
							<p>{text}</p>
							{ !!children && children }
						</div>
					</>
				</RDCModalBody>
				<RDCModalFooter renderFooterLine={renderFooterLine}>
					<IconButton outline onClick={onClose}>{cancelTextButton}</IconButton>
					<IconButton color="red" onClick={onConfirmHandler}>{confirmTextButton}</IconButton>
				</RDCModalFooter>
			</RDCModal>
		</BlockedContainer>
	);
};

export default ConfirmationModal;
