import { ContactType } from "src/Types/ReDoc/Contact";
import { Contact } from "../../Components";
import './style.scss';

interface IContactListProps {
	list?: Array<ContactType>;
};

const ContactList: React.FC<IContactListProps> = ({ list = [] }) => (
	<div className="contacts">
		<p className="contacts_text">Связаться со службой поддержки:</p>
		<ul className="contacts_list">
			{
				list.map( (contact, index) => (
					<li id={"contact_" + index} key={"contact_" + index}>
						<Contact {...contact} />
					</li>
				))
			}
		</ul>
	</div>
);

export default ContactList;
