
import { useState } from "react";
import classNames from "classnames";
import { EQActionType } from "..";
import { useOutsideClick } from "src/Hooks";
import { ReactComponent as ExpandIcon } from '../Assets/expand.svg';
import "./EQActionBtn.scss";

interface IEQActionBtnProps {
	action: EQActionType;
	onClick: (action: string) => void;
};

export const EQActionBtn: React.FC<IEQActionBtnProps> = ( props ) => {
	if ( !!props.action.subAction ) return <EQActionBtnExpand {...props} />		
	else return <EQActionBtnStd {...props} />		
};

const EQActionBtnStd: React.FC<IEQActionBtnProps> = ( { action,  onClick } ) => (
	<button className="eq_btn" onClick={() => onClick(action.name)} data-tooltip-id="menu-tooltip" data-tooltip-content={action.title}>
		<action.icon />
	</button>
);

const EQActionBtnExpand: React.FC<IEQActionBtnProps> = ( { action,  onClick } ) => {

	const [ expand, setExpand ] = useState<boolean>(false);
	const listRef = useOutsideClick<HTMLUListElement>( () => setExpand(false));

	return (
		<div className={classNames("eq_btn-expand", { "active": expand } )}>
			<button className="eq_btn" onClick={() => onClick(action.name)} data-tooltip-id="menu-tooltip" data-tooltip-content={action.title}>
				<action.icon  />
			</button>
			<button className="eq_btn expand-btn" onClick={() => setExpand( !expand )}  data-tooltip-id="menu-tooltip" data-tooltip-content="Развернуть" >
				<ExpandIcon />
			</button>
			{
				expand && 
				<ul ref={listRef} className="expand_btn_list">
					{
						action.subAction?.map( item => (
							<EQActionBtnStd key={ "actionbtn_" + item.name} action={item} onClick={onClick} />
						))
					}
				</ul>
			}
		</div>
	);
}
