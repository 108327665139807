import { showModal, toggleShow, getIsWait, getTitle, getType } from '../Slicers/waitSlicer';
import { useDispatch, useSelector } from 'react-redux';

type useIsWaitingType = () => {
	isWaiting?: boolean;
	variant?: string;
	title?: string;
	showWaiting: (title?: string) => void;
	setWaiting: (isShow?: boolean) => void;
};

/**
 * Хук реализующий логику работы с тробером средствами редакса
 * @returns кортеж из текущего состояния тробера, 
 * заголовка тробера, 
 * функции установки тробера,
 * функции переключения тробера
 */
const useIsWaiting: useIsWaitingType = () => {

	const dispatch = useDispatch();

	const showWaiting = ( title?: string, type: string = "std" ):void => {
		dispatch(showModal({
			isWaiting: true,
			variant: type,
			title: (title || "Пожалуйста, подождите...")
		}));
	};

	const setWaiting = (isShow?: boolean):void => {
		dispatch(toggleShow({isShow}));
	};


	return { showWaiting, setWaiting };
};

/**
 * Хук реализующий логику работы с тробером средствами редакса
 * @returns кортеж из текущего состояния тробера, 
 * заголовка тробера, 
 * функции установки тробера,
 * функции переключения тробера
 */
export const useIsWaitingGlobal: useIsWaitingType = () => {

	const dispatch = useDispatch();
	const isWaiting = useSelector(getIsWait); 
	const variant = useSelector(getType) || "global";
	const title = useSelector(getTitle) || ""; 

	const showWaiting = ( title?: string ): void => {
		dispatch(showModal({
			isWaiting: true,
			title: (title || "Пожалуйста, подождите...")
		}));
	};

	const setWaiting = (isShow?: boolean): void => {
		dispatch(toggleShow({isShow}));
	};

	return { isWaiting, variant, title, showWaiting, setWaiting };
};

export default useIsWaiting;