import { FileAttachmentResponseType } from 'src/Types/File/Attachment';
import { ListJsonResponseType, FileType, FileRequestType, IFileFilterExport, BaseResponseType } from '../Types';
import downloadjs from 'downloadjs';
import { FileCountType } from 'src/Types/File';
import { SearchFileByFieldsRequestType } from 'src/Types/File/FileRequestType';
import { CreateFileResponseType } from "src/Types/ReDoc/File";
import API from '.';

type getFileListType = (body: Partial<FileRequestType>) => Promise<ListJsonResponseType<FileType>>;

type exportType = (filter: IFileFilterExport) => Promise<BaseResponseType<any>>;

type getAttachmentsType = (id: string) => Promise<FileAttachmentResponseType>;

type getFileCountType = (body: Partial<FileRequestType>) => Promise<FileCountType>;

type searchFileByFieldsType = ( body: SearchFileByFieldsRequestType) => Promise<ListJsonResponseType<FileType>>;

export default class File {

	static handleErrors = (response: any) => {
		if ( API.checkUnauthorized( response ) ) {
			throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
		}
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	}

	static searchFileList: getFileListType = async (body) => {

		const params: any = {
			method: "POST",
			credentials: "include",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		};

		const url = window.host + "/webinterfacenew/search/fileList";
		return fetch(url, params)
			.then(this.handleErrors)
			.then(response => response.json())
			.then(result => {
				if (!!result.hasError) {
					throw new Error(result.errorMessage);
				}
				return result;
			})
			.catch((e) => { 
				console.warn(e);
				throw new Error('Сервер не доступен. Попробуйте перезагрузить страницу ( Ctrl + F5 )');
			});
	}; 

	static export: exportType = async (filter) => {
		const url = window.host + '/WebInterfaceNew/Search/Export';
		const result: BaseResponseType<any> = {
			iserror: true,
   			message: "Ошибка экспорта.",
			data: null
		};
		try {
			const request = await fetch( url, {
				method: "POST",
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(filter)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {

				const answerHeader = request.headers.get("Content-Type");
				if ( answerHeader?.includes("application/json") ) {
					const res = await request.json();
					result.iserror = res.hasError;
					result.message = res.errorMessage;
					return result;
				}	

				let filename = "Дела";
				const blob = await request.blob();
				const disposition = request.headers.get("Content-Disposition");
				
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) {
						filename = matches[1].replace(/['"]/g, '');
						//для кирилицы и спец. символов
						filename = decodeURIComponent(escape(filename));
					}
				}
				if ( !!blob ) {
					downloadjs(blob, filename);
					result.iserror = false;
				}

				return result;
			}
		}
		catch( error: any ) {
			result.data = error;
			return result;
		}
		return result;
	};

	/** */
	static getAttachments: getAttachmentsType = async ( id ) => {
		const url = window.host + '/files/' + id + '/attachments';
		try {
			const request = await fetch( url, {credentials: "include"});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка вложений. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: []
			}
		}
	};

	/**  */
	static getFileCount: getFileCountType = async (filter) => {
		const url = window.host + '/WebInterfaceNew/Search/FileListCount';
		try {
			const request = await fetch( url, { 
				method:"POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(filter)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка вложений. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: []
			}
		}
	};

	/**  */
	static searchFileByFields: searchFileByFieldsType = async ( filters ) => {
		const params: any = {
			method: "POST",
			credentials: "include",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify( filters )
		};
		
		const url = window.host + "/Nvx.ReDoc.WebInterfaceModule/Search/FileByFieldsList";
		return fetch(url, params)
			.then(this.handleErrors)
			.then(response => response.json())
			.then(result => {
				if (!!result.hasError) {
					throw new Error(result.errorMessage);
				}
				return result;
			})
			.catch((e) => { 
				console.warn(e);
				throw new Error('Сервер не доступен. Попробуйте перезагрузить страницу ( Ctrl + F5 )');
			});
	};

	/** */
	static create = async ( templateId?: string ): Promise<CreateFileResponseType> => {
		const url = window.host + '/WebInterfaceModule/File/New' + ( !!templateId ? ("?templateId=" + templateId) : "" ) ;
		try {
			const request = await fetch( url, { 
				method:"POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при создании дела. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message
			}
		}
	};

}