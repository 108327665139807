import { ReactComponent as ExpandIcon } from './ExpandIcon.svg';
import './expand-column.scss';

interface ExpandColumnProps {
	isExpand?: boolean;
	rowId: string;
	onExpand: (rowId: string) => void;
	render?: ExpandColumnCellRenderType;
};

type ExpandColumnCellRenderType = ( isExpand: boolean, onExpandHandler: () => void ) => React.ReactNode;

/** Отрисовка ячейки с туглером  раскрытия строки */
export const ExpandColumnCell: React.FC<ExpandColumnProps> = ({ isExpand = false, rowId, render, onExpand  }) => {

	const expandRowHandler: React.MouseEventHandler<HTMLButtonElement> = (e)  => {
		e.preventDefault();
		e.stopPropagation();
		onExpand(rowId);
	};

	return (
		<td className='rdct_expand'>
			<div className="rdct_expand-cell_wrapper" >
				{
					!render ? (
						<button id={ "btn_expand-" + rowId } onClick={expandRowHandler} >
							<ExpandIcon style={{ transition: 'all 0.4s', transform: isExpand ? 'rotate(180deg)' : '' }} />
						</button>
					) :
					render( isExpand, () => onExpand(rowId) )
				}
			</div>
		</td>
	);
};