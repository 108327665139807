import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { IconButton, Modal } from "src/Components";
import { EQMenuButton } from "./EQMenuButton";
import { CallByNumber, EQWidget, LoginModal, PostponeModal, RedirectModal,ChoicePauseReasonsModal } from "./Modals";
import { useAppSelector, useEQDispatch, useEQSelector, useModal } from "src/Hooks";
import { EQModalStates, EQModals, EQStates, PointType } from "./";
import { EQueueType, WidgetType } from "src/Types/ReDoc/EQueue";
import CompleteModal from "./Modals/CompleteModal/CompleteModal";
import { getLearningMode } from "src/Slicers/panelSlicer";
import "./eQueue.scss";

interface IEnterEQProps {
	isExpanded?: boolean;
	isNeedLogout?: boolean;
	type: EQueueType;
};

const autoUpdate = 60000;

export const EQueue: React.FC<IEnterEQProps> = ({ isExpanded = false, type, isNeedLogout = false }) => {

	const inLearningMode = useAppSelector( getLearningMode );
	const [ showWidget, setShowWidget ] = useState<boolean>(false);
	const [ widgetPosition, setWidgetPosition ] = useState<PointType|null>(null);
	const [ showErrorModal ] = useModal();

	const { 
		dispatch, dispatchStd, getConfig, getQueueState, call, getQuery, delay, redirect, beginServing, 
		recall, endPause, beginPause, callById, drop, getDenyReasons, reason,
		endServing, exit 
	} = useEQDispatch( type );

	const status = useEQSelector( type, "loading" );
	const idQ = useEQSelector(  type, "status" )?.ticket?.idQ;
	const state = useEQSelector( type, "widgetState") || 0;
	const modalState = useEQSelector( type, "modalState" );

	const onCloseModal = () => dispatch( "closeModal" );

	/** При изменении режима ( в обучении ), скрываем виджет */
	useEffect( () => {
		setShowWidget( prev => inLearningMode ? false : prev );
	}, [ inLearningMode ] )

	useEffect( () => {
		if ( isNeedLogout ) {

			switch( state ) {
				case WidgetType.None: return;
				case WidgetType.Waiting: {
					exit();
					break;
				}
				case WidgetType.Calling: {
					drop(idQ || "")
						.then( (res: any) => {
							if ( !res.payload.hasError && res.payload.result.pultStatus === 1 )
								exit();
						});
					break;
				}
				case WidgetType.Working: {
					endServing(idQ || "")
						.then( (res: any) => {
							if ( !res.payload.hasError && res.payload.result.pultStatus === 1 )
								exit();
						});
					break;
				}
				case WidgetType.OnPause: {
					endPause()
						.then( (res: any) => {
							if ( !res.payload.hasError && res.payload.result.pultStatus === 1 )
								exit();
						});
					break;
				}
				default: {
					console.warn( `Неизвестное состояние ЭО - ${WidgetType[state]} ( ${state} ).` );
					return;
				}
			}
		}
	// eslint-disable-next-line
	}, [ isNeedLogout ] );

	// Update state timer
	useEffect( () => {
		// Ссылка на таймер
		let timerID: NodeJS.Timer;
		// Получаем настройки
		getConfig();
		// Проверка статуса ( на случай переоткрытия страницы )
		getQueueState();
		// Запускаем таймер на обновление состояния
		timerID = setInterval( () => {
			getQueueState();
		}, autoUpdate );
		// При размонтировании компонента, очищаем таймер
		return () => {
			if ( !!timerID ) {
				clearInterval(timerID);
			}
		};
	// eslint-disable-next-line
	}, [] );

	// При смене состояния, если виджет свернут, то развернем его
	useEffect( () => {
		if ( state !== 0 && !showWidget )
			setShowWidget(true);
		else if ( state === 0 )
			setShowWidget(false);
	// eslint-disable-next-line 
	}, [ state ] );

	// Тут следим за флагом отображения виджета и состоянием модалок
	// и при необходимости включаем затемнение
	useEffect( () => {
		dispatchStd( { type: "waiting/setBlockContent", payload: ( showWidget || modalState !== 0) } );
	// eslint-disable-next-line
	}, [ showWidget, modalState ] );

	// Тут обрабатываем экшены
	const onActionHandler = ( actionName: string, payload?: any ) => {
		if ( process.env.NODE_ENV === "development" ) 
			console.log( "Action - ", actionName, payload );
		if ( actionName === "asc-inviteByNumber" ) {
			if ( type === EQueueType.Enter ) {
				getQuery();
			}
			dispatch( "inviteByNumberModal" );
		}
		// Перерыв
		else if ( actionName === "cap" ) {
			dispatch( "askCapModal");
		}
		else if ( actionName === "cancelCap" ) {
			dispatch( "cancelCapModal" );
		}
		// Отмена вызова ?
		else if ( actionName === "asc-notAppear" ) {
			dispatch( "askCancelCall" );
		}
		else if ( actionName === "asc-delay" ) {
			dispatch( "ascDelay" );
		}
		else if ( actionName === "asc-redirect" ) {
			dispatch( "ascRedirect" );
		}
		else if ( actionName === "asc-exit" ) {
			dispatch( "askExitModal" );
		}
		
		else if ( actionName === "invite" ) {
			call();
		}
		else if ( actionName === "inviteByNumber" ) {
			callById(payload);
		}
		else if ( actionName === "beginPause" ) {
			beginPause( payload );
			onCloseModal();
		}
		else if ( actionName === "endPause" ) {
			endPause();
		}
		// Не явился ( отмена )
		else if ( actionName === "notAppear" ) {
			drop(idQ || "");
		}
		else if ( actionName === "repeatCall" ) {
			recall();
		}
		else if ( actionName === "beginServing" ) {
			beginServing();
		}
		else if ( actionName === "delay" ) {
			delay( payload );
			onCloseModal();
		}
		else if ( actionName === "redirect" ) {
			redirect( payload );
			onCloseModal();
		}
		else if ( actionName === "endService" ) {
			if ( type === EQueueType.Enter ) {
				getDenyReasons();
				dispatch( "askEndServiceModal" );
			} 
			else {
				endServing(idQ || "");
			}
		}
		else if ( actionName === "complete" ) {
			endServing(idQ || "");
		}
		else if ( actionName === "reasons" ) {
			reason( payload );
		}
		// 
		else if ( actionName === "endServiceAndInviteByNumber" ) {
			if ( type === EQueueType.Enter ) {
				getQuery();
			}
			// endServing(idQ || "");
			dispatch( "inviteByNumberModal" );
		}
		else if ( actionName === "endServiceAndCap" ) {
			endServing(idQ || "");
			dispatch( "askCapModal" );
		}
		else if ( actionName === "endServiceAndExit" ) {
			endServing(idQ || "");
			dispatch( "askExitModal");
		}
		else if ( actionName === "exit" ) {
			exit();
			setShowWidget(false);
		}
	};

	/** Вызывается при получении ответа от запроса на авторизацию  */
	const onLoginHandler = ( isSuccess: boolean ) => {
		if ( isSuccess ) {
			onCloseModal();
			if ( !isExpanded )
				setWidgetPosition({ left: 104, top: 700});
			setShowWidget(true);
		}
	};

	/** Вызывается при открытии виджета */
	const onOpenWidgetHandler = ( position: PointType ) => {
		if ( state !== 0 ) { 
			const pos = isExpanded ? null : { ...position, left: 104 };
			setWidgetPosition(pos);
			setShowWidget( !showWidget );
		} 
		else {
			dispatch( "openLoginForm" );
		}
	};

	/** Вызывается при закрытии виджета */
	const onCloseWidgetHandler = () => {
		if ( !isExpanded )
			setShowWidget(false);
	};

	const renderModalToolbar = ( onClose: () => void ) => {
		const mState = EQModals[modalState];
		const onNextState = () => {
			onCloseModal();
			if ( !!mState.onNextState.onAction ) {
				onActionHandler( mState.onNextState.onAction );
			}
		};
		return (
			<>
				{
					!!mState.onCloseTitle && 
					<IconButton outline onClick={onClose}>
						{mState.onCloseTitle}
					</IconButton> 
				}
				{
					!!mState.onNextState &&
					<IconButton color={mState.color} onClick={onNextState}>
						{mState.onNextState.title}
					</IconButton> 
				}
			</>
		)
	};

	/** Рендер стандартной модалки с подтверждением */
	const renderAskModal = () => {
		const mState = EQModals[modalState];
		const onClose = () => {
			onCloseModal();
			setShowWidget(true);
		};
		return (
			<Modal
				isOpen={modalState !== EQModalStates.None}
				title={mState.title}
				onClose={onClose}
				color={mState.color}
				renderIcon={ !!mState.icon ? <mState.icon /> : null }
				renderFooter={renderModalToolbar(onClose)}
			>
				{mState.text}
			</Modal>
		);	
	};

	/** Отрисовка модалки по состоянию */
	const renderModal = () => {
		switch ( modalState ) {

			case EQModalStates.QueueIsEmpty:
			case EQModalStates.AskCancelCall:
			case EQModalStates.Exit:
			case EQModalStates.AskExitCap:
				return renderAskModal();

			case EQModalStates.AskComplete:
				return <CompleteModal type={type} isOpen={modalState === EQModalStates.AskComplete} onClose={onCloseModal} onAction={onActionHandler} />;

			case EQModalStates.AskCap:  
				return <ChoicePauseReasonsModal type={type} isOpen={ modalState === EQModalStates.AskCap} onClose={onCloseModal} onAction={onActionHandler} />;
				
			case EQModalStates.AscCallByNumber:
				return <CallByNumber type={type} isOpen={ modalState === EQModalStates.AscCallByNumber} onClose={onCloseModal} onAction={onActionHandler} />;

			case EQModalStates.AskPostPone: 
				return <PostponeModal type={type} isOpen={ modalState === EQModalStates.AskPostPone} onClose={onCloseModal} onAction={onActionHandler} />;

			case EQModalStates.AskRedirect: 
				return <RedirectModal type={type} isOpen={ modalState === EQModalStates.AskRedirect} onClose={onCloseModal} onAction={onActionHandler} />;

			case EQModalStates.Login: 
				return <LoginModal type={type} setError={showErrorModal} onLogin={onLoginHandler} onClose={onCloseModal} />;

			case EQModalStates.None:
			default: return null;
		}
	};

	/** Рендер пункта меню */
	const renderInMenu = () => {
		const renderMenuBtn = ( isActive: boolean = false ) => (
			<EQMenuButton 
				isActive={ isActive || !inLearningMode }
				color={EQStates[state].color}
				isWait={ status === "pending" }
				caret 
				title='Очередь' 
				iconClass='icon-electronic_queue' 
				onClick={onOpenWidgetHandler} 
			/>
		); 
		if ( !showWidget ) {
			return renderMenuBtn();
		} 
		else {
			const widget = <EQWidget type={type} position={widgetPosition} onClose={onCloseWidgetHandler} state={EQStates[state]} onAction={onActionHandler} />;
			if ( !!isExpanded )
				return <li className="menu_item">{widget}</li>;
			else {
				return ( 
					<>
						{ createPortal( widget, document.body ) }
						{ renderMenuBtn(true) }
					</>
				);
			}
		}
	};

	return (
		<>
			{ renderModal() }
			{ renderInMenu() }
		</>
	);
};
