import React from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import api from './API/';

const DefaultLogoutUrl = "/login";

const Login = React.lazy(() => import('./Pages/Login'));
const Config = React.lazy(() => import('./Pages/Config'));
const PortalLayout = React.lazy( () => import('./Layers/Portal') );
const Widget = React.lazy( () => import('./Layers/Widget/Widget') );
const Public = React.lazy( () => import('./Layers/Public') );
const PrintLayoute = React.lazy( () => import('./Layers/Print/PrintLayout') );
const Help = React.lazy( () => import('./Views/Help/Help') );

/** Обертка над роутами доступными только авторизированным пользователям  */
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
	const location = useLocation();
	const isAuth = api.Auth.isAuthenticated();
	if ( !isAuth ) {
		return <Navigate to={DefaultLogoutUrl} state={{ from: location }} />;
	}
	return children;
};

const App = () =>  (
	<HashRouter>
		<Routes>
			<Route path='/login' id='Login Page' element={<Login />} />
			<Route path="/cfg" element={<PrivateRoute><Config /></PrivateRoute>} />
			<Route path='/public/*' element={<Public />} />
			<Route path='/widget/*' id='widget' element={<Widget />} />
			<Route path="/portal/*"	element={<PrivateRoute><PortalLayout /></PrivateRoute>}	/>
			<Route path='/print/:type/*' element={<PrivateRoute><PrintLayoute /></PrivateRoute>} />
			<Route path="/help" element={<Help />} />
			<Route path="*"	element={ <Navigate to='/portal/fileList' /> } />
		</Routes>
	</HashRouter>
);

export default App;
