import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '../../Components';
import { toggleShow } from '../../Slicers/infoModalSlice';
import { ReactComponent as BigAlert } from "src/Themes/assets/BigAlert.svg";

/**   */
export enum ModalTypes {
    info,
    debug,
    error,
	green
};

/**   */
const InfoModal = () => {

	const { isShow, title, type, text } = useSelector( (store:any) => store.infomodal );
	const dispatch = useDispatch();

	const getClsByType = () => {
		switch(type) {

			case ModalTypes.error: 
				return "brd-error";

			case ModalTypes.info: 
			default: 
				return "brd-info";
		}
	};

	const getColorByType = () => {
		switch(type) {

			case ModalTypes.error: 
				return "error";

			case ModalTypes.green: 
			default: 
				return "green";
		}
	};

	const renderModal = () => (
		<Modal
			isOpen={isShow}
			title={title}
			onClose={() => dispatch(toggleShow())}
			className={getClsByType()}
			color={getColorByType()}
			renderIcon={ type === ModalTypes.error ? <BigAlert /> : null }
		>
			{text}
		</Modal>
	);

	return createPortal( renderModal(), document.body );
};

export default InfoModal;
