import React, { MouseEvent } from 'react';
import cn from 'classnames';
import { EmptyPanel, IconButton } from 'src/Components';
import { RdcGroupItemType, RdcGroupType } from '.';
import { RdcGroupItem } from './RdcGroupItem';
import st from "./GroupList.module.scss";

interface IRdcGroupProps extends React.HTMLAttributes<HTMLDivElement> {
	isLoading?: boolean;
	parentKey?: string;
	group: RdcGroupType;
	counted?: boolean;
	enableFavorite: boolean;
	selectedIdList: Array<string>;
	searchText: string;
	control: {
		isExpanded: boolean;
		toggle: () => void;
	};
	showMoreText: string;
	renderItemTb?: ( item: any ) => React.ReactNode;
	onGetParams: ( item: any ) => RdcGroupItemType;
	onAction: ( e: MouseEvent, item: any, action: string ) => void;
	renderItem?: ( item: RdcGroupType, parentItem: RdcGroupType ) => React.ReactNode;
	onMoreBtnClick: ( groupId: string, page: number  ) => void;
};

export const RdcGroup: React.FC<IRdcGroupProps> = ( props ) => {

	const { parentKey, isLoading = false, counted, group, control, 
		enableFavorite, selectedIdList, searchText, showMoreText,
		onMoreBtnClick, renderItem, onAction, onGetParams, renderItemTb 
	} = props;

	const getTitle = ( item: RdcGroupType ) => item.groupTitle + ( counted ? ` ( ${item.count} )` : '' );

	const renderItemInner = ( item: RdcGroupType ) => {
		if ( !!renderItem ) 
			return renderItem(item, group );

		return (
			<RdcGroupItem
				enableFavorite={enableFavorite}
				selectedIdList={selectedIdList} 
				onGetParams={onGetParams}
				onAction={onAction}
				item={item}
				renderItemTb={renderItemTb}
				searchText={searchText}
			/>
		);
	};

	const renderListItem = ( item: RdcGroupType, index: number ) => {
		if ( item?.list?.length ) {
			return (
				<RdcGroup 
					key={index}
					parentKey={ parentKey + "_sub" + index } 
					group={item}
					counted={counted} 
					showMoreText={showMoreText}
					selectedIdList={selectedIdList}
					enableFavorite={enableFavorite}
					searchText={searchText}
					renderItem={renderItem}
					control={control}
					onMoreBtnClick={onMoreBtnClick}
					onGetParams={onGetParams}
					onAction={onAction}
					renderItemTb={renderItemTb}
				/>
			);
		}
		return (
			<li key={ parentKey + "_sub" + index }  className={st.rdcGroup__groupItems__item}>
				{ renderItemInner(item) }
			</li>
		);
	};

	const renderContent = () => (
		<ul className={st.rdcGroup__groupItems}>
			{ 
				!!group.list.length 
					? group.list.map( renderListItem ) 
					: <EmptyPanel />
			}
			{
				group.count > group.list.length && 
				<li className={st.rdcGroup__morebtn}>
					<IconButton centered outline bordered onClick={() => onMoreBtnClick( group.groupId, ++group.page )} isWaiting={isLoading}>
						{ showMoreText }
					</IconButton>
				</li>
			}
		</ul>
	);

	const title = getTitle( group );

	const renderHeader = () => (
		<div className={st.rdcGroup__item__header} onClick={control.toggle}>
			<h5 className={cn(st.rdcGroup__item__title, "headline-large")} title={title}>
				<div dangerouslySetInnerHTML={{ __html: group.icon || "" }} />
				<span>{title}</span>
			</h5>
			<span className={st.rdcGroup__item__header__expand}>
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path d="M10.9026 20.2333L16.0026 15.1444L21.1026 20.2333L22.6693 18.6667L16.0026 12L9.33594 18.6667L10.9026 20.2333Z" fill="inherit"/>
				</svg>
			</span>
		</div>
	);
	
	if ( !group.list.length ) return null;

	return (
		<li className={cn(st.rdcGroup__item, { [st.expanded]: control.isExpanded })} >
			{ renderHeader() }
			{ control.isExpanded && renderContent() }
		</li>
	);
};
