
import classNames from 'classnames';
import { SortType } from '.';
import { ReactComponent as Sort } from 'src/Themes/assets/table/sort.svg';
import { ReactComponent as SortUp } from 'src/Themes/assets/table/sort-up.svg';
import { ReactComponent as SortDown } from 'src/Themes/assets/table/sort-dawn.svg';
import "./SortBtn.scss";

interface ISortBtnProps {
	enable?: boolean;
	dir: SortType;
	onChange?: ( newDir: SortType ) => void;
};

const SortBtn: React.FC<ISortBtnProps> = ( { enable = true, dir, onChange } ) => {

	const onChangeHandler = () => {
		let newDir: SortType = 'ASC';
		if ( dir === "ASC" ) newDir = "DESC";
		else if ( dir === "DESC" ) newDir = null;
		else if ( !dir ) newDir = "ASC";

		if ( !!onChange ) {
			onChange( newDir );
		}
	};
	
	const renderIcon = () => {
		if ( !!dir ) {
			if ( ['asc', 'ASC'].includes(dir.toString()) )
				return <SortUp />;
			else if ( ['DESC', 'desc'].includes(dir.toString()) )
				return <SortDown />;
			else
				return <Sort />;
		}
		else
			return <Sort />;
	};

	return (
		<button className={ classNames('sortbtn', { "sortbtn-disable": !enable } )} onClick={onChangeHandler}>
			{ renderIcon() }
		</button>
	);
}

export default SortBtn;