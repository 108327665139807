import { BaseResponseType, ReestrColumnType } from '../Types';
import { availableColumns } from './AvailableColumns';

export const getObject = <T>( obj: T, delay: number = 500, isError: boolean = false ) => 
	new Promise<BaseResponseType<T>>( (resolve, reject) => {
		const response: BaseResponseType<T> = {
			iserror: isError,
			message: isError ? 'Какая-то ошибка нах...' : 'success',
			// type: "json"
			data: obj
		};
		setTimeout(() => {
			if ( isError )
				reject(response);
			else 
				resolve(response);
		}, delay);
	}
);

const getList = <T>( obj: Array<T>, delay: number = 500, isError: boolean = false ) => 
	new Promise<BaseResponseType<Array<T>>>( (resolve, reject) => {
		const response: BaseResponseType<Array<T>> = {
			iserror: isError,
			message: isError ? 'Какая-то ошибка нах...' : 'success',
			// type: "json"
			data: obj
		};
		setTimeout(() => {
			if ( isError )
				reject(response);
			else 
				resolve(response);
		}, delay);
	}
);

export const getAvailableColumns = (delay: number) => getList<ReestrColumnType>( availableColumns, delay, false );
