import { Card, CardBody, Button, ButtonToolbar, Alert, CardHeader, CardFooter } from 'reactstrap';
import ModalHeader from '../ModalHeader';
import TroberButton from '../TroberButton';
import API from 'src/API';
import { useModal } from 'src/Hooks';
import { useEffect, useRef, useState } from 'react';
import { NewsType } from 'src/Types';
import './news.scss';

interface INewsModalProps {
	onClose: () => void;
	checkUnreadNews: () => void;
};

const NewsModal: React.FC<INewsModalProps> = ({ onClose, checkUnreadNews }) => {

	const wrapperRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<NewsType[]>([]);
	const [ showError ] = useModal();

	const [ count, setCount ] = useState<number>(0);
	const [skipPage, setSkipPage] = useState(0);

	useEffect( () => {
		setIsLoading(true);
		API.Plugins.getNews(3, skipPage)
			.then( result => {
				setData( prev => [...prev, ...result.list]);
				setCount( result.count );
				if ( !!wrapperRef.current && data.length > 3 ) {
					wrapperRef.current.scrollBy({ top: wrapperRef.current.scrollHeight, behavior: "smooth" });
				}
			})
			.catch( (e:any) => {
				showError( e.message, "Ошибка при получении новостей");
			})
			.finally( () => setIsLoading(false));
	// eslint-disable-next-line
	}, [skipPage]);

	const onSetAsReadHandler = () => {
		API.Plugins.setAsRead()
			.then( () => checkUnreadNews() )
			.catch( (e: any) => {
				console.warn(e);
				showError( e.message, "Новости Re:Doc");
			})
			.finally( () => onClose() );
	};

	const onShowMoreHandler = () => {
		setSkipPage( prev => prev += 1);
	};

	const renderNewsCard = ( key: number, news:NewsType ) => {
		return (
			<Card body color="warning" outline className='rdc_new' key={ 'news_' + key}>
				<CardHeader className='rdc_new__header' tag={'h5'}>{news.title}</CardHeader>
				<CardBody>
					<div className='rdc_new__context' dangerouslySetInnerHTML={{ __html: news.content }} />
				</CardBody>
				<CardFooter className="text-muted">
					{news.updateDate}
				</CardFooter>
			</Card>
		);
	};

	const renderContent = () => {
		if ( !!data.length ) {
			return (
				<>
					<div className="rdc_news_wrapper" ref={wrapperRef}>
						{data.map( (item: NewsType, index: number) => renderNewsCard(index, item) )}
					</div>
					{
						data.length < count &&
						<TroberButton isWaiting={isLoading} block onClick={onShowMoreHandler}>&hellip; Показать еще</TroberButton>
					}
				</>
			);
		}
		else {
			return (
				<Alert color='success'>
					<h5 className='offset-md-4'>Новостная лента пуста</h5>
				</Alert>
			);
		}
	;}

	const renderFooter = () => (
		<ButtonToolbar className='rdc_news-tb'>
			<Button color='success' onClick={onSetAsReadHandler} disabled={!data.length}>
				&#10004;
				Прочитано
			</Button>
			<Button outline onClick={onClose}>Закрыть</Button>
		</ButtonToolbar>
	);

	return (
		<div className="modal-overlay">
			<div className="modal-window rdc_news-modal">
				<ModalHeader onClose={onClose}>Новостная лента Re:Doc</ModalHeader>
				{renderContent()}
				{renderFooter()}
			</div>
		</div>
	);
};

export default NewsModal;