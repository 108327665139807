import { useEffect, useState } from "react";
import { enterGetHoldMode } from "src/Slicers/eQueue/EnterThunks";
import { FeedbackMessage, InputNumber } from "src/Components/Controls";
import { IconButton, Modal, UTextList } from "src/Components";
import { useAppDispatch } from "src/Hooks";
import { IModalProps } from "..";
import { EQueueType } from "src/Types/ReDoc/EQueue";
import "../modals.scss";

interface IPostponeModalProps extends IModalProps {};

const PostponeModal: React.FC<IPostponeModalProps> = ( { type, isOpen, onClose, onAction } ) => {

	const dispatch = useAppDispatch();
	const [ holdMode, setHoldMode ] = useState<number>(0);
	const [ delay, setDelay ] = useState<number|null>(null);
	const [ errorMsg, setErrorMsg ] = useState<string>("");

	useEffect( () => {
		if ( type === EQueueType.Enter ) {
			dispatch ( enterGetHoldMode() ).unwrap()
				.then( data => !data.hasError && setHoldMode( data.result ) );
		}
	// eslint-disable-next-line
	}, [] );

	useEffect(() => {
		if ( !delay )
			setErrorMsg("Необходимо заполнить");
		else if ( delay < 0 )
			setErrorMsg( "Значение не может быть меньше нуля" );
		else if ( delay > 480 )
			setErrorMsg( "Нельзя отложить на более 480 минут" ); 
		else 
			setErrorMsg('');
	}, [ delay ] );

	const withTime = type === EQueueType.Damask || holdMode === 1;

	const renderBody: React.FC = ( props ) => {
		if ( withTime ) {
			return (
				<div className="modal_wrapper modal_wrapper-postpone">
					<p className="headline">На сколько отложить прием посетителя?</p>
					<InputNumber value={delay} setValue={setDelay} placeholder="Введите количество минут" />
					{ !!errorMsg && <FeedbackMessage message={errorMsg} /> }
					<UTextList splitBy=';'>
						До истечения указанного времени Вы сможете вызвать посетителя из персональной очереди.;
						После истечения указанного времени Вы сможете вызвать посетителя при приглашении следующего.
					</UTextList>
				</div>
			);
		}
		return (
			<div className="modal_wrapper modal_wrapper-postpone">
				<p className="headline">Отложить прием посетителя?</p>
			</div>
		);
	};

    const renderModalToolbar = () => {
		return (
			<>
				<IconButton outline onClick={onClose}>Закрыть</IconButton>
				<IconButton disable={ withTime && !!errorMsg} onClick={ () => onAction('delay', delay )} >Отложить</IconButton> 
			</>
		)
	};

    return (
        <Modal
			isOpen={isOpen}
			title="Отложить прием посетителя"
			onClose={onClose}
			color="light"
			renderFooter={renderModalToolbar()}
			renderBody={renderBody}
		/>
    );
};

export default PostponeModal;
