import React, { useState } from 'react';
import classNames from 'classnames';
import { SearchInput, EmptyPanel } from '..';
import { ITreeListProps, TreeListItemType, useListExpandedByCurrent, useListSearchedItems } from '.';
import TreeListItem from './TreeListItem/TreeListItem';
import './TreeList.style.scss';

/** Древовидный список */
const TreeList: React.FC<ITreeListProps> = ({ id, items, paddingEnclose = 24, selectedItem = "", className, searchEnable = false, onClick, renderToolbar }) => {
	
	const [ search, setSearch ] = useState<string>("");
	const preparedItems = useListExpandedByCurrent( items, selectedItem );
	const filtering = useListSearchedItems(preparedItems, search);

	/** Вызов внешнего метода выбора реестра  */
	const onClickHandler = ( item: TreeListItemType ) => !!onClick && onClick(item);

	/** Отрисовка списка */
	const renderList = () => (
		<ul id={id} className="treelist">
			{
				filtering.map( (i, index) => (
					<TreeListItem 
						index={index}
						paddingEnclose={paddingEnclose}
						activePreset={selectedItem}
						key={ !!i.id ? i.id : index } 
						item={i} 
						onClick={onClickHandler}
						renderToolbar={renderToolbar}
					/>
				))
			}
		</ul>
	);

	return (
		<div className={classNames("treelist-wrapper", className)}>
			{ 
				!!searchEnable && 
				<SearchInput 
					id="input_search-reestr"
					placeholder='Введите название реестра' 
					onSearch={setSearch}
					debounceDelay={500}
				/> 
			}
			{ !!filtering.length ? renderList() : <EmptyPanel text="Не найдено" /> }
		</div>
	);
};

export default TreeList;
