import { useState, useEffect } from 'react';
import debounce from "lodash.debounce";

interface IuseScrollListResult {
	canScrollUp: boolean;
	canScrollDown: boolean;
	canScrollLeft: boolean;
	canScrollRight: boolean;
	scrollHeightHandler: (distance: number) => void;
	scrollWidthHandler: (distance: number) => void;
};

type scrollListType = (
	containerRef: React.RefObject<HTMLUListElement>, 
	scrollHeight?: number,
	isScrollByWheel?: boolean,
	delay?: number
) => IuseScrollListResult;

/**
 * Хук реализующий логику прокрутки указанного контейнера
 * @param containerRef Ссылка на контейнер
 * @param scrollHeight Высота скролинга машью
 * @param isScrollByWheel Включить скролинг ( по высоте ) колесиком мыши
 * @param delay Задержка перед скролингом кнопками
 * @returns Кортеж с данными для управления  
 * canScrollUp - Можно кролить вверх
 * canScrollDown - Можно скролить вниз
 * canScrollLeft - Можно скролить влево
 * canScrollRight - Можно скролить вправо
 * scrollHeightHandler - Функция скрола по вертикали
 * scrollWidthHandler -  Функция скрола по горизонтали
 */
const useScrollList: scrollListType = (containerRef, scrollHeight = 40, isScrollByWheel = false, delay = 200) => {
	const [canScrollUp, setCanScrollUp] = useState<boolean>(false);
	const [canScrollDown, setCanScrollDown] = useState<boolean>(false);
	const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
	const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

	const checkForScrollPosition = () => {
		const { current } = containerRef;
		if (current) {
			const { scrollHeight, scrollTop, clientHeight, clientWidth, scrollLeft, scrollWidth } = current;
			setCanScrollUp(scrollTop > 0);
			setCanScrollDown(scrollTop !== scrollHeight - clientHeight);
			setCanScrollLeft(scrollLeft > 0);
			setCanScrollRight(scrollLeft !== scrollWidth - clientWidth);
		}
	};

	const scrollHorizontally = (e:any) => {
		const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
		if ( !!containerRef.current ) {
			containerRef.current.scrollTop -= (delta * scrollHeight); 
		}
		e.preventDefault();
	};

	const scrollHeightHandler = (distance: number) => containerRef.current?.scrollBy({ top: distance, behavior: "smooth" });
	const scrollWidthHandler = (distance: number) => containerRef.current?.scrollBy({ left: distance, behavior: "smooth" });
	
	const debounceCheckForScrollPosition = debounce(checkForScrollPosition, delay);

	useEffect(() => {
		const { current } = containerRef;
		checkForScrollPosition();
		current?.addEventListener("scroll", debounceCheckForScrollPosition);
		if ( !!isScrollByWheel ) {
			// IE9, Chrome, Safari, Opera
			current?.addEventListener('mousewheel', scrollHorizontally, false);
			// Firefox
			current?.addEventListener('DOMMouseScroll', scrollHorizontally, false);
		}
		return () => {
			if ( !!isScrollByWheel ) {
				current?.removeEventListener('mousewheel', scrollHorizontally);
				current?.removeEventListener('DOMMouseScroll', scrollHorizontally);
			}
			current?.removeEventListener("scroll", debounceCheckForScrollPosition);
			debounceCheckForScrollPosition.cancel();
		};
		// eslint-disable-next-line
  	}, [containerRef, isScrollByWheel]);

	return {
		canScrollUp,
		canScrollDown,
		canScrollLeft,
		canScrollRight,
		scrollHeightHandler,
		scrollWidthHandler
	};
};

export default useScrollList;