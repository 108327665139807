import { SimpleTable } from "./SimpleTable";

export type ColumnType = {
	id: string;
	title?: string;
	type?: "number" | "string";
	width?: string|number;
	sortable?: boolean;
};

export default SimpleTable;
