import React from 'react';
import BaseTable, { ChangeOrderType } from './BaseTable/BaseTable';
import { ColumnDef } from '@tanstack/react-table';
import IconButton from 'src/Components/IconButton';
import SortIcon from './SortIcon';
import { SortOrder } from '..';

interface DataGridProps {
	data: Array<any>;
	columns?: any;
};

class DataGrid extends React.PureComponent<DataGridProps> {

	constructor(props: DataGridProps) {
		super(props);

		this.state = {
			
		};
	};

	getColumns = (): Array<ColumnDef<any>> => ([
		{
			accessorKey: "id",
			header: 'ИД',
			size: 20,
			enableSorting: true,
			enableColumnFilter: true,
		},
		{
			accessorKey: "status",
			size: 500,
			enableSorting: true,
			enableColumnFilter: true
		}
	]);

	renderSortButton = (sortEnable: boolean, order: SortOrder, changeOrder: ChangeOrderType) => {
		return (
			<IconButton outline disable={!sortEnable} onClick={ () => changeOrder()}>
				<SortIcon dir={order}  />
			</IconButton>
		);
	};

	render(): React.ReactNode {
		return (
			<div id='rdc_table_container' className="rdc_datagrid_wrapper">
				<BaseTable 
					dataKey='id' 
					data={this.props.data} 
					columns={this.getColumns()}

					sortBtnRender={this.renderSortButton}
					// onSortChanged={}
				/>
			</div>
		);  
	};

};

export default DataGrid;