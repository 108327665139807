import classNames from "classnames";
import "./Block.scss";

interface IBlockProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    dir?: "row" | "col";
    children: React.ReactNode;
};

const Block: React.FC<IBlockProps> = ( { title, dir = "row", children, className, ...otherProps } ) => (
    <div className={classNames("rdc_block", className, `rdc_block-${dir}` )} {...otherProps} >
        { 
            !!title && 
            <h3 className="rdc_block__title headline">
                {title}
            </h3> 
        }
        { children }
    </div>
);

export default Block;
