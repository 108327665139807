import { useState, useCallback } from 'react';

type useToggleType = (defaultValue: boolean) => [
    value: boolean,
    toggle: () => void,
    setValue: (value:boolean) => void
];

/**
 * Хук реализующий переключаемое состояние с логическим типом 
 * @param defaultValue Значение по умолчанию
 * @returns Кортеж из значения, функции-переключателя и функции прямой установки значения
 */
const useToggle: useToggleType = (defaultValue = false) => {
    const [ value, setValue ] = useState<boolean>(defaultValue);
    const toggle = useCallback(() => setValue(x => !x), []);
    return [ value, toggle, setValue ];
};

export default useToggle;
