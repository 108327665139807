
import { Modal, ModalBody } from 'reactstrap';
import { ReDocTrober, WaitBlock } from '../../Components';
import { useIsWaitingGlobal } from '../../Hooks/useIsWaiting';
import BlockedContainer from '../BlockedContainer';
import style from './styles.module.scss';

/** Блокирующий троббер */
const BlockedTrober = () => {

	const { isWaiting, variant, title } = useIsWaitingGlobal();

	if ( !isWaiting ) return null;
	else if ( variant === "global" ) 
		return (
			<Modal backdrop="static" isOpen={isWaiting} size="lg">
				<ModalBody>
					<h3 className={style.header}>
						{title}
					</h3>
					<ReDocTrober className='margin-auto' size='lg' />
				</ModalBody>
			</Modal>
		);
	else return (
		<BlockedContainer>
			<WaitBlock title={title} />
		</BlockedContainer>
	);
}

export default BlockedTrober;