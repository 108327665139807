import { useState } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "../FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Input } from 'reactstrap';
import { ReactComponent as EyeOff } from './eye_off.svg';
import { ReactComponent as EyeOn } from './eye_on.svg';
import "./style.scss";
import { getValue } from '../../';

interface ITextFieldProps {
	name: string;
	title: string;
	setData: Function;
	errors: any;
	touched: any;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	placeHolder?: string;
}

/**
 * Текстовое поле
 */
const PasswordField = (props: ITextFieldProps) => {
	const { name, title, setData, errors, touched, isRequired = false, disabled = false, placeHolder } = props;


	const onChangeValueHandler = (e :any, form: any) : any => {
		e.persist();
		form.setFieldValue(name, e.target.value);
		!!setData && setData( (data: any) => {
			return { ...data, [name]: e.target.value }
		});
	};

	const [isShowValue, setIsShowValue] = useState(false);
	const fieldType = isShowValue ? "text" : "password"; 

	const toggleShow = () => setIsShowValue(!isShowValue);

	const renderBtnEyeIcon = () => (isShowValue ? <EyeOff /> : <EyeOn />);

	const error = !!errors && getValue(errors, name);
	const touch = !!touched && getValue(touched, name);

	return (
		<Field id={name} name={name}>
			{( props: any ) => (
				<FormGroup className='pwd_field'>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<Input
						id={ "input_" + name }
						{...props.field}
						placeholder={placeHolder}
						value={props.field.value || ''}
						className={classNames('form-control', { 'is-invalid': (error && touch) })}
						type={fieldType}
						onChange={e => onChangeValueHandler(e, props.form)}
						disabled={disabled}
					/>
					<button 
						type="button"
						className='pwd_eye_btn' 
						onClick={toggleShow}
					>
						{renderBtnEyeIcon()}
					</button>
					{(error && touch) && FeedbackMessage(error)}
				</FormGroup>
			)}
		</Field>
	);
}

export default PasswordField;