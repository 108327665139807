import { ReactComponent as Sort } from '../assets/sort.svg';
import { ReactComponent as SortUp } from '../assets/sort-up.svg';
import { ReactComponent as SortDown } from '../assets/sort-dawn.svg';

interface ISortIcon {
	dir: 'ASC' | 'DESC' | string | false | null | undefined;
};

const SortIcon: React.FC<ISortIcon> = ({dir}) => {
	
	const renderIcon = () => {
		if ( !!dir ) {
			if ( ['asc', 'ASC'].includes(dir.toString()) )
				return <SortUp />;
			else if ( ['DESC', 'desc'].includes(dir.toString()) )
				return <SortDown />;
			else
				return <Sort />;
		}
		else
			return <Sort />;
	};

	return (
		<span className='icon'>
			{renderIcon()}
		</span>
	);
}

export default SortIcon;