import { useRef } from "react";
import { Field } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Button, Table } from 'reactstrap'
import './styles.scss';
import { getValue } from "..";

interface IFileFiledProps {
	name: string;
	title: string;
	setData: Function;
	errors: any;
	touched: any;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	accept?: string;
	multiple?: boolean;
	maxFiles?: number;
	maxFileSiz?: number;
}

/**
 * Текстовое поле
 */
const FilesFiled = (props: IFileFiledProps) => {

	const { name, title, setData, errors, touched, isRequired = false, disabled = false, accept = "" } = props;

	const files = useRef<any[]>([]);

	const onChangeValueHandler = (e: any, form: any): any => {
		e.persist();
		const file = e.target.files[0];
		if (!!file) {
			files.current.push({
				name: file.name,
				data: file.file
			});
			form.setFieldValue(name, files.current);
			!!setData && setData((data: any) => ({
				...data,
				[name]: files.current
			}));
		}
		
	}

	const deleteHandler = (index: number, form: any) => {
		files.current.splice(index, 1);
		form.setFieldValue(name, files.current);
		!!setData && setData((data: any) => {
			return {
				...data,
				[name]: files.current
			}
		});
	}

	const error = !!errors && getValue(errors, name);
	const touch = !!touched && getValue(touched, name);

	return (
		<Field id={name} name={name}>
			{(props: any) => (
				<FormGroup>
					{
						!!title &&
						<Label htmlFor={name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<input
						accept={accept}
						hidden
						id={name}
						placeholder="test"
						
						type='file'
						onChange={e => onChangeValueHandler(e, props.form)}
						disabled={disabled}
					/>
					<FormGroup className={classNames('form-control filesfield_group', { 'is-invalid': (error && touch) })}>
						<Table hover responsive size="sm" striped>
							<thead>
								<tr>
									<td colSpan={3}>
										<Label htmlFor={name} className="filesfield_group__load">
											<i className="fa fa-upload" aria-hidden="true" />
											<span> Загрузить</span>
										</Label>
									</td>
								</tr>
								<tr>
									<th>Наименование</th>
									<th>Размер (mb)</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									!!files.current && files.current.map((i: any, index: number) => (
										<tr key={name + index}>
											<td>{i.name}</td>
											<td>{(i.size / 1024).toFixed(2)}</td>
											<td>
												<Button onClick={() => deleteHandler(index, props.form)} outline size="sm">
													<i className="fa fa-times" aria-hidden="true" />
												</Button>
											</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</FormGroup >
					{(error && touch) && FeedbackMessage(error)}
				</FormGroup>
			)}
		</Field>
	);
}

export default FilesFiled;