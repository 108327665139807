import { ReactComponent as Alert } from "./alert_info.svg";
import "./style.scss";

interface IFeedbackMessageProps {
	message?: string
	children?: React.ReactNode;
};

const FeedbackMessage: React.FC<IFeedbackMessageProps> = ({ message, children }) => (
	<div className='rdc invalid-feedback help-block' style={{ display: 'block' }}>
		<Alert />
		{ !!message ? message : children }
	</div>
);

export default FeedbackMessage;