import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { EmptyPanel, IconButton, WaitBlock } from 'src/Components';
import { ExtendedGroupType, IRdcGroupListRef, RdcGroupItemType, RdcGroupType } from '.';
import { RdcGroup } from './RdcGroup';
import st from "./GroupList.module.scss";

interface IRdcGroupListProps extends React.HTMLAttributes<HTMLDivElement> {
	isLoading?: boolean;
	data: RdcGroupType;
	counted?: boolean;
	page?: number;
	defaultExpanded?: boolean;
	enableFavorite?: boolean;
	selectedIdList?: Array<string>;
	searchText?: string;
	showMoreText?: string;
	filterItems?: ( item: any ) => boolean;
 	renderItem?: ( item: RdcGroupType, parentItem: RdcGroupType ) => React.ReactNode;
	onMoreBtnClick: ( groupId: string, page: number ) => void;
	onGetParams: ( item: any ) => RdcGroupItemType;
	onAction: ( e: React.MouseEvent, item: any, action: string ) => void;
	renderItemTb?: ( item: any ) => React.ReactNode;
	renderToolbar?: ( place: string ) => React.ReactNode;
};

export const RdcGroupList = forwardRef<IRdcGroupListRef, IRdcGroupListProps>( (props, ref ) => {

	const { isLoading = false, data, counted = false, defaultExpanded = false, className, 
		enableFavorite = false, selectedIdList = [], searchText = "", showMoreText = "Показать еще",
		renderItem, onMoreBtnClick, onGetParams, renderItemTb, renderToolbar, onAction, ...otherProps 
	} = props;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [ extendedData, setExtendedData ] = useState<ExtendedGroupType>({});

	useImperativeHandle( ref, () => {
        return {
            scrollTo( id: string ) {
				const element = document.getElementById( id );
				if ( !!element )
					element.scrollIntoView(	{behavior: "smooth", block: "start", inline: "start"});
            },
        };
    }, [] );

	useEffect( () => {
		if ( !!data && !!data.list.length ) {
			const _exData: ExtendedGroupType = {
				...extendedData,
			};
			const isExpanded = ( gr: RdcGroupType, index: number ) => {
				if ( index === 0 || defaultExpanded ) return true;
				else if ( !defaultExpanded ) return !!extendedData[gr.groupId];
				return false;
			}
			data.list.forEach( (gr, i) => {
				_exData[gr.groupId] = isExpanded(gr, i);
			});
			setExtendedData( _exData );
		}
	// eslint-disable-next-line
	}, [ data ] );

	const renderContent = () => {
		if ( isLoading ) {
			return (
				<WaitBlock type="short" className="margin-auto mv-32" title='Ищем услуги...' />
			);
		}
		else if ( data.list.findIndex( g => !!g.list.length ) === -1 ) {
			return (
				<EmptyPanel text='По вашему запросу ничего не найдено'/>
			);
		}
		else {
			return (
				<ul className={st.rdcGroup__list}>
					{ data.list.map(( group, i) => (
						<RdcGroup 
							key={"group_" + i}
							parentKey={ "group_" + i } 
							control={{
								isExpanded: extendedData[group.groupId],
								toggle: () => setExtendedData({ ...extendedData, [group.groupId]: !extendedData[group.groupId] }) 
							}}
							showMoreText={showMoreText}
							enableFavorite={enableFavorite}
							selectedIdList={selectedIdList}
							group={group}
							searchText={searchText}
							counted={counted} 
							renderItem={renderItem}
							onMoreBtnClick={onMoreBtnClick}
							onGetParams={onGetParams}
							onAction={onAction}
							renderItemTb={renderItemTb}
						/> 
					))}
					{
						data.count > data.list.length && 
						<li className={st.rdcGroup__morebtn}>
							<IconButton centered outline bordered onClick={ () => onMoreBtnClick( data.groupId, data.page++ )} isWaiting={isLoading}>
								{ showMoreText }
							</IconButton>
						</li>
					}
				</ul>
			);
		}
	};

	return (
		<div ref={wrapperRef} className={classNames( st.rdcGroup, className)}  {...otherProps}>
			{ renderContent() }
			{ !!renderToolbar && renderToolbar("bottom") }
		</div>
	);
} );
